/** @jsxImportSource @emotion/react */
import BreazeLogo from '../assets/BreazeLogo';
import React from 'react';
import PasswordResetForm from './PasswordResetForm';
import EmailForm from './EmailForm';
import EmailSentMessage from './EmailSentMessage';
import MaterialSpinner from '../../material/MaterialSpinner';

const StyledLoginBody = settled => ({
  opacity: settled ? '1' : '0',
  padding: '1.3rem',
  margin: 'auto',
  width: '18rem',
  transition: 'opacity 0.4s ease-in',
  border: '3px solid #4AC9E3',
  borderRadius: '0.75rem',
  '.breaze-logo': {
    margin: 'auto',
    width: '9rem',
    height: '3rem',
  },
  '.help-text':{
    color: 'grey',
    textAlign: 'center',
    fontSize: '0.9rem',
  },
  '.input': {
    margin: '1rem 0 0.5rem',
  },
  '.primary-button': {
    fontSize: '0.9rem',
    padding: '0.5rem 1.75rem',
    margin: 'auto',
  },
  '.material-spinner': {
    left: 'calc(50% - 2.75rem)',
    top: 'calc(50% - 4.75rem)',
    borderTopColor: 'rgba(215,215,215, 0.5)',
    borderBottomColor: 'rgba(215,215,215, 0.5)',
    borderRightColor: 'rgba(215,215,215, 0.5)',
    borderLeftColor: '#4ac9e3',
  },
});

const PasswordResetBody = ({ email, token, loading, setLoading }) => {
  const [settled, setSettled] = React.useState(false);
  const [sentEmail, setSentEmail] = React.useState(false);
  const [successful, setSuccessful] = React.useState(false);
  const helpText = sentEmail || successful? '' : email !== null? 'Fill in the following fields to complete your password reset' : 'Enter your email address to receive an account recovery email';

  React.useEffect(() => {
    setTimeout(() => {
      setSettled(true);
    }, 3);
  });

  return (
    <div css={StyledLoginBody(settled)} className={'login-body'}>
      <BreazeLogo />
      <MaterialSpinner isLoading={loading}/>
      <div className={'help-text'}>{helpText}</div>
      {sentEmail? <EmailSentMessage/> : email !== null ? <PasswordResetForm email={email} loading={loading} setLoading={setLoading} token={token} successful={successful} setSuccessful={setSuccessful} /> : <EmailForm loading={loading} setSentEmail={setSentEmail} setLoading={setLoading} />}
    </div>
  );
};

export default PasswordResetBody;
