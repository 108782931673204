import reduxEvents from './redux-events';

const set = (_, interactivesMap) => interactivesMap;

const interactivesEvents = reduxEvents().addEvent('INTERACTIVES', 'SET', set);

const interactives = interactivesEvents.getReducer(new Map());
const interactivesActionCreator = interactivesEvents.getActionCreators();

export { interactives, interactivesActionCreator };
