import reduxEvents from '../redux-events';

const set = (state, updates) => ({ ...state, ...updates });

const viewEvents = reduxEvents().addEvent('VIEW', 'SET', set);

const view = viewEvents.getReducer({});

const viewActionCreator = viewEvents.getActionCreators();

export { view, viewActionCreator };
