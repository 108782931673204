/** @jsxImportSource @emotion/react */
import TopBar from '../assets/TopBar';
import Footer from '../assets/Footer';
import FeaturesBox from '../assets/FeaturesBox';
import RegisterBackground from './RegisterBackground';
import RegisterBody from './RegisterBody';
import ReCaptchaMessage from '../assets/ReCaptchaMessage';

const StyledRegistration = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  '.body': {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 'auto 0',
    maxWidth: '100vw',
  },
};

const Registration = props => {
  return (
    <div css={StyledRegistration} className={'registration'}>
      <TopBar {...props} />
      <div className={'body'}>
        <RegisterBody {...props} />
        <FeaturesBox />
      </div>
      <Footer />
      <ReCaptchaMessage />
      <RegisterBackground />
    </div>
  );
};

export default Registration;
