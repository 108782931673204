const InteractiveIframe = {
  type: 'InteractiveIframe',
  tags: ['interactives', 'tracking'],
  image:
    'https://breas3.com/item-images-v0/interactiveiframe.jpg',
  rolloverImage:
    'https://breas3.com/item-images-v0/interactiveiframe.jpg',
  description:
    'Empty iframe to embed content that sends back tracking information to ECO',
  tracking_id: null,
  interactive_id: null,
  language: null,
  enabledByConfig: true,
  content: {
    url: 'https://breas3.com/empty-interactive-iframe.html',
    width: 0,
    height: 0,
  },
};

export default InteractiveIframe;
