import xr from '../../lib/xr/xr-wrapper';
import urls from '../../config/urls/index';
import { viewActionCreator } from '../../events/view/view';
import { breazeView } from '../../lib/breaze/view';
import jwtDecode from 'jwt-decode';
import { getToken } from '../../hooks/useLoginToken';

const { set } = viewActionCreator;

const loadView = () => dispatch => {
  const { permissions = [] } = jwtDecode(getToken());
  return Promise.all([xr.get(urls.user_load), xr.get(urls.organization_course_limit)]).then(
    data => {
      const [user, limit] = data;
      const view = {
        ...breazeView,
        ...permissions,
        ...user.data,
        ...limit.data,
      };
      dispatch(set(view));
      return view;
    }
  );
};
export default loadView;
