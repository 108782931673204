/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

const StyledPrimary = {
  display: 'flex',
  flexDirection: 'column',
  button: {
    border: 'none',
    background: '#4ac9e3',
    color: 'white',
    fontWeight: '600',
    borderRadius: '0.5rem',
    padding: '0.3rem 0.75rem',
    height: 'fit-content',
    margin: 'auto 0.3rem',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      background: '#253746',
    },
    '&:disabled': {
      background: '#acacac',
    },
  },
};

const PrimaryButton = ({ text, onClick, disabled = false }) => {
  return (
    <div className={'primary-button'} css={StyledPrimary}>
      <button disabled={disabled} onClick={() => onClick()}>
        {text}
      </button>
    </div>
  );
};

PrimaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default PrimaryButton;
