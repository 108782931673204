import PropTypes from 'prop-types';
import React, { useRef, useLayoutEffect } from 'react';
import DialogButton from './DialogButton';
import StyledDialog from './StyledDialog';
import StyledDialogHeader from './StyledDialogHeader';
import StyledDialogBody from './StyledDialogBody';
import StyledDialogButtons from './StyledDialogButtons';

const elements =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

const Dialog = props => {
  const dialog = useRef();
  const onFocus = event => {
    if (props.isTopModal && !dialog.current.contains(event.target)) {
      dialog.current.querySelector(elements).focus();
    }
  };
  useLayoutEffect(() => {
    document.addEventListener('focus', onFocus, true);
    return () => {
      document.removeEventListener('focus', onFocus, true);
    };
  }, []);

  const {
    header,
    body,
    whiteSpace,
    rejectCallback,
    reject,
    acceptCallback,
    accept,
  } = props;
  return (
    <StyledDialog
      ref={dialog}
      className="dialog"
      role="dialog"
      aria-modal="true"
    >
      <StyledDialogHeader className="dialog-header">
        {header}
      </StyledDialogHeader>
      <StyledDialogBody
        className="dialog-body"
        style={{ whiteSpace: whiteSpace }}
      >
        {body}
      </StyledDialogBody>
      <StyledDialogButtons className="dialog-buttons">
        <DialogButton onClick={acceptCallback} text={accept} />
        <DialogButton onClick={rejectCallback} text={reject} />
      </StyledDialogButtons>
    </StyledDialog>
  );
};

Dialog.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string,
  whiteSpace: PropTypes.string,
  rejectCallback: PropTypes.func,
  reject: PropTypes.string,
  acceptCallback: PropTypes.func,
  accept: PropTypes.string,
  isTopModal: PropTypes.bool.isRequired,
};

Dialog.defaultProps = {
  header: '',
  body: '',
  whiteSpace: '',
  rejectCallback: () => {},
  reject: '',
  acceptCallback: () => {},
  accept: '',
};

export default Dialog;
