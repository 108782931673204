import { combineReducers } from 'redux';
import { guidebooks } from '../events/guidebooks/guidebooks';
import { interactives } from '../events/interactives';
import { modals } from '../events/modals';
import { playerView } from '../events/view/player-view';
import { trackingIds } from '../events/tracking-ids';
import { completedTrackingIds } from '../events/completed-tracking-ids/completed-tracking-ids';
import { view } from '../events/view/view';
import { achievements } from '../events/achievements/achievements';
import { titleTranslations } from '../events/title-translations';
import { guidebook } from '../events/guidebook/guidebook';

const editor = combineReducers({ trackingIds });

const player = combineReducers({
  guidebooks,
  interactives,
  completedTrackingIds,
  view: playerView,
  achievements,
  titleTranslations,
});

const reducer = combineReducers({
  editor,
  modals,
  player,
  view,
  guidebook
});

export default reducer;
