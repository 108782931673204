export default {
  user_create: '/api/user/create',
  user_trackingIdStates: '/api/user/t_id_states',
  user_load: '/api/user/load',
  user_postTrackingIdState: '/api/user/t_id_state',
  user_remove: '/api/user/remove',
  set_permission: '/api/user/set_permission',
  user_add_to_organization: '/api/user/add_to_organization',
  user_checkExists: '/api/user/check_exists',
  user_organizations: '/api/user/organizations',
};
