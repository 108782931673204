/** @jsxImportSource @emotion/react */

const StyledFeaturesBox = {
  background: 'url("https://breas3.com/register_features.png") 50% 50%',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  margin: 'auto',
  width: '50%',
  minWidth: '35rem',
  maxWidth: '35rem',
  height: '100%',
  minHeight: '25rem',
  '@media only screen and (max-width: 1180px)': {
    display: 'none',
  },
  '@media only screen and (min-width: 1440px)': {
    margin: 'auto auto auto 0',
  },
};

const FeaturesBox = () => {
  return <div css={StyledFeaturesBox} className={'features-box'} />;
};

export default FeaturesBox;
