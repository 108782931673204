import { createLocalStorageStateHook } from 'use-local-storage-state';
import history from '../lib/history';

export const getToken = () => {
  const rawToken = localStorage.getItem('breaze_login_token');
  return rawToken ? JSON.parse(rawToken) : null;
};

const directUseLoginToken = createLocalStorageStateHook(
  'breaze_login_token',
  null
);

const useLoginToken = () => {
  const [token, setToken] = directUseLoginToken();
  const setLoginToken = (token = null) => {
    setToken(token);
    history.go(0); // refresh the page when changing login token
  };
  return [token, setLoginToken, setToken];
};

export default useLoginToken;
