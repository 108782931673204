import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import LoadedSpinner from '../material/LoadedSpinner';
import getLoader from '../../selectors/config/custom-components/library/get-custom-component-library-loader';
import { useConfig } from '../../providers/Config';

const LoadedComponent = props => {
  const config = useConfig();
  const { library, ...otherProps } = props;
  const [StaticComponent] = React.useState(() => {
    const componentLoader = getLoader(config, library);
    const Component = lazy(componentLoader);
    return Component;
  });
  return (
    <Suspense fallback={<LoadedSpinner isLoading />}>
      <StaticComponent {...otherProps} />
    </Suspense>
  );
};

LoadedComponent.propTypes = {
  library: PropTypes.string.isRequired,
};

export default LoadedComponent;
