/** @jsxImportSource @emotion/react */

const StyledLeftBean = {
  height: '100%',
  margin: 'auto auto auto 0',
  overflow: 'hidden',
  transform: 'scaleX(-1)',
  '@media only screen and (max-width: 768px)': {
    display: 'none',
  },
  svg: {
    width: '250%',
    transform: 'scaleX(-1)',
    path: {
      fill: '#f7ea48',
    },
  },
};

const LeftBean = ({}) => {
  return (
    <div className={'left-bean'} css={StyledLeftBean}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 365.49 282.98"
      >
        <path
          d="M292.64,79.71c21.1,8.15,162.65,86.07,83.42,205.06C301.7,396.45,216.69,329,178.73,317.26S57.17,329.58,37.53,255.78C11.26,157,157.45,20.08,292.64,79.71Z"
          transform="translate(-34.45 -65.19)"
        />
      </svg>
    </div>
  );
};

export default LeftBean;
