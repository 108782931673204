import topNavigationBars from './imports/TopNavigationBarImports';
import homepages from './imports/HomepageImports';
import topicMenus from './imports/TopicMenuImports';
import sectionMenus from './imports/SectionMenuImports';
import pages from './imports/PageImports';
import utilityPanels from './imports/UtilityPanelImports';
import managerDashboards from './imports/ManagerDashboardImports';
import achievementDashboards from './imports/AchievementDashboardImports';
import reportingAndUtilityPages from './imports/ReportingAndUtilityPageImports';
import items from './imports/ItemImports';
import itemEditors from './imports/ItemEditorImports';
import reportingDashboards from './imports/ReportingDashboardImports';

const imports = {
  ...topNavigationBars,
  ...homepages,
  ...topicMenus,
  ...sectionMenus,
  ...pages,
  ...utilityPanels,
  ...managerDashboards,
  ...achievementDashboards,
  ...reportingDashboards,
  ...reportingAndUtilityPages,
  ...items,
  ...itemEditors,
  EmptyComponent: () => Promise.resolve(() => null),
  emptyObject: () => Promise.resolve({}),
  MaterialSpinner: () =>
    import(
      /* webpackChunkName: "material-spinner" */
      '../components/material/MaterialSpinner'
    ),
  ReactColor: () =>
    import(
      /* webpackChunkName: "react-color" */
      'react-color'
    ),
};

export default imports;
