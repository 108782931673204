const Rating = {
  type: 'Rating',
  tags: ['interactives'],
  image: 'https://breas3.com/item-images-v0/rating.jpg',
  rolloverImage:
    'https://breas3.com/item-images-v0/rating_rollover.jpg',
  description:
    'Interactive element to leave a rating (out of five) and a comment',
  tracking_id: null,
  interactive_id: null,
  language: null,
  canMultiItem: true,
  helpExtension: 'rating',
  content: {
    hasFeedback: false,
    header: 'How did you like this course?',
  },
};

export default Rating;
