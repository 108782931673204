const MultipleSelectQuestion = {
  type: 'MultipleSelectQuestion',
  tags: ['questions', 'tracking'],
  image:
    'https://breas3.com/item-images-v0/multipleselectquestion.jpg',
  rolloverImage:
    'https://breas3.com/item-images-v0/multipleselectquestion_rollover.jpg',
  description: 'Knowledge Check question with multiple selectable answers',
  tracking_id: null,
  interactive_id: null,
  language: null,
  forceTracked: true,
  canMultiItem: true,
  helpExtension: 'multiple-select-question',
  content: {
    question: 'Which of the following are planets in our solar system?',
    hideCheckMark: false,
    random: true,
    buckets: [
      {
        value: 'Pluto',
        id: '5f4b100c-4c5d-424f-bb07-74a8f878c5b2',
        correctChoiceId: '',
      },
      {
        value: 'Neptune',
        id: '8707e66b-631b-4051-acd1-81a837499467',
        correctChoiceId: '957c0ebf-26b9-405c-af35-4f5bb1bb2819',
      },
      {
        value: 'Pandora',
        id: 'c898461f-cc6a-4e35-99b3-d640df3ceca1',
        correctChoiceId: '',
      },
      {
        value: 'Mars',
        id: '9265dfc3-221c-4d55-bff1-64e8d604df66',
        correctChoiceId: '957c0ebf-26b9-405c-af35-4f5bb1bb2819',
      },
    ],
    choices: [
      {
        value: 'Single Choice',
        id: '957c0ebf-26b9-405c-af35-4f5bb1bb2819',
      },
    ],
    feedback: {
      correct: "That's right!",
      incorrect: "Uh oh, that wasn't quite right...",
      partial: 'Well, you were close.',
    },
  },
};
export default MultipleSelectQuestion;
