import React from 'react';
import PropTypes from 'prop-types';
import NavigationErrorDialogWrapper from './NavigationErrorDialogWrapper';
import getReturnPath from './get-return-path';
import { useDefaultStrings } from '../../../providers/DefaultStrings';

const ErrorAccessSectionDialog = props => {
  const { error, ok, selectedSectionDoesNotExist } = useDefaultStrings();
  return (
    <NavigationErrorDialogWrapper
      {...props}
      header={error}
      body={`${selectedSectionDoesNotExist}.`}
      accept={ok}
      historyCallback={getReturnPath(`/entry-section.${props.rootParameter}/`)}
    />
  );
};

ErrorAccessSectionDialog.propTypes = {
  rootParameter: PropTypes.string.isRequired,
};

export default ErrorAccessSectionDialog;
