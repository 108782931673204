/** @jsxImportSource @emotion/react */
import React from 'react';
import BreazeTextInput from '../assets/Inputs/BreazeTextInput';
import PrimaryButton from '../assets/buttons/PrimaryButton';
import MaterialSpinner from '../../material/MaterialSpinner';
import {
  getRecaptchaToken,
  loadRecaptchaScript,
} from '../../../actions/re-captcha/recaptcha';
import PropTypes from 'prop-types';
import sendCreateEmail from '../../../api/authentication/send-create-email';

const StyledLandingForm = {
  display: 'flex',
  width: '21rem',
  margin: 'auto',
  maxWidth: '100%',
  boxSizing: 'border-box',
  position: 'relative',
  '.input': {
    width: '15rem',
    marginLeft: 'auto',
  },
  '.primary-button': {
    fontSize: '1.2rem',
    margin: '0 auto 0 1rem',
    button: {
      padding: '0.3rem 2.3rem',
    },
  },
  '.material-spinner': {
    left: 'calc(50% - 2.75rem)',
    top: 'calc(50% - 2.75rem)',
    borderTopColor: 'rgba(215,215,215, 0.5)',
    borderBottomColor: 'rgba(215,215,215, 0.5)',
    borderRightColor: 'rgba(215,215,215, 0.5)',
    borderLeftColor: '#4ac9e3',
  },
  '.auth-message': {
    boxSizing: 'border-box',
    height: '2rem',
    color: '#e64c4c',
    fontSize: '0.8rem',
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    bottom: '-1.5rem',
    overflow: 'hidden',
  },
  '@media only screen and (max-width: 768px)': {
    width: '100%',
    padding: '1rem 0',
    flexDirection: 'column',
    '.input': {
      margin: 'auto',
    },
    '.primary-button': {
      margin: '1rem auto auto auto',
    },
    button: {
      fontSize: '1rem',
    },
  },
};

const LandingForm = ({ setShowCompleteMessage }) => {
  const [email, setEmail] = React.useState({ value: '', valid: false });
  const [isLoading, setIsLoading] = React.useState(false);
  const [authMessage, setAuthMessage] = React.useState('');
  const disabled = !email.valid && !isLoading;

  React.useEffect(() => {
    loadRecaptchaScript();
  }, []);

  const sendForm = captchaToken => {
    setIsLoading(true);
    setAuthMessage('');
    sendCreateEmail(email.value, captchaToken).then(response => {
      if (response.ok) {
        setIsLoading(false);
        setEmail({ value: '', valid: false });
        setShowCompleteMessage(true);
      } else {
        response.text().then(str => {
          setAuthMessage(str);
          setIsLoading(false);
        });
      }
    });
  };

  const onSubmit = () => {
    getRecaptchaToken('createAccount').then(token => {
      sendForm(token);
    });
  };

  return (
    <form css={StyledLandingForm} onSubmit={e => e.preventDefault()}>
      <BreazeTextInput
        label={'Email'}
        pattern={'^.+@.+\\..+$'}
        onChange={setEmail}
        value={email.value}
        helpText={'Please enter a valid email address'}
      />
      <MaterialSpinner isLoading={isLoading} />
      <PrimaryButton
        text={'Submit'}
        onClick={() => onSubmit()}
        disabled={disabled}
      />
      <div className={'auth-message'}>{authMessage}</div>
    </form>
  );
};

LandingForm.propTypes = {
  setShowCompleteMessage: PropTypes.func.isRequired,
};

export default LandingForm;
