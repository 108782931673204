export default {
  Section: 'Section',
  Sections: 'Sections',
  'Section Title': 'Titre De La Section',
  'Add a Section': 'Ajouter une Section',
  'Unnamed Section': 'Section Sans nom',
  Page: 'Page',
  Pages: 'Pages',
  'Create A Page': 'Créer une Page',
  'Add Existing Page': 'Ajouter Page Existante',
  'Unnamed Page': 'Page Sans nom',
  Save: 'Enregistrer',
  Download: 'Télécharger',
  'Export Guidebook': 'Exporter Guidebook',
  'Archive Guidebook': 'Archiver Guidebook',
  Prev: 'Précédent',
  Next: 'Suivant',
  None: 'Aucun',
  'Remove File': 'Supprimer le fichier',
  'Mark Complete': 'Marquer Complète',
  Completed: 'Terminé',
  'Try Again': 'Essaye Encore',
  Submit: 'Soumettre',
  'Page Title': 'Titre De La Page',
  'Page Items': 'Eléments de Page',
  'Untitled Indicator': 'Indicateur Sans Titre',
  'Change Password': 'Changer le mot de passe',
  'Old Password': 'Ancien mot de passe',
  'New Password': 'Nouveau mot de passe',
  'Password Hint': 'Indice de mot de passe',
  'Type here': 'Écrivez ici',
  'Edit Mode': "Mode d'édition",
  'Log Out': 'Se déconnecter',
  Breadcrumbs: 'Aide à la navigation',
  Editor: 'Éditeur',
  Account: 'Compte',
  'Manage Sections': 'Gérer les sections',
  'Create new page': 'Créer une nouvelle page',
  'Insert existing page': 'Insérer une page existante',
  'Existing Pages': 'Pages Existantes',
  Cancel: 'Annuler',
  Publish: 'Publier',
  'Manage Groups': 'Gérer les Groupes',
  'Assign Groups': 'Attribuer des Groupes',
  'Unnamed Group': 'Groupe Sans nom',
  Done: 'Fini',
  'Deselect All': 'Tout Déselectionner',
  'Tracking IDs': 'Identifications de Suivi',
  'Tracking Ids': 'Identifiants de Suivi',
  Assign: 'Assigner',
  Lock: 'Fermer',
  'Assign Image': 'Attribuer une Image',
  Images: 'Images',
  'Upload Images': 'Télécharger des Images',
  'Colour Picker': 'Pipette à Couleurs',
  Stamp: 'Marque',
  Viewed: 'Visité',
  Question: 'Question à Choix Multiples',
  TxtQuestion: 'Question',
  MultipleSelectQuestion: 'Question de Sélection Multiple',
  DragDropMatchingQuestion: "Question d'Appariement par Glisser-Déposer",
  Video: 'Vidéo',
  StatusIndicator: 'Indicateur de Statut',
  progressTracker: 'Suivre les Progrès',
  videoWithMarkdown: 'Vidéo avec Markdown',
  youTubeWithMarkdown: 'YouTube avec Markdown',
  vimeoWithMarkdown: 'Vimeo avec Markdown',
  markdownWithYouTube: 'Markdown avec Youtube',
  markdownWithVimeo: 'Markdown avec Vimeo',
  markdownWithVideo: 'Markdown avec Vidéo',
  markdownWithAudio: 'Markdown avec Acoustique',
  markdownWithImage: 'Markdown avec Image',
  markdownWithMarkdown: 'Markdown avec Markdown',
  markdownWithStamp: 'Markdown avec Marque',
  MarkdownMap: 'Markdown et Carte',
  MapMarkdown: 'Carte et Markdown',
  passwordChangeSuccess: 'Votre mot de passe a été changé avec succès',
  passwordChangeError:
    "Une erreur est survenue et votre mot de passe n'a pas été modifié.",
  'Multiple Choice Question Editor': 'Éditeur de la Question à Choix Multiples',
  'Multiple Select Question Editor':
    'Éditeur de la Question de Sélection Multiple',
  'Drag and Drop Question Editor': 'Éditeur de la Question de Glisser-Déposer',
  Correct: 'Exacte',
  Choice: 'Choix',
  Answer: 'Réponse',
  'Drag Item': 'Article Glissable',
  'Drop Item': 'Article de Dépôt',
  Feedback: 'Réaction',
  'Correct Feedback': 'Réaction Correcte',
  'Incorrect Feedback': 'Réaction Incorrecte',
  'Partial Feedback': 'Réaction Partiel',
  'Randomize?': 'Randomiser?',
  'Markdown Editor': 'Markdown éditeur',
  'Help?': 'Aidez-moi ?',
  'Youtube Video Selector': 'Youtube Sélecteur Vidéo',
  'Paste link here': 'Coller lien ici',
  'Image Editor': 'Image éditeur',
  'Video Editor': 'Vidéo éditeur',
  'Image File Picker': "Sélecteur de fichier d'image",
  'Video File Picker': 'Sélecteur de fichier vidéo',
  'Audio Picker': 'Sélecteur acoustique',
  audioWithMarkdown: 'Acoustique avec Markdown',
  imageWithImage: 'Image avec Image',
  imageWithImageWithImage: 'Image avec Image avec Image',
  CourseLauncher: 'Cours Launcher',
  imageWithMarkdown: 'Image avec Markdown',
  imageWithQuestion: 'Image avec une question à choix multiple',
  imageWithMultipleSelectQuestion: 'Image avec question de sélection multiple',
  stampWithMarkdown: 'Stamp avec Markdown',
  'Map Editor': 'Editeur de carte',
  Audio: 'Acoustique',
  'Set Initial Zoom': 'Set initial Zoom',
  'Set Minimum Zoom': 'Set Zoom minimum',
  'Set Maximum Zoom': 'Set Zoom maximum',
  'Marker Mode': 'Mode de Marqueur',
  'Set Center Coordinates': 'Définir les coordonnées du centre',
  'View Enlarged': 'Vue Agrandie',
  'Map Header': 'Carte Tête',
  'Marker Editor': 'éditeur de Marqueur',
  'Marker Header': 'Marker tête',
  'Marker Body': 'Corps Marker',
  'Delete Marker': 'Supprimer Marqueur',
  Background: 'Fond',
  'Body Text': 'Le corps du texte',
  'Header Text': 'Titre',
  Property: 'propriété',
  'Change Colour': 'Changer la couleur',
  'Reset Colour': 'couleur réinitialisation',
  'Item Colour Properties Editor': 'Article Couleur Properties Editor',
  'Progress Title': 'Progrès Titre',
  Comment: 'Commentaire',
  'Set to None': 'Réinitialiser',
  Upload: 'Télécharger',
  Reporting: 'Rapports',
  Dashboard: 'Tableau de Bord',
  User: 'Utilisateur',
  'All Groups': 'Tous les Groupes',
  'Report Grouping': 'Regroupement de rapports',
  'User ID': "ID de l'utilisateur",
  'User Name': "Nom d'utilisateur",
  'Section Name': 'nom de la section',
  'Average Tracking Ids Completed': 'Ids de suivi Moyenne Achevé',
  'Total Tracking Ids': 'Suivi totale Ids',
  'Average Percentage Completed': 'Pourcentage moyen terminée',
  'Total Completed': 'total Terminé',
  'Tracking Ids Completed': 'Tracking Ids Terminé',
  'Filter By': 'Filtrer Par',
  'Filter By Name': 'Filtrer par nom',
  'Total Sections': 'Nombre de Sections',
  'Total Users': "Nombre d'utilisateurs",
  'Percentage Completed': 'pourcentage Terminé',
  'Back to FiLMS': 'Retour à FiLMS',
  Search: 'Recherche',
  'Vimeo Video Selector': 'Vimeo Sélecteur vidéo',
  'Divider Editor': 'Diviseur éditeur',
  'Divider Bar Background Color': 'Contexte diviseur Color Bar',
  'Divider Background Color': 'Diviseur Couleur de fond',
  'Divider Height': 'Diviseur Hauteur',
  'Divider Arrow Height': 'Diviseur Flèche Hauteur',
  'Divider Arrow Alignment': 'Diviseur Flèche Alignement',
  'Divider Arrow Alignment Percentage':
    'Diviseur Flèche Alignement Pourcentage',
  Left: 'Gauche',
  Center: 'Centre',
  Right: 'Droit',
  Removing: 'Enlever',
  Management: 'La Gestion',
  'User Management': 'Gestion des Utilisateurs',
  'Filter By Group Name': 'Filtrer par Nom de groupe',
  'Filter By User Name': "Filtrer par Nom d'utilisateur",
  Groups: 'Groupes',
  Users: 'Utilisateurs',
  Previous: 'Précédent',
  'All Users': 'Tous Les Utilisateurs',
  Username: "Nom d'utilisateur",
  Reporter: 'Journaliste',
  'Assign Reporting Groups': "Attribuer des groupes d'information",
  'Reassign Groups': 'Attribuer des Groupes',
  Filter: 'Filtre',
  Status: 'Statut',
  'Partially Completed': 'Partiellement achevé',
  Incomplete: 'Incomplet',
  Complete: 'Achevée',
  'No Tracking': 'Pas de localisation',
  'Download as CSV': 'Télécharger en tant que CSV',
  Close: 'Fermer',
  Course: 'Cours',
  'Curriculum Picker': 'Bien sûr sélecteur',
  'Course Launcher Editor': 'Cours Launcher Editor',
  Divider: 'Cloison',
  'Launch Course': 'Lancement de cours',
  'Choose Curriculum for Course Launching':
    'Choisissez Curriculum pour le lancement du cours',
  'Choose Curriculum': 'Choisissez Curriculum',
  closeCourseDialog1:
    'Votre parcours a été lancé dans une nouvelle fenêtre/onglet.',
  closeCourseDialog2:
    'Terminez le cours puis fermez la nouvelle fenêtre / onglet pour continuer.',
  'Course Launcher': 'Cours Launcher',
  'Sketchfab Model Selector': 'Sketchfab Sélecteur de modèle',
  'Sketchfab Model Editor': 'Sketchfab Model Editor',
  'Scroll wheel zooming': 'Molette zoomer',
  'Annotations Visible': 'Annotations visibles',
  'Transparent background': 'fond transparent',
  'Hide model info bar': "Cacher modèle barre d'information",
  'Hide controls': 'contrôles masquer',
  Pro: 'Pro',
  Business: 'Entreprise',
  'Animated entrance': 'entrée animée',
  'Turntable animation': 'animation Turntable',
  'First person mode': 'Premier mode de personne',
  'Preselect annotation': 'Pre Select annotation',
  'Annotation Picker': "le sélecteur d'annotations",
  'Unnamed Annotation': 'Annotation Sans nom',
  'Annotation autopilot': 'Annotation pilote automatique',
  'Reselect Annotation': 'Désélectionner Annotation',
  'Select Annotation': 'Sélectionner Annotation',
  'Value:': 'valeur:',
  General: 'Général',
  Annotations: 'Annotations',
  'Destroy unsaved changes': 'Détruisez les modifications non enregistrées',
  'Saved Successfully': 'Sauvegarde réussie',
  'Exit application?': 'application sortie',
  Interactive: 'Interactif',
  'Interactive Ids': 'Interactif Ids',
  Undo: 'Annuler',
  Choices: 'Les choix',
  Buckets: 'Godets',
  'Exam Editor': "Éditeur d'examen",
  Required: 'Obligatoire',
  Name: 'Prénom',
  'Minimum Grade to Pass': 'Minimum à passer',
  'Number of Questions': 'Nombre de questions',
  Questions: 'Les questions',
  'Randomize Questions': 'Questions aléatoires',
  'Show Question Feedback': 'Afficher la réponse à la question',
  Exam: 'Examen [obsolète par vérification des connaissances]',
  'Question Answered': 'Question Posée',
  Grade: 'Grade',
  'Generate New Questions On Failure':
    "Générer de nouvelles questions sur l'échec",
  Poll: 'Sondage',
  Vote: 'Vote',
  'Poll Editor': 'Éditeur de sondage',
  'Insert Choice': 'Insérer le choix',
  'Rating Editor': 'Éditeur de note',
  'Report On': 'Rapport sur',
  'Type Filter': 'Filtre de type',
  Heading: 'Rubrique',
  'Average Rating': 'Note moyenne',
  'Number Of Responses': 'Nombre de réponses',
  'User Responses': 'Réponses des utilisateurs',
  Rating: 'Évaluation',
  'Responded At': 'Répondu à',
  Interactives: 'Interactifs',
  'Average percentage correct on first attempt':
    'Pourcentage moyen correct à la première tentative',
  'Average number of attempts': 'Nombre moyen de tentatives',
  'Percentage passed on first attempt':
    'Pourcentage passé à la première tentative',
  'Average number of attempts needed to pass':
    'Nombre moyen de tentatives nécessaires pour réussir',
  Yes: 'Oui',
  No: 'Non',
  View: 'vue',
  'Question Response': 'Question Réponse',
  Selection: 'Sélection',
  Bucket: 'Seau',
  'Average percentage on first attempt':
    'Pourcentage moyen lors de la première tentative',
  Passed: 'Passé',
  Answers: 'Réponses',
  'No Responses': 'Pas de réponse',
  'Total Interactives Completed': 'Total des interactions terminées',
  'Total Tracking Ids Completed': 'Total des ID de suivi terminés',
  'Total Interactives': 'Interactifs totaux',
  'Overall Grade': 'Note globale',
  'Insert copy of existing page': 'Insérer une copie de la page existante',
  'Vimeo Error Occurred': 'Erreur Vimeo',
  'Unknown error occurred': "Une erreur inconnue š'est produite",
  'Preparing Upload': 'Préparation du téléchargement',
  'Uploading File(s)': 'Téléchargement de fichier (s)',
  'Syncing File(s)': 'Synchronisation de fichier (s)',
  'Upload Completed': 'Télécharger terminé',
  Syncing: 'Synchronisation',
  Error: 'Erreur',
  'An Error Has Occurred': 'Une erreur est survenue',
  Languages: 'Langues',
  English: 'Anglais',
  French: 'Français',
  OK: "D'accord",
  'Selected location does not exist': "L'emplacement sélectionné n'existe pas",
  'Do not have access to entry section or does not exist':
    "N'a pas accès à la section d'entrée ou n'existe pas",
  'This section is locked. Please complete content before section':
    'Cette section est verrouillée. Veuillez compléter le contenu avant la section',
  'Selected section does not exist': "La section sélectionnée n'existe pas",
  'Selected page does not exist': "La page sélectionnée n'existe pas",
  'This page is locked. Please complete content before page':
    'Cette page est verrouillée. Veuillez compléter le contenu avant la page',
  'Please login to continue. Redirecting to login page.':
    'Merci de vous connecter pour continuer. Redirection vers la page de connexion.',
  'Unexpected HTTP Error, Redirect To Login?':
    'Erreur HTTP inattendue, Redirect to Login?',
  Warning: 'Attention',
  'Changes successfully published': 'Modifications publiées avec succès',
  unlockingThisWillCausePermalinksToBeModified:
    'Le déverrouillage de cette option entraînera la modification des permtabilités lorsque des ' +
    "changements se produiront dans l'identificateur. Si vous utilisez déjà des liens basés sur " +
    "l'identifiant, ne débloquez pas cela.",
  'Page Url Identifier': "Identifiant de l'URL",
  'Section Url Identifier': "Identificateur d'URL de section",
  Language: 'La langue',
  'Topic Title': 'Titre du sujet',
  'Do not have access to topic or does not exist':
    "N'a pas accès au sujet ou n'existe pas",
  Player: 'Joueur',
  All: 'Tout',
  'Show Feedback': 'Afficher les commentaires',
  'Feedback comments (optional)': 'Commentaires et suggestions (facultatif)',
  'Duplicate Item': 'Article en double',
  'Sort by': 'Trier par',
  'Image List': "Liste d'images",
  'Add Image': 'Ajouter une image',
  Switch: 'Commutateur',
  Slide: 'Se Glisser',
  Dots: 'Points',
  'Display Mode': "Mode d'affichage",
  'Select Image': "Sélectionner l'image",
  Okay: "D'accord",
  'Select File': 'Choisir le dossier',
  'Click to Insert': 'Cliquez pour insérer',
  ImageList: 'Image',
  'Course Packages': 'Forfaits Cours',
  'Filter by Package Name': 'Filtrer par nom de package',
  'Create Package': 'Créer un package',
  'No Packages Exist': "Aucun forfait n'existe",
  Back: 'Arrière',
  'Update Package': 'Paquet de mise à jour',
  'Download Course ZIP': 'Cours de téléchargement ZIP',
  'Filter by Section Name': 'Filtrer par le nom de la section',
  'No Sections Assigned': 'Pas de sections attribuées',
  'An unexpected error has occurred. Please restart to continue.':
    "Une erreur imprévue s'est produite. Redémarrez pour continuer.",
  'Start in first Page': 'Commencez en première page',
  About: 'à travers',
  'Eco Version': 'Eco Version',
  'Course Packages which embeds your Eco Site inside a SCORM Player':
    "Paquets de cours qui intègrent votre Eco Site à l'intérieur d'un lecteur SCORM",
  'Cannot run courses in course mode.':
    "Impossible d'exécuter des cours en mode cours.",
  'Eco 2 Go': 'Eco 2 Go',
  imageWithMarkdownWithImage: 'Image avec Markdown avec Image',
  markdownWithImageWithImage: 'Markdown avec image avec image',
  imageWithImageWithMarkdown: 'Image avec Image avec Markdown',
  imageWithYoutube: 'Image avec Youtube',
  youtubeWithImage: 'Youtube avec Image',
  'Report Options': 'Options de rapport',
  'Hide Navigation Bar': 'Cacher la barre de navigation',
  Report: 'Rapport',
  'Tracking Id Completions': 'Suivi des Id Idées',
  'Filter by Page Name': 'Filtrer par nom de page',
  'Filter by Tracking Id Name': "Filtrer par nom d'identifiant de suivi",
  'Clear Report': 'Effacer le rapport',
  'Tracking Id': 'Identifiant de suivi',
  'Tracking Id Completion': "Suivi de l'identification du suivi",
  'User Completions': "Complétions d'utilisateurs",
  'User Completion': "Achèvement de l'utilisateur",
  Login: "S'identifier",
  'Response Details': 'Détails de la réponse',
  Ratings: 'Évaluations',
  Polls: 'Les sondages',
  Exams: 'Examens',
  MultipleSelectQuestions: 'Questions à choix multiple',
  MultipleChoiceQuestions: 'Questions de Sélection Multiple',
  Optimized: 'Optimisé',
  Group: 'Groupe',
  Autoplay: 'Lecture automatique',
  Loop: 'Boucle',
  Autopause: 'Pause automatique',
  Muted: 'Muet',
  questionCorrectText: 'CORRECTE!',
  DocumentSignature: 'Signature du document',
  'Document Signature Editor': 'Éditeur de signature de document',
  'Document URL': 'URL du document',
  'Opened At': 'Ouvert à',
  'Confirmed At': 'Confirmé à',
  DocumentSignatures: 'Signatures de documents',
  URL: 'URL',
  'Document Name': 'Nom du document',
  'Support & User Guide': "Support et guide de l'utilisateur",
  Introduction: 'Introduction',
  'New Content': 'Nouveau Contenu',
  Secondary: 'Secondaire',
  Tertiary: 'Tertiaire',
  'Getting Started': 'Commencer',
  Welcome: 'Bienvenue',
  'Navigate to Home': "Naviguer vers la page d'accueil",
  'Navigate Back': 'Naviguer Retour',
  'Open Menu': 'Ouvrir le menu',
  'Close Menu': 'Fermer le menu',
  Rate: 'Taux',
  Stars: 'Étoiles',
  Star: 'Étoile',
  Play: 'Jouer',
  Pause: 'Pause',
  Home: 'Accueil',
  Description: 'La description',
  'Move to Previous Image': "Déplacer vers l'image précédente",
  'Move to Next Image': "Passer à l'image suivante",
  lottieWithMarkdown: 'Lottie avec Markdown',
  markdownWithLottie: 'Markdown avec Lottie',
  achievementEditor: 'Editeur de certificats',
  userDashboard: 'Tableau de bord utilisateur',
  achievementBasics: 'les bases du certificat',
  expiryReset: 'Expiration et réinitialisation',
  requirementsLabel: 'Exigence(s)',
  trackingRequirement: 'Identifiant de suivi (s) exigences',
  sectionRequirement: 'Section(s) exigences',
  pageRequirement: 'Page(s) exigences',
  previewSave: 'Prévisualiser et sauvegarder',
  achievementName: 'Nom de certificat',
  achievementDescription: 'Description de la certificat',
  expiry: 'Expiration',
  reset: 'réinitialiser',
  potentialCriteria: 'Critères certificat',
  addedCriteria: 'Critères ajoutés',
  threshold: 'seuil',
  achievementIcon: 'Icône de certificat',
  achievements: 'Certificats',
  dashboardGreeting: "Bienvenue sur le tableau de bord de l'utilisateur",
  earnedOn: 'Gagné sur ',
  resetOn: 'Progression réinitialise sur ',
  expiresOn: 'Expire le ',
  earnedAchievementsName: 'Certificats gagnés',
  expiredAchievements: 'Certificats expirés',
  obtainableAchievements: 'Certificats en cours',
  cancelAndClose: 'Annuler les modifications et fermer',
  achievementReport: 'rapport de certificat',
  achievementId: 'id de certificat',
  earnedAt: 'Gagné sur',
  resetsOn: 'Réinitialise sur',
  isReset: 'Est réinitialisé',
  noData:
    "Personne n'a encore de certificat, essayez d'en créer un dans " +
    "l'éditeur de certificats!",
  achievementReportWelcome: 'Bienvenue sur le rapport de certificat',
  createNewAchievement: 'Créer un nouveau certificat',
  achievementDisabled: 'Certificat désactivé',
  disabledWarning:
    "Avertissement: cette certificat est désactivée. Il ne peut pas être gagné pour le moment, mais sera toujours montré à ceux qui l'ont déjà gagné.",
  editImage: "changer l'image",
  expiryString: "à partir de la date d'obtention du certificat",
  noIcon: "pas d'icône",
  noIconSelected: 'aucune icône sélectionnée',
  selectedIcon: 'Icône sélectionnée:',
  previewPopup: 'aperçu popup',
  looksGood: 'Cela semble bon',
  achievementsNameWarning: 'les certificats nécessitent une valeur de nom',
  achievementsDescriptionWarning:
    'les certificats nécessitent une valeur de description',
  requirementWarning:
    'La configuration requise est invalide. Il doit y avoir au moins une exigence' +
    ' et toutes les exigences doivent avoir au moins un élément de critère attribué' +
    ' pour eux.',
  expiryResetWarning:
    "Toute modification de l'expiration ou de la réinitialisation affectera " +
    "uniquement les utilisateurs qui n'ont pas gagné ou regagné ce certificat.",
  requirementsModificationWarning:
    "Toute modification des exigences n'affectera que les utilisateurs qui " +
    "n'ont pas gagné ou regagné cet exploit.",
  Earned: 'Réussi',
  Delete: 'effacer',
  yourProgress: 'Votre progression',
  markAllAsRead: 'Tout marquer comme lu',
  newText: 'Nouveau',
  Read: 'lis',
  noNotifications: "Vous n'avez actuellement aucune notification",
  editIcon: "Icône d'édition",
  selectIcon: "Sélectionnez l'icône",
  displayType: "Type d'affichage",
  editColor: 'Modifier la couleur',
  editDisplayType: "Changer le type d'affichage",
  certificationOfCompletion: "Certificat d'achèvement",
  hasCompleted: ' accompli ',
  dateCertified: 'Date certifiée: ',
  expiryDate: "Date d'expiration",
  displayProperties: "Propriétés d'affichage",
  certificateIncomplete: 'Certificat incomplet',
  thumbnailPickerText:
    'Entrez un horodatage de la vidéo en secondes à partir de la vidéo sélectionnée pour définir une vignette.',
  thumbnailPickerPreview: 'éditer la miniature de la vidéo',
  nameLabel: 'Prénom',
  completed: 'Terminé',
  leftBoxDefaultText: "Pour l'achèvement du certificat",
  presentedTo: 'PRÉSENTÉ À:',
  dateOfExpiry: "date d'expiration",
  dateOfCompletion: "Date d'achèvement",
  editorResetWarning:
    'Êtes-vous sûr de vouloir vous reposer de vos progrès pour cet article',
  assessmentText: "Texte d'évaluation",
  awaitingContent: 'Contenu en attente',
  awaitingUser: 'Utilisateur en attente',
  awaitingManager: 'Gestionnaire en attente',
  completedText: 'Texte complété',
  assessmentEditor: "Éditeur d'évaluation",
  AssessmentExam: "Examen d'évaluation",
  PDFLink: 'Lien PDF',
  buttonText: 'Texte du bouton',
  Resources: 'Ressources',
  PDFScheduleLinks: 'Calendrier des liens',
  firstButtonText: 'Texte du premier bouton',
  secondButtonText: 'Texte du deuxième bouton',
  firstLink: 'Premier lien',
  secondLink: 'Deuxième lien',
  SectionEnd: 'Fin de section',
  sectionResults: 'Résultats de la section',
  pageResults: 'Résultats de la page',
  Communication: 'La Communication',
  notificationSettings: 'Paramètres de notification',
  myMessages: 'Mes messages',
  showHiddenMessagesLabel: 'Afficher les messages cachés',
  sendMessages: 'Envoyer des Messages',
  sentMessages: 'Messages Envoyés',
  noMessages: 'Aucun message à afficher',
  hideMessage: 'Masquer le message',
  receivedOn: 'Reçu Le',
  viewedOn: 'Vu Sur',
  from: 'De:',
  addRemoveRecipients: 'Ajouter / supprimer des destinataires',
  editMessageContent: 'Modifier le contenu du message',
  to: 'To:',
  sentOn: 'Envoyé le',
  invalidFileTypes: 'Types de fichiers invalides trouvés',
  fileSizeTooBig: 'La taille du fichier est trop grande',
  filesUploadedSuccessfully: 'Fichiers téléchargés avec succès',
  oneOrMoreFilesFailedToUpload:
    "Échec du téléchargement d'un ou plusieurs fichiers",
  successfullyDeleted: 'Supprimé avec succès',
  failedToDelete: 'Échec de la suppression',
  uploadingFiles: 'télécharger des fichiers',
  Emails: 'Email',
  Valid: 'valide',
  use: 'utilisation',
  Edit: 'Modifier',
  phoneNumbers: 'Numéros de téléphone',
  copySectionWithNewPageIds:
    'Faites une copie avec les nouveaux identifiants de page',
  copySectionWithDuplicatePageIds:
    'Faire une copie avec les ID de page en double',
  managerDashboard: 'Tableau de bord du gestionnaire',
  Dashboards: 'Tableaux de bord',
  selectAGroup: 'sélectionner un groupe',
  selectAType: 'sélectionner un type',
  usersComplete: 'utilisateurs complets',
  usersAssigned: 'utilisateurs assignés',
  sectionUserTitle: 'section utilisateurs finaux',
  achievementUserTitle: 'Certificats utilisateurs finaux',
  enterSearchTerm: 'entrez le terme de recherche',
  userAchievementTitle: 'utilisateurs Certificats finaux',
  userSectionTitle: 'utilisateurs section finaux',
  completeUsers: 'utilisateurs complets',
  eligibleUsers: 'utilisateurs éligibles',
  completedAchievements: 'Certificats accomplies',
  eligibleAchievements: 'Certificats éligibles',
  sectionsCompleted: 'sections complétées',
  sectionsAssigned: 'sections assignées',
  completedAt: 'complété à',
  Confirm: 'Confirmer',
  KnowledgeCheck: 'Vérification des connaissances',
  enableAssessment: "permettre l'évaluation",
  enableExam: "permettre l'examen",
  Assessment: 'évaluation [obsolète par vérification des connaissances]',
  responseDetailsText: 'Détails de la réponse pour tentative',
  knowledgeCheckRequests: 'demandes de vérification des connaissances',
  noAttempts: 'aucune tentative',
  Attempts: 'Tentatives',
  lastUpdated: 'Dernière mise à jour',
  Actions: 'Actions',
  Pass: 'Passer',
  Fail: 'Échouer',
  sortAlphabetically: 'trier par ordre alphabétique',
  noDataToDisplay: 'aucune donnée à afficher',
  overallProgressOfUsers: 'progrès global des utilisateurs',
  getCSV: 'obtenir le CSV',
  subjectLine: 'Sujet',
  messageBody: 'Corps du message',
  sortByGroup: 'Trier par groupe',
  people: 'Personnes',
  selectAllInList: 'Sélectionner tout de cette liste',
  deselectAllInList: 'Tout désélectionner de cette liste',
  send: 'Envoyer',
  NA: 'S/O',
  knowledgeChecks: 'vérification des connaissances',
  knowledgeChecksTitle:
    'vérifications des connaissances pour tous les groupes et utilisateurs que vous gérez',
  manager: 'Directeur',
  PageNavigationBar: 'Barre de navigation de page',
  previousLesson: 'Leçon précédente',
  nextLesson: 'Prochaine leçon',
  overallProgression: 'Progression globale',
  myCertificates: 'Mes certificats',
  assignSelected: 'Attribuer la sélection',
  createNew: 'Créer un nouveau',
  enterName: 'Entrez le nom',
  TrackedVideo: 'vidéo suivie',
  requiredCompletionPercentage: "pourcentage d'achèvement requis",
  lastVisitedText:
    'Vous avez déjà visité cette section, souhaitez-vous reprendre là où vous vous étiez arrêté?',
  imageStatusIndicator: "indicateur d'état de l'image",
  incompleteImage: 'image incomplète',
  completeImage: 'image complète',
  userCompletionPercentTitle:
    "achèvement global par l'utilisateur du contenu attribué",
  completionPercentage: "pourcentage d'achèvement",
  pendingAssessments: 'Évaluations en attente',
  processedAssessments: 'Évaluations traitées',
  Failed: 'Échoué',
  backToLearning: "Retour à l'apprentissage",
  sectionEmptyError: "La section n'a pas de pages navigables",
  turnOnEditModeGoToSection: 'Activez le mode édition et passez à la section?',
  hideFeedbackCheckMark: "Masquer la coche des retour d'information",
  inProgress: 'En cours',
  Expired: 'Expiré',
  noneInProgress: "vous n'avez pas de certificat incomplet",
  noneEarned: "vous n'avez pas de certificat complété",
  welcome: 'Bienvenue',
  myAccount: 'Mon Compte',
  trueText: 'Vrai',
  falseText: 'Faux',
  locked: 'Fermé à clé',
  invalid: 'Invalide',
  invalidInputs:
    "Impossible de traiter la demande car certaines valeurs d'entrée ne sont pas valides",
  passwordMatchFailed:
    'Les champs Nouveau mot de passe et Confirmer le nouveau mot de passe doivent avoir des valeurs correspondantes pour continuer',
  passwordErrorMessage:
    "L'utilisateur doit être connecté et fournir son mot de passe valide actuel",
  changesSavedSuccessfully: 'Changements sauvegardés avec succès',
  submitAndClose: 'Soumettre et Fermer',
  displayName: 'Nom Visuel',
  currentPassword: 'Mot de passe actuel',
  confirmNewPassword: 'Confirmez le nouveau mot de passe',
  basics: 'Basiques',
  details: 'Détails',
  usernameDescription:
    "Nom d'utilisateur personnel sur lequel vous pouvez vous connecter.",
  displayNameDescription: 'Editor Comment votre nom apparaît dans eco',
  detailsDescription:
    "Contrôlez vos paramètres du système de gestion de l'apprentissage pour déterminer le contenu auquel il est possible ou non d'accéder et déterminera votre expérience sur la plateforme.",
  notStartedUsers: 'utilisateurs non démarrés',
  partiallyCompleteUsers: 'utilisateurs partiellement complets',
  noneExpired: "vous n'avez pas de certificat expiré",
  emptyInteractiveOpen: 'Cette ',
  emptyInteractiveClose: "n'a pas été configurée",
  interactiveEditor: 'Éditeur interactif',
  selectInteractive: 'Sélectionnez un Id interactif',
  interactiveContent: 'Contenu interactif',
  select: 'Sélectionner',
  interactive: 'Interactif',
  change: 'changement',
  itemError: 'Un problème est survenu avec cet article',
  pageLayoutBuilder: 'Générateur de mise en page',
  markdown: 'Text',
  confirmChanges: 'Confirmer les modifications',
  developFromTemplate: "Développer à partir d'un modèle",
  addIndividualItems: 'Ajouter des éléments individuels',
  responseSubmitted: 'Réponse Soumise',
  submitAnswer: 'Soumettre la réponse',
  enterAnswerHere: 'Entrez la réponse ici',
  responseTooShort: 'Réponse trop courte',
  responseTooLong: 'Réponse trop longue',
  searchTerm: 'Terme de recherche',
  Contains: 'contient',
  Matches: 'correspond à',
  doesNotMatch: 'Ne correspond pas',
  beginsWith: 'Commence par',
  endsWith: 'se terminant par',
  equalTo: 'correspondance exacte',
  notEqualTo: "n'est pas égal",
  Between: 'Entre',
  greaterThan: 'Plus grand que',
  lessThan: 'moins que',
  On: 'sur',
  Before: 'avant',
  After: 'après',
  Apply: 'appliquer',
  Clear: 'jeter',
  selectDates: 'sélectionner des dates',
  selectDate: 'sélectionner une date',
  allDates: 'toutes les dates',
  editReportColumns: 'modifier les colonnes du rapport',
  editSortingOptions: 'modifier les colonnes de tri',
  editFilters: 'modifier les filtres',
  saveAndClose: 'sauver et fermer',
  cancelChangesAndClose: 'annuler les modifications et fermer',
  Field: 'colonne',
  Priority: 'Priorité',
  Order: 'Commandé',
  noSort: 'Pas de tri',
  Ascending: 'Ordre croissant',
  Descending: 'Ordre décroissant',
  loadTemplate: 'Charger le modèle',
  saveTemplate: 'Enregistrer le modèle',
  selectATemplate: 'Sélectionnez un modèle',
  reportTitle: 'Titre du rapport',
  templateName: 'Nom du modèle',
  Templates: 'Modèles',
  loadingTemplates: 'Chargement des modèles',
  editReport: 'Modifier le rapport',
  inReport: 'Dans le rapport',
  notInReport: 'Pas dans le rapport',
  VrLauncher: 'Lanceur VR',
  vrLauncherEditor: 'Éditeur Lanceur VR',
  selectACourse: 'Sélectionnez un cours',
  noCourseSelected: 'Aucun cours sélectionné',
  notStarted: 'Pas commencé',
  personalInteractiveReports: 'Rapports Interactifs Personnels',
  noInteractiveReportsAtThisTime: 'Aucun rapport interactif pour le moment',
  clickToDownloadAndGenerate:
    'Cliquez pour générer un rapport et le télécharger automatiquement',
  myReports: 'Mes rapports',
  writtenResponse: 'Réponses écrites',
  sideMargins: 'Marges latérales',
  requirements: 'Conditions',
  passKnowledgeCheck:
    "Veuillez confirmer que l'utilisateur a réussi cette vérification des connaissances",
  failKnowledgeCheck:
    "Veuillez confirmer que l'utilisateur a échoué à cette vérification des connaissances",
  requirement: 'Exigence',
  sectionDescription: 'description de la section',
  addSection: 'Ajouter une section',
  addLesson: 'Ajouter une leçon',
  courseLive: 'Faire vivre le cours',
  courseLiveBlurb: 'Cela rendra ce cours visible pour votre équipe',
  publishChanges: 'Publier les modifications',
  editCourseSettings: 'Modifier les paramètres du cours',
  courseTitle: 'Titre de cours',
  editing: 'Édition',
  live: 'Vivre',
  category: 'Catégorie',
  createANewCourse: 'Créer un nouveau cours',
  getStartedInMinutes:
    'Commencez et créez votre premier cours en quelques minutes',
  getStartedHomepageBlurb:
    'Le bouton Créer un cours créera un cours de base avec une page de leçon de démarrage que vous pourrez modifier',
  sectionDeleteBlurb:
    'Cette section contient des leçons, si vous supprimez la section, vous supprimerez également ses leçons.',
  sectionDeleteAccept: 'Supprimer la section et ses leçons',
  start: 'Démarrer',
  lessons: 'Cours',
  pickATemplate: 'Choisissez un modèle',
  blankPage: 'Page blanche',
  returnToCourseEditing: 'Revenir à l\'édition de cours',
  createCourse: 'Créer un cours',
  basic: 'De base',
  refineSearch: 'Affiner votre recherche',
  horizontal: 'Horizontal',
  vertical: 'Vertical',
  orientation: 'Orientation',
  useSelected: 'Utiliser la sélection',
  enterSearchToBegin: 'Entrez la recherche pour commencer',
  noResultsFound: 'Aucun résultat trouvé',
  clickToSelect: 'Cliquez pour sélectionner',
  selected: 'Choisi',
  local: 'Local',
  loaded: 'Chargé',
  addFromStock: 'Ajouter du stock',
  howToUserGuide: 'Guide de l\'utilisateur',
  Overview: 'Aperçu',
  Content: 'Matériaux',
  Advanced: 'Avancée',
  Difficulty: 'Difficulté',
  Medium: 'Moyen',
};
