import history from '../../lib/history';
import historyReplace from '../../actions/history/replace-history';
import setStateFromHistory from '../../actions/history/set-state-from-history';
import store from '../../store';

const addHistoryEventListener = config => {
  const {
    location: { pathname, search },
  } = history;
  history.listen(historyParams =>
    store.dispatch(setStateFromHistory(historyParams, config))
  );
  historyReplace(`${pathname}${search}`);
};

export default addHistoryEventListener;
