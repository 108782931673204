import getFirstUnsavedInteractive from './get-first-unsaved-interactive';

const hasEdited = state => {
  const { guidebook } = state;
  const hasEditedGuidebook = guidebook.dirtySections.size > 0 || guidebook.dirtyPages.size > 0;
  const hasEditedInteractives = getFirstUnsavedInteractive(state) !== null;
  return hasEditedGuidebook || hasEditedInteractives;
};

export default hasEdited;
