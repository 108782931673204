import sortBy from 'lodash/sortBy';
import getkebabedGuidebooks from '../guidebooks/get-kebabed-guidebooks';

const getTopics = state => {
  const kebabedGuidebooks = getkebabedGuidebooks(state);
  const uncategorizedTopics = kebabedGuidebooks
    .map(({ name, sort_weight, ..._ }, key) => ({
      key,
      name,
      sort_weight,
      sections: [{ name, ..._ }],
    }));
  return sortBy(sortBy(uncategorizedTopics, 'name'), 'sort_weight');
};

export default getTopics;
