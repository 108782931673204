import getParseOrder from '../../selectors/config/navigation/get-parse-order';

const cache = {
  calculatedValues: {},
  guidebook: {},
  completedTrackingIds: {},
};

const reset = () => {
  cache.calculatedValues = {
    completeTrackingIdPerPage: new Map(),
    incompleteTrackingIdPerPage: new Map(),
    completeTrackingIdPerSection: new Map(),
    incompleteTrackingIdPerSection: new Map(),
    completePagePerSection: new Map(),
    incompletePagePerSection: new Map(),
    descriptionPages: new Set(),
    lockedSections: new Set(),
    lockedPages: new Set(),
    crumbs: new Map(),
    firstPage: undefined,
  };
};

const funkySort = (toSortArray, sortedArray) =>
  toSortArray.sort((a, b) => {
    const ai = sortedArray.indexOf(a);
    if (ai === -1) return 1;
    const bi = sortedArray.indexOf(b);
    if (bi === -1) return -1;
    return ai > bi ? 1 : -1;
  });

const calculatePageProgress = (
  pageItems,
  completedTrackingIds,
  completeTrackingIdPerPage,
  incompleteTrackingIdPerPage
) => {
  pageItems.forEach((pageItem, id) => {
    let completeCount = 0;
    let incompleteCount = 0;
    pageItem.forEach(item => {
      if (item.tracking_id) {
        if (completedTrackingIds.has(item.tracking_id)) {
          completeCount += 1;
        } else {
          incompleteCount += 1;
        }
      }
    });
    completeTrackingIdPerPage.set(id, completeCount);
    incompleteTrackingIdPerPage.set(id, incompleteCount);
  });
};

const setMapOfArray = (target, id, value) =>
  target.has(id) ? target.get(id).push(value) : target.set(id, [value]);

const doSectionsBeforePagesCalculations = (guidebook, completedTrackingIds) => {
  const { root, working } = guidebook;
  const { sectionNodes, pageItems, childPages, childSections } = working;
  const rootSection = sectionNodes.get(root) || [];
  const rootLocked = rootSection.lock;
  reset();
  const {
    completeTrackingIdPerPage,
    incompleteTrackingIdPerPage,
    completeTrackingIdPerSection,
    incompleteTrackingIdPerSection,
    completePagePerSection,
    incompletePagePerSection,
    descriptionPages,
    lockedSections,
    lockedPages,
    crumbs,
  } = cache.calculatedValues;
  let foundFirstPage = false;

  calculatePageProgress(
    pageItems,
    completedTrackingIds,
    completeTrackingIdPerPage,
    incompleteTrackingIdPerPage
  );

  const calcPerSection = (id, locked, rootLocked) => {
    let lockNext = locked;
    setMapOfArray(crumbs, id, id);
    const countOf = {
      completeTrackingIds: 0,
      incompleteTrackingIds: 0,
      completePages: 0,
      incompletePages: 0,
    };
    const pageIds = childPages.get(id) || [];
    const sectionIds = childSections.get(id) || [];
    const section = sectionNodes.get(id) || [];
    if (rootLocked || !foundFirstPage || section.description) {
      funkySort(pageIds, section.pages);
      funkySort(sectionIds, section.sections);
    }

    let lookingForDescriptionPage = section.description;

    sectionIds.forEach(sectionId => {
      if (lockNext) {
        lockedSections.add(sectionId);
      }
      crumbs.set(sectionId, crumbs.get(id).slice());
      calcPerSection(sectionId, lockNext, rootLocked);
      countOf.completeTrackingIds += completeTrackingIdPerSection.get(
        sectionId
      );
      countOf.incompleteTrackingIds += incompleteTrackingIdPerSection.get(
        sectionId
      );
      countOf.completePages += completePagePerSection.get(sectionId);
      countOf.incompletePages += incompletePagePerSection.get(sectionId);
      if (!lockNext && rootLocked && countOf.incompleteTrackingIds !== 0) {
        lockNext = true;
      }
    });
    pageIds.forEach(pageId => {
      if (!foundFirstPage) {
        foundFirstPage = true;
        cache.calculatedValues.firstPage = `${id} ${pageId}`;
      }
      if (lookingForDescriptionPage) {
        lookingForDescriptionPage = false;
        descriptionPages.add(`${id} ${pageId}`);
      }
      if (lockNext) {
        lockedPages.add(`${id} ${pageId}`);
      }
      const completePageTrackingIdCount = completeTrackingIdPerPage.get(pageId);
      const incompletePageTrackingIdCount = incompleteTrackingIdPerPage.get(
        pageId
      );
      countOf.completeTrackingIds += completePageTrackingIdCount;
      countOf.incompleteTrackingIds += incompletePageTrackingIdCount;
      if (
        incompletePageTrackingIdCount !== undefined &&
        incompletePageTrackingIdCount !== 0
      ) {
        countOf.incompletePages += 1;
      } else if (
        completePageTrackingIdCount !== undefined &&
        completePageTrackingIdCount !== 0
      ) {
        countOf.completePages += 1;
      }
      if (!lockNext && rootLocked && incompletePageTrackingIdCount) {
        lockNext = true;
      }
    });

    completeTrackingIdPerSection.set(id, countOf.completeTrackingIds);
    incompleteTrackingIdPerSection.set(id, countOf.incompleteTrackingIds);
    completePagePerSection.set(id, countOf.completePages);
    incompletePagePerSection.set(id, countOf.incompletePages);
  };
  calcPerSection(root, false, rootLocked);
};

const doPagesBeforeSectionsCalculations = (guidebook, completedTrackingIds) => {
  const { root, working } = guidebook;
  const { sectionNodes, pageItems, childPages, childSections } = working;
  const rootSection = sectionNodes.get(root) || [];
  const rootLocked = rootSection.lock;
  reset();
  const {
    completeTrackingIdPerPage,
    incompleteTrackingIdPerPage,
    completeTrackingIdPerSection,
    incompleteTrackingIdPerSection,
    completePagePerSection,
    incompletePagePerSection,
    descriptionPages,
    lockedSections,
    lockedPages,
    crumbs,
  } = cache.calculatedValues;
  let foundFirstPage = false;

  calculatePageProgress(
    pageItems,
    completedTrackingIds,
    completeTrackingIdPerPage,
    incompleteTrackingIdPerPage
  );

  const calcPerSection = (id, locked, rootLocked) => {
    let lockNext = locked;

    setMapOfArray(crumbs, id, id);
    const countOf = {
      completeTrackingIds: 0,
      incompleteTrackingIds: 0,
      completePages: 0,
      incompletePages: 0,
    };
    const pageIds = childPages.get(id) || [];
    const sectionIds = childSections.get(id) || [];
    const section = sectionNodes.get(id) || [];

    if (rootLocked || !foundFirstPage || section.description) {
      funkySort(pageIds, section.pages);
      funkySort(sectionIds, section.sections);
    }
    let lookingForDescriptionPage = section.description;
    pageIds.forEach(pageId => {
      if (!foundFirstPage) {
        foundFirstPage = true;
        cache.calculatedValues.firstPage = `${id} ${pageId}`;
      }
      if (lookingForDescriptionPage) {
        lookingForDescriptionPage = false;
        descriptionPages.add(`${id} ${pageId}`);
      }
      if (lockNext) {
        lockedPages.add(`${id} ${pageId}`);
      }
      const completePageTrackingIdCount = completeTrackingIdPerPage.get(pageId);
      const incompletePageTrackingIdCount = incompleteTrackingIdPerPage.get(
        pageId
      );
      countOf.completeTrackingIds += completePageTrackingIdCount;
      countOf.incompleteTrackingIds += incompletePageTrackingIdCount;
      if (
        incompletePageTrackingIdCount !== undefined &&
        incompletePageTrackingIdCount !== 0
      ) {
        countOf.incompletePages += 1;
      } else if (
        completePageTrackingIdCount !== undefined &&
        completePageTrackingIdCount !== 0
      ) {
        countOf.completePages += 1;
      }
      if (!lockNext && rootLocked && incompletePageTrackingIdCount) {
        lockNext = true;
      }
    });

    sectionIds.forEach(sectionId => {
      if (lockNext) {
        lockedSections.add(sectionId);
      }
      crumbs.set(sectionId, crumbs.get(id).slice());
      calcPerSection(sectionId, lockNext, rootLocked);
      countOf.completeTrackingIds += completeTrackingIdPerSection.get(
        sectionId
      );
      countOf.incompleteTrackingIds += incompleteTrackingIdPerSection.get(
        sectionId
      );
      countOf.completePages += completePagePerSection.get(sectionId);
      countOf.incompletePages += incompletePagePerSection.get(sectionId);
      if (!lockNext && rootLocked && countOf.incompleteTrackingIds !== 0) {
        lockNext = true;
      }
    });
    completeTrackingIdPerSection.set(id, countOf.completeTrackingIds);
    incompleteTrackingIdPerSection.set(id, countOf.incompleteTrackingIds);
    completePagePerSection.set(id, countOf.completePages);
    incompletePagePerSection.set(id, countOf.incompletePages);
  };
  calcPerSection(root, false, rootLocked);
};

const getCalculatedValues = (state, config) => {
  const { guidebook, player } = state;
  const { completedTrackingIds } = player;
  if (
    cache.guidebook !== guidebook ||
    cache.completedTrackingIds !== completedTrackingIds
  ) {
    if (getParseOrder(config) === 'sectionsBeforePages') {
      doSectionsBeforePagesCalculations(guidebook, completedTrackingIds);
    } else {
      doPagesBeforeSectionsCalculations(guidebook, completedTrackingIds);
    }
    cache.guidebook = guidebook;
    cache.completedTrackingIds = completedTrackingIds;
  }
  return cache.calculatedValues;
};

export default getCalculatedValues;
