import reduxEvents from './redux-events';

const set = (_, data) => data;

const titleTranslationsEvents = reduxEvents().addEvent(
  'TITLE_TRANSLATIONS',
  'SET',
  set
);

const titleTranslations = titleTranslationsEvents.getReducer([]);
const titleTranslationsActionCreator = titleTranslationsEvents.getActionCreators();

export { titleTranslations, titleTranslationsActionCreator };
