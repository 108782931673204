/** @jsxImportSource @emotion/react */
import CreateOrganizationBody from './create_org/CreateOrganizationBody';
import MaterialSpinner from '../../material/MaterialSpinner';
import React from 'react';
import PropTypes from 'prop-types';
import CreateUserAndOrganizationBody from './create_user_and_org/CreateUserAndOrganizationBody';

const Form = ({ userExists, loading, ...props }) => {
  return loading ? (
    <MaterialSpinner isLoading={true} />
  ) : userExists ? (
    <CreateOrganizationBody {...props} />
  ) : (
    <CreateUserAndOrganizationBody {...props} />
  );
};

Form.propTypes = {
  userExists: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Form;
