const WrittenResponse = {
  type: 'WrittenResponse',
  tags: ['questions', 'tracking'],
  image:
    'https://breas3.com/item-images-v0/writtenresponse.jpg',
  rolloverImage:
    'https://breas3.com/item-images-v0/writtenresponse_rollover.jpg',
  description: 'Question with available space for a typed in short answer',
  tracking_id: null,
  interactive_id: null,
  language: null,
  forceTracked: true,
  canMultiItem: true,
  helpExtension: 'written-response',
  content: {
    question: 'What is your greatest vacation memory?',
    minimum_response_length: 16,
    maximum_response_length: 2048,
  },
};
export default WrittenResponse;
