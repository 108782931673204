import React from 'react';
import { useConfig } from './Config';
import { ThemeProvider } from '@emotion/react';

//#region defaultTheme and deriveTheme
const defaultTheme = {
  breazeBlue: '#4AC9E3',
  breazeYellow: '#F7EA48',
  breazeOrange: '#FED141',
  breazeGreen: '#C5E86C',
  breazeNavy: '#253746',
  breazeRed: '#FE5107',
  primaryColor: '#8dc442',
  secondaryColor: '#816853',
  tertiaryColor: '#D9D6D4',
  background: 'transparent',
  pageBackground: '#fff',
  pageColor: '#000',
  topNavigationBarBackground: 'white',
  topNavigationBarHeight: '2.25rem',
  pageNavBackground: '#E0E1E0',
  pageNavColor: '#000',
  subSectionColor: '#000',
  dashboardBgColour: '#eef2f5',
  subPanelBorderColour: '#dbdfe2',
  tableBorderColour: '#a9a9a9',
  sectionIconColour: '#4183D7',
  sectionIconHoverColour: '#3363a5',
  userIconColour: '#1F3A93',
  pageIconColour: '#34495E',
  keyboardIconColour: '#22313F',
  trackingIdIconColour: '#3498DB',
  completeIconColour: '#8BC34A',
  incompleteIconColour: '#F44336',
  partialCompleteIconColour: '#FFEB3B',
  hoverTextColour: '#2446be',
  subPanelBgColour: '#5e6d80',
  logo: "url('https://s3-ca1.icom.productions/assets/eco/logo.png')",
  logoHeight: '1.75rem',
  logoWidth: false,
  radialProgressHeight: '13.5625rem',
  radialProgressUrl: '',
  stampHeight: '17.1875rem',
  stampIncompleteUrl:
    "url('https://s3-ca1.icom.productions/assets/eco/stamp-incomplete.png')",
  stampCompleteUrl:
    "url('https://s3-ca1.icom.productions/assets/eco/stamp-complete.png')",
  interactiveBackground: '#3E3E3D',
  interactiveColor: '#fff',
  interactiveFeedbackColor: '#c5e860',
  legacyTopNavigationBar: false,
  legacyCourseLauncherIcon: false,
  legacyItemLayout: false,
  markdownListColor: '#000',
  markdownLinkUnderlined: false,
  markdownLinkBold: false,
  markdownLinkColor: false,
  markdownLinkVisitedColor: false,
  markdownLinkActiveColor: false,
  markdownBackground: 'initial',
  questionIconsUrl: '',
  questionIconsHeight: '1.875rem',
  sectionIconsUrl: '',
  sectionIconWidth: '1.875rem',
  sectionIconHeight: '',
  fixQuestionLayout: true,
  sectionSubItemIncompleteColor: '#5B5B5B',
  CustomSectionMenu: false,
  CustomSectionMenuV2: false,
  iconsUrl: '',
  fontFamily: "'Rubik', sans-serif;",
  customProgressCircles: '',
  customProgressCirclesOutside: '',
  customProgressCirclesCompleteBadge: '',
  css: '',
  mobile: 'only screen and (max-width: 529px)',
  tablet: 'only screen and (min-width: 530px) and (max-width: 959px)',
  desktopSm: 'only screen and (min-width: 960px) and (max-width: 1128px)',
  desktopMd: 'only screen and (min-width: 1129px) and (max-width: 1439px)',
  desktopLg: 'only screen and (min-width: 1440px)',
  markdownMap: 'only screen and (max-width: 750px)',
};

const deriveTheme = theme => {
  const {
    primaryColor,
    pageNavColor,
    secondaryColor,
    tertiaryColor,
    interactiveBackground,
    topNavigationBarBackground,
    questionIconsHeight,
    sectionIconWidth,
    radialProgressHeight,
    topNavigationBarIconColor = primaryColor,
    pageNavLockedColor = pageNavColor,
    radialProgressColor = primaryColor,
    sectionMenuColor = primaryColor,
    guidebookListItemBackground = primaryColor,
    progressBarBackground = secondaryColor,
    stampBackground = tertiaryColor,
    interactiveSelectedColor = primaryColor,
    markdownColor = primaryColor,
    dragAndDropColor = primaryColor,
    pageNameBackground = primaryColor,
    dragAndDropBackground = interactiveBackground,
    reportingAccordionBg = topNavigationBarBackground,
    questionIconsWidth = questionIconsHeight,
    sectionIconHeight = sectionIconWidth,
    radialProgressWidth = radialProgressHeight,
    CustomSectionMenuPrimaryColor = primaryColor,
    topBannerColor = primaryColor,
  } = theme;
  return {
    topNavigationBarIconColor,
    pageNavLockedColor,
    radialProgressColor,
    sectionMenuColor,
    guidebookListItemBackground,
    progressBarBackground,
    stampBackground,
    interactiveSelectedColor,
    markdownColor,
    dragAndDropColor,
    pageNameBackground,
    dragAndDropBackground,
    reportingAccordionBg,
    questionIconsWidth,
    sectionIconHeight,
    radialProgressWidth,
    CustomSectionMenuPrimaryColor,
    topBannerColor,
  };
};
//#endregion

const getThemeFromConfig = config => {
  const configTheme = config.AppTheme || {};
  const combinedTheme = { ...defaultTheme, ...configTheme };
  const derivedTheme = deriveTheme(combinedTheme);
  return { ...combinedTheme, ...derivedTheme };
};

export const AppTheme = props => {
  const config = useConfig();
  const theme = getThemeFromConfig(config);
  return <ThemeProvider {...props} theme={theme} />;
};
