const immutableDelete = (set, key) => {
  if (!set.has(key)) {
    return set;
  }
  const newSet = new Set(set);
  newSet.delete(key);
  return newSet;
};

export default immutableDelete;
