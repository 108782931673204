export default {
  algolia_search_api_key: '/api/media/algolia_search_api_key',
  s3_post_url: '/api/media/s3/post_url',
  s3_post_urls: '/api/media/s3/post_urls',
  s3_delete: '/api/media/s3/delete',
  video_tus_url: '/api/media/cloudflare_video/tus_url',
  tus_upload: '/api/media/cloudflare_video/tus_upload',
  video_status: '/api/media/cloudflare_video/status',
  video_delete: '/api/media/cloudflare_video/delete',
  video_rename: '/api/media/cloudflare_video/rename',
  pixabaySearchImages: '/api/media/pixabay/search_images',
  clear_cache: '/api/media/clear_cache',
};
