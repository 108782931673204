export const canDoEverything = state => state.view.can_do_everything === 1;

export const canUseEditor = state => {
  if (canDoEverything(state)) {
    return true;
  } else {
    return state.view.can_use_editor === 1;
  }
};

export const canRunReports = state => {
  if (canDoEverything(state)) {
    return true;
  } else {
    return state.view.can_run_reports === 1;
  }
};

export const canUploadMedia = state => {
  if (canDoEverything(state)) {
    return true;
  } else {
    return state.view.can_upload_media === 1;
  }
};

export const canImpersonate = state => {
  if (canDoEverything(state)) {
    return true;
  } else {
    return state.view.can_impersonate === 1;
  }
};

export const canManageUsers = state => {
  if (canDoEverything(state)) {
    return true;
  } else {
    return state.view.can_manage_users === 1;
  }
};

export const canDeleteUsers = state => {
  if (canDoEverything(state)) {
    return true;
  } else {
    return state.view.can_delete_users === 1;
  }
};

export const canOverRidePasswordField = state => {
  if (canDoEverything(state)) {
    return true;
  } else {
    return state.view.can_override_password_field === 1;
  }
};

export const canEditOrganizationDetails = state => {
  if (canDoEverything(state)) {
    return true;
  } else {
    return state.view.can_edit_organization_details === 1;
  }
};
