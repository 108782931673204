import loadEventListeners from './load-event-listeners';
import setLang from './set-lang';

const postLoad = config => (dispatch, getState) => {
  const state = getState();
  setLang(state);
  loadEventListeners(config);
};

export default postLoad;
