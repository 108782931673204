import getModals from '../../selectors/modals/get-modals';
import utilityPanelIsOpen from '../../selectors/view/utility-panel-is-open';
import toggleUtilityPanel from '../toggle-utility-panel';
import toggleOffModal from '../toggle-off-modal';

const closeCurrentOverlay = () => (dispatch, getState) => {
  const state = getState();
  const modals = getModals(state);
  if (modals.length > 0) {
    dispatch(toggleOffModal());
  } else if (utilityPanelIsOpen(state)) {
    dispatch(toggleUtilityPanel());
  }
};

export default closeCurrentOverlay;
