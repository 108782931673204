import loadCourses from '../../api/courses/load';
import loadView from '../view/load';
import loadTrackingIdCompletions from '../../api/user/load-tracking-id-completions';
import postLoad from './post-load';
import loadTrackingIdsIfEditor from '../tracking-ids/load-tracking-ids-if-editor';
import loadTitleTranslations from '../languages/load-title-translations';
import isLoadingWrapper from './is-loading-wrapper';
import useLoginToken from '../../hooks/useLoginToken';
import closeCurrentOverlay from '../modal/close-current-overlay';

const loadRequests = config => async dispatch => {
  const [, setToken] = useLoginToken();
  const [view] = await Promise.all([
    dispatch(loadView()),
    dispatch(loadCourses()),
    dispatch(loadTrackingIdCompletions()),
  ]).catch((e) => {
    if(e.status === 401){
      //TODO Something Better on expired token for now login
      //Hopefully show dialog to return to login
      dispatch(closeCurrentOverlay());
      setToken();
    }
  });
  await dispatch(loadTrackingIdsIfEditor(view));
  await dispatch(loadTitleTranslations(view, config));
};

const load = config => dispatch =>
  dispatch(isLoadingWrapper(dispatch(loadRequests(config)))).then(() => {
    dispatch(postLoad(config));
  });

export default load;
