import loadCourses from '../../api/courses/load';
import isLoadingWrapper from '../../actions/load/is-loading-wrapper';
import getStateGuidebooks from '../guidebooks/get-guidebooks';

const getGuidebooks = (state, prevRootId, rootId) => dispatch =>
  new Promise(resolve =>
    prevRootId && rootId && prevRootId !== rootId
      ? dispatch(isLoadingWrapper(dispatch(loadCourses()))).then(data =>
          resolve(data)
        )
      : resolve(getStateGuidebooks(state))
  );

export default getGuidebooks;
