const defaultStrings = {
  section: 'Section',
  sections: 'Sections',
  sectionTitle: 'Section Title',
  addASection: 'Add a Section',
  unnamedSection: 'Unnamed Section',
  useFirstPageDescription: 'Use First Page As Description',
  page: 'Page',
  pages: 'Pages',
  createAPage: 'Create A Page',
  addExistingPage: 'Add Existing Page',
  unnamedPage: 'Unnamed Page',
  save: 'Save',
  download: 'Download',
  exportGuidebook: 'Export Guidebook',
  archiveGuidebook: 'Archive Guidebook',
  prev: 'Prev',
  next: 'Next',
  none: 'None',
  removeFile: 'Remove File',
  markComplete: 'Mark Complete',
  completed: 'Completed',
  tryAgain: 'Try Again',
  submit: 'Submit',
  pageTitle: 'Page Title',
  pageItems: 'Page Items',
  untitledIndicator: 'Untitled Indicator',
  changePassword: 'Change Password',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  passwordHint: 'Password Hint',
  passwordChangeSuccess: 'passwordChangeSuccess',
  passwordChangeError: 'passwordChangeError',
  typeHere: 'Type here',
  editMode: 'Edit Mode',
  logOut: 'Log Out',
  breadcrumbs: 'Breadcrumbs',
  editor: 'Editor',
  account: 'Account',
  manageSections: 'Manage Sections',
  multipleQuestionEditor: 'Multiple Choice Question Editor',
  multipleSelectEditor: 'Multiple Select Question Editor',
  dragDropQuestionEditor: 'Drag and Drop Question Editor',
  correct: 'Correct',
  answer: 'Answer',
  choice: 'Choice',
  dragItem: 'Drag Item',
  dropItem: 'Drop Item',
  feedback: 'Feedback',
  correctFeedback: 'Correct Feedback',
  incorrectFeedback: 'Incorrect Feedback',
  partialFeedback: 'Partial Feedback',
  randomize: 'Randomize?',
  txtQuestion: 'TxtQuestion',
  markdownEditor: 'Markdown Editor',
  markdownEditorHelp: 'Help?',
  youtubeSelector: 'Youtube Video Selector',
  pasteLinkHere: 'Paste link here',
  imagePicker: 'Image File Picker',
  videoPicker: 'Video File Picker',
  createNewPage: 'Create new page',
  insertExistingPage: 'Insert existing page',
  existingPages: 'Existing Pages',
  cancel: 'Cancel',
  publish: 'Publish',
  manageGroups: 'Manage Groups',
  assignGroups: 'Assign Groups',
  unnamedGroup: 'Unnamed Group',
  done: 'Done',
  deselectAll: 'Deselect All',
  trackingIds: 'Tracking Ids',
  name: 'Name',
  date: 'Date',
  id: 'ID',
  assign: 'Assign',
  lock: 'Lock',
  assignImage: 'Assign Image',
  images: 'Images',
  insertImage: 'Insert Image',
  uploadImages: 'Upload Images',
  colorPicker: 'Colour Picker',
  audioPicker: 'Audio Picker',
  mapEditor: 'Map Editor',
  setInitialZoom: 'Set Initial Zoom',
  setMinimumZoom: 'Set Minimum Zoom',
  setMaximumZoom: 'Set Maximum Zoom',
  setCenterCoords: 'Set Center Coordinates',
  markerMode: 'Marker Mode',
  viewEnlarged: 'View Enlarged',
  mapHeader: 'Map Header',
  markerEditor: 'Marker Editor',
  markerHeader: 'Marker Header',
  markerBody: 'Marker Body',
  deleteMarker: 'Delete Marker',
  background: 'Background',
  bodyText: 'Body Text',
  headerText: 'Header Text',
  property: 'Property',
  changeColour: 'Change Colour',
  resetColour: 'Reset Colour',
  itemColourPropertiesEditor: 'Item Colour Properties Editor',
  progressTitle: 'Progress Title',
  comment: 'Comment',
  setToNone: 'Set to None',
  upload: 'Upload',
  reporting: 'Reporting',
  dashboard: 'Dashboard',
  group: 'Group',
  user: 'User',
  allGroups: 'All Groups',
  userID: 'User ID',
  userName: 'User Name',
  sectionName: 'Section Name',
  averageTrackingIDCompleted: 'Average Tracking Ids Completed',
  totalTrackingID: 'Total Tracking Ids',
  trackingIdsCompleted: 'Tracking Ids Completed',
  averagePercentageCompleted: 'Average Percentage Completed',
  percentageCompleted: 'Percentage Completed',
  totalCompleted: 'Total Completed',
  filterBy: 'Filter By',
  filterByName: 'Filter By Name',
  totalSections: 'Total Sections',
  totalUsers: 'Total Users',
  backToFiLMS: 'Back to FiLMS',
  search: 'Search',
  vimeoSelector: 'Vimeo Video Selector',
  dividerEditor: 'Divider Editor',
  dividerBarBackgroundColor: 'Divider Bar Background Color',
  dividerBackgroundColor: 'Divider Background Color',
  dividerHeight: 'Divider Height',
  dividerArrowHeight: 'Divider Arrow Height',
  dividerArrowAlignment: 'Divider Arrow Alignment',
  dividerArrowAlignmentPercentage: 'Divider Arrow Alignment Percentage',
  left: 'Left',
  center: 'Center',
  right: 'Right',
  removing: 'Removing',
  management: 'Management',
  userManagement: 'User Management',
  filterByGroupName: 'Filter By Group Name',
  filterByUserName: 'Filter By User Name',
  groups: 'Groups',
  users: 'Users',
  previous: 'Previous',
  allUsers: 'All Users',
  username: 'Username',
  reporter: 'Reporter',
  reassignGroups: 'Reassign Groups',
  filter: 'Filter',
  status: 'Status',
  partiallyCompleted: 'Partially Completed',
  complete: 'Complete',
  incomplete: 'Incomplete',
  noTracking: 'No Tracking',
  downloadAsCSV: 'Download as CSV',
  close: 'Close',
  courseLauncherEditor: 'Course Launcher Editor',
  curriculumPicker: 'Curriculum Picker',
  launchCourse: 'Launch Course',
  chooseCurriculumForCourseLaunching: 'Choose Curriculum for Course Launching',
  chooseCurriculum: 'Choose Curriculum',
  closeCourseDialog1: 'closeCourseDialog1',
  closeCourseDialog2: 'closeCourseDialog2',
  CourseLauncher: 'Course Launcher',
  sketchfabModelEditor: 'Sketchfab Model Editor',
  sketchfabModelSelector: 'Sketchfab Model Selector',
  scrollWheelZooming: 'Scroll wheel zooming',
  annotationsVisible: 'Annotations visible',
  transparentBackground: 'Transparent background',
  hideModelInfoBar: 'Hide model info bar',
  hideControls: 'Hide controls',
  pro: 'Pro',
  business: 'Business',
  animatedEntrance: 'Animated entrance',
  turntableAnimation: 'Turntable animation',
  firstPersonMode: 'First person mode',
  preselectAnnotation: 'Preselect annotation',
  annotationPicker: 'Annotation Picker',
  unnamedAnnotation: 'Unnamed Annotation',
  annotationAutopilot: 'Annotation autopilot',
  reselectAnnotation: 'Reselect Annotation',
  selectAnnotation: 'Select Annotation',
  valueTxt: 'Value:',
  general: 'General',
  annotations: 'Annotations',
  destroyUnsavedChanges: 'Destroy unsaved changes',
  changesSuccessfullyPublished: 'Changes successfully published',
  exitApplication: 'Exit application?',
  interactiveIds: 'Interactive Ids',
  undo: 'Undo',
  choices: 'Choices',
  buckets: 'Buckets',
  examEditor: 'Exam Editor',
  required: 'Required',
  type: 'Type',
  minimumGradeToPass: 'Minimum Grade to Pass',
  numberOfQuestions: 'Number of Questions',
  questions: 'Questions',
  randomizeQuestions: 'Randomize Questions',
  showQuestionFeedback: 'Show Question Feedback',
  questionAnswered: 'Question Answered',
  grade: 'Grade',
  generateNewQuestionsOnFailure: 'Generate New Questions On Failure',
  vote: 'Vote',
  pollEditor: 'Poll Editor',
  insertChoice: 'Insert Choice',
  ratingEditor: 'Rating Editor',
  typeFilter: 'Type Filter',
  heading: 'Heading',
  averageRating: 'Average Rating',
  numberOfResponses: 'Number Of Responses',
  userResponses: 'User Responses',
  rating: 'Rating',
  respondedAt: 'Responded At',
  interactives: 'Interactives',
  averagePercentageCorrectOnFirstAttempt:
    'Average percentage correct on first attempt',
  averageNumberOfAttempts: 'Average number of attempts',
  percentagePassedOnFirstAttempt: 'Percentage passed on first attempt',
  averageNumberOfAttemptsNeededToPass:
    'Average number of attempts needed to pass',
  yes: 'Yes',
  no: 'No',
  view: 'View',
  questionResponse: 'Question Response',
  selection: 'Selection',
  bucket: 'Bucket',
  averagePercentageOnFirstAttempt: 'Average percentage on first attempt',
  passed: 'Passed',
  answers: 'Answers',
  noResponses: 'No Responses',
  totalInteractivesCompleted: 'Total Interactives Completed',
  totalTrackingIdsCompleted: 'Total Tracking Ids Completed',
  totalInteractives: 'Total Interactives',
  totalTrackingIds: 'Total Tracking Ids',
  overallGrade: 'Overall Grade',
  insertCopyOfExistingPage: 'Insert copy of existing page',
  vimeoErrorOccurred: 'Vimeo Error Occurred',
  unknownErrorOccurred: 'Unknown error occurred',
  preparingUpload: 'Preparing Upload',
  uploadingFiles: 'Uploading File(s)',
  syncingFiles: 'Syncing File(s)',
  uploadCompleted: 'Upload Completed',
  syncing: 'Syncing',
  error: 'Error',
  anErrorHasOccurred: 'An Error Has Occurred',
  languages: 'Languages',
  en: 'English',
  fr: 'French',
  ok: 'OK',
  selectedLocationDoesNotExist: 'Selected location does not exist',
  doNotHaveAccessToEntrySectionOrDoesNotExist:
    'Do not have access to entry section or does not exist',
  thisSectionIsLockedPleaseComplete:
    'This section is locked. Please complete content before section',
  selectedSectionDoesNotExist: 'Selected section does not exist',
  selectedPageDoesNotExist: 'Selected page does not exist',
  thisPageIsLockedPleaseComplete:
    'This page is locked. Please complete content before page',
  pleaseLoginToContinue: 'Please login to continue. Redirecting to login page.',
  unexpectedHTTPError: 'Unexpected HTTP Error, Redirect To Login',
  warning: 'Warning',
  pageUrlIdentifier: 'Page Url Identifier',
  sectionUrlIdentifier: 'Section Url Identifier',
  language: 'Language',
  doNotHaveAccessToTopicOrDoesNotExist:
    'Do not have access to topic or does not exist',
  player: 'Player',
  all: 'All',
  showFeedback: 'Show Feedback',
  feedbackCommentsOptional: 'Feedback comments (optional)',
  duplicateItem: 'Duplicate Item',
  sortBy: 'Sort by',
  imageList: 'Image List',
  addImage: 'Add Image',
  switchMode: 'Switch',
  flipMode: 'Flip',
  slideMode: 'Slide',
  dots: 'Dots',
  displayMode: 'Display mode',
  selectImage: 'Select Image',
  okay: 'Okay',
  selectFile: 'Select File',
  clickToInsert: 'Click to Insert',
  coursePackages: 'Course Packages',
  filterByPackageName: 'Filter by Package Name',
  createPackage: 'Create Package',
  noPackagesExist: 'No Packages Exist',
  back: 'Back',
  updatePackage: 'Update Package',
  downloadCourseZip: 'Download Course ZIP',
  filterBySectionName: 'Filter by Section Name',
  noSectionsAssigned: 'No Sections Assigned',
  anUnexpectedErrorHasOccured:
    'An unexpected error has occurred. Please restart to continue.',
  startInFirstPage: 'Start in first Page',
  about: 'About',
  ecoVersion: 'Eco Version',
  coursePackagesWhichEmbedsYourEcoSiteInsideASCORMPlayer:
    'Course Packages which embeds your Eco Site inside a SCORM Player',
  cannotRunCoursesInCourseMode: 'Cannot run courses in course mode.',
  eco2Go: 'Eco 2 Go',
  classification: 'Classification',
  reportOptions: 'Report Options',
  hideNavigationBar: 'Hide Navigation Bar',
  report: 'Report',
  reportOn: 'Report On',
  trackingIdCompletions: 'Tracking Id Completions',
  filterByPageName: 'Filter by Page Name',
  filterByTrackingIdName: 'Filter by Tracking Id Name',
  clearReport: 'Clear Report',
  trackingId: 'Tracking Id',
  trackingIdCompletion: 'Tracking Id Completion',
  userCompletions: 'User Completions',
  userCompletion: 'User Completion',
  login: 'Login',
  responseDetails: 'Response Details',
  optimized: 'Optimized',
  unlockingThisWillCausePermalinksToBeModified:
    'Unlocking this option will cause permalinks to be modified when changes occur in the' +
    ' identifier. If you already using links based off the identifier do not unlock this.',
  autoplay: 'Autoplay',
  loop: 'Loop',
  autopause: 'Autopause',
  muted: 'Muted',
  documentSignatureEditor: 'Document Signature Editor',
  documentURL: 'Document URL',
  openedAt: 'Opened At',
  confirmedAt: 'Confirmed At',
  url: 'URL',
  documentName: 'Document Name',
  userGuide: 'Support & User Guide',
  introduction: 'Introduction',
  newContent: 'New Content',
  code: 'Code',
  title: 'Title',
  pdfDocumentEditor: 'PDF Document Editor',
  secondary: 'Secondary',
  tertiary: 'Tertiary',
  welcome: 'welcome',
  navigateToHome: 'Navigate to Home',
  navigateBack: 'Navigate Back',
  openMenu: 'Open Menu',
  closeMenu: 'Close Menu',
  rate: 'Rate',
  stars: 'Stars',
  star: 'Star',
  stamp: 'Stamp',
  play: 'Play',
  pause: 'Pause',
  home: 'Home',
  description: 'Description',
  moveToPreviousImage: 'Move to Previous Image',
  moveToNextImage: 'Move to Next Image',
  interactiveIframeEditor: 'Interactive Iframe Editor',
  interactiveIframe: 'Interactive Iframe',
  pageHeight: 'Page Height',
  pageWidth: 'Page Width',
  response: 'Response',
  lottieEditor: 'Lottie Editor',
  hideResults: 'Hide Results',
  submitText: 'Submit Button Text',
  achievementEditor: 'achievementEditor',
  userDashboard: 'userDashboard',
  achievementBasics: 'achievementBasics',
  expiryReset: 'expiryReset',
  requirementsLabel: 'requirementsLabel',
  trackingRequirement: 'trackingRequirement',
  sectionRequirement: 'sectionRequirement',
  pageRequirement: 'pageRequirement',
  previewSave: 'previewSave',
  achievementName: 'achievementName',
  achievementDescription: 'achievementDescription',
  expiry: 'expiry',
  reset: 'reset',
  potentialCriteria: 'potentialCriteria',
  addedCriteria: 'addedCriteria',
  threshold: 'threshold',
  achievementIcon: 'achievementIcon',
  achievements: 'achievements',
  dashboardGreeting: 'dashboardGreeting',
  earnedOn: 'earnedOn',
  resetOn: 'resetOn',
  expiresOn: 'expiresOn',
  earnedAchievementsName: 'earnedAchievementsName',
  expiredAchievements: 'expiredAchievements',
  obtainableAchievements: 'obtainableAchievements',
  cancelAndClose: 'cancelAndClose',
  achievementReport: 'achievementReport',
  achievementId: 'achievementId',
  earnedAt: 'earnedAt',
  resetsOn: 'resetsOn',
  isReset: 'isReset',
  noData: 'noData',
  achievementReportWelcome: 'achievementReportWelcome',
  createNewAchievement: 'createNewAchievement',
  achievementDisabled: 'achievementDisabled',
  disabledWarning: 'disabledWarning',
  editImage: 'editImage',
  expiryString: 'expiryString',
  noIcon: 'noIcon',
  noIconSelected: 'noIconSelected',
  selectedIcon: 'selectedIcon',
  previewPopup: 'previewPopup',
  danger: 'Danger',
  looksGood: 'looksGood',
  achievementsNameWarning: 'achievementsNameWarning',
  achievementsDescriptionWarning: 'achievementsDescriptionWarning',
  requirementWarning: 'requirementWarning',
  expiryResetWarning: 'expiryResetWarning',
  requirementsModificationWarning: 'requirementsModificationWarning',
  earned: 'Earned',
  deleteString: 'Delete',
  yourProgress: 'yourProgress',
  notifications: 'Notifications',
  markAllAsRead: 'markAllAsRead',
  newText: 'newText',
  read: 'Read',
  noNotifications: 'noNotifications',
  editIcon: 'editIcon',
  selectIcon: 'selectIcon',
  displayType: 'displayType',
  editColor: 'editColor',
  editDisplayType: 'editDisplayType',
  certificationOfCompletion: 'certificationOfCompletion',
  hasCompleted: 'hasCompleted',
  dateCertified: 'dateCertified',
  expiryDate: 'expiryDate',
  displayProperties: 'displayProperties',
  certificateIncomplete: 'certificateIncomplete',
  nameLabel: 'nameLabel',
  thumbnailPickerText: 'thumbnailPickerText',
  thumbnailPickerPreview: 'thumbnailPickerPreview',
  editorResetWarning: 'editorResetWarning',
  assessmentText: 'assessmentText',
  awaitingContent: 'awaitingContent',
  awaitingUser: 'awaitingUser',
  awaitingManager: 'awaitingManager',
  completedText: 'completedText',
  assessmentEditor: 'assessmentEditor',
  AssessmentExam: 'AssessmentExam',
  managerDashboard: 'managerDashboard',
  Dashboards: 'Dashboards',
  selectAGroup: 'selectAGroup',
  selectAType: 'selectAType',
  userSectionCompletions: 'userSectionCompletions',
  usersComplete: 'usersComplete',
  usersAssigned: 'usersAssigned',
  sectionUserTitle: 'sectionUserTitle',
  achievementUserTitle: 'achievementUserTitle',
  enterSearchTerm: 'enterSearchTerm',
  userAchievementTitle: 'userAchievementTitle',
  userSectionTitle: 'userSectionTitle',
  completeUsers: 'completeUsers',
  eligibleUsers: 'eligibleUsers',
  completedAchievements: 'completedAchievements',
  eligibleAchievements: 'eligibleAchievements',
  sectionsCompleted: 'sectionsCompleted',
  sectionsAssigned: 'sectionsAssigned',
  completedAt: 'completedAt',
  confirm: 'Confirm',
  PDFLink: 'PDFLink',
  buttonText: 'buttonText',
  resources: 'Resources',
  PDFScheduleLinks: 'PDFScheduleLinks',
  firstButtonText: 'firstButtonText',
  secondButtonText: 'secondButtonText',
  firstLink: 'firstLink',
  secondLink: 'secondLink',
  SectionEnd: 'SectionEnd',
  sectionResults: 'sectionResults',
  pageResults: 'pageResults',
  copySectionWithNewPageIds: 'copySectionWithNewPageIds',
  copySectionWithDuplicatePageIds: 'copySectionWithDuplicatePageIds',
  createNewSection: 'Create New Section',
  existingSections: 'Existing Sections',
  copySelectedSection: 'Copy Selected Section',
  KnowledgeCheck: 'KnowledgeCheck',
  enableAssessment: 'enableAssessment',
  enableExam: 'enableExam',
  exam: 'Exam',
  assessment: 'Assessment',
  Assessment: 'Assessment',
  responseDetailsText: 'responseDetailsText',
  communication: 'Communication',
  messages: 'Messages',
  notificationSettings: 'notificationSettings',
  myMessages: 'myMessages',
  showHiddenMessagesLabel: 'showHiddenMessagesLabel',
  sendMessages: 'sendMessages',
  sentMessages: 'sentMessages',
  noMessages: 'noMessages',
  hideMessage: 'hideMessage',
  receivedOn: 'receivedOn',
  viewedOn: 'viewedOn',
  from: 'from',
  addRemoveRecipients: 'addRemoveRecipients',
  editMessageContent: 'editMessageContent',
  to: 'to',
  sentOn: 'sentOn',
  invalidFileTypes: 'invalidFileTypes',
  fileSizeTooBig: ' fileSizeTooBig',
  filesUploadedSuccessfully: 'filesUploadedSuccessfully',
  oneOrMoreFilesFailedToUpload: 'oneOrMoreFilesFailedToUpload',
  successfullyDeleted: 'successfullyDeleted',
  failedToDelete: 'failedToDelete',
  emails: 'Emails',
  valid: 'valid',
  use: 'use',
  edit: 'Edit',
  phoneNumbers: 'phoneNumbers',
  knowledgeCheckRequests: 'knowledgeCheckRequests',
  noAttempts: 'noAttempts',
  attempts: 'Attempts',
  lastUpdated: 'lastUpdated',
  actions: 'Actions',
  pass: 'Pass',
  fail: 'Fail',
  sortAlphabetically: 'sortAlphabetically',
  noDataToDisplay: 'noDataToDisplay',
  overallProgressOfUsers: 'overallProgressOfUsers',
  getCSV: 'getCSV',
  subjectLine: 'subjectLine',
  messageBody: 'messageBody',
  sortByGroup: 'sortByGroup',
  people: 'people',
  selectAllInList: 'selectAllInList',
  deselectAllInList: 'deselectAllInList',
  send: 'send',
  NA: 'NA',
  knowledgeChecks: 'knowledgeChecks',
  knowledgeChecksTitle: 'knowledgeChecksTitle',
  manager: 'manager',
  PageNavigationBar: 'PageNavigationBar',
  previousLesson: 'previousLesson',
  nextLesson: 'nextLesson',
  Lessons: 'Lessons',
  overallProgression: 'overallProgression',
  myCertificates: 'myCertificates',
  assignSelected: 'assignSelected',
  createNew: 'createNew',
  enterName: 'enterName',
  TrackedVideo: 'TrackedVideo',
  requiredCompletionPercentage: 'requiredCompletionPercentage',
  lastVisitedText: 'lastVisitedText',
  ImageStatusIndicator: 'ImageStatusIndicator',
  incompleteImage: 'incompleteImage',
  completeImage: 'completeImage',
  userCompletionPercentTitle: 'userCompletionPercentTitle',
  completionPercentage: 'completionPercentage',
  exams: 'Exams',
  pendingAssessments: 'pendingAssessments',
  processedAssessments: 'processedAssessments',
  failed: 'Failed',
  backToLearning: 'backToLearning',
  sectionEmptyError: 'sectionEmptyError',
  turnOnEditModeGoToSection: 'turnOnEditModeGoToSection',
  hideFeedbackCheckMark: 'hideFeedbackCheckMark',
  inProgress: 'inProgress',
  expired: 'Expired',
  noneInProgress: 'noneInProgress',
  noneEarned: 'noneEarned',
  noneExpired: 'noneExpired',
  text: 'Text',
  link: 'Link',
  pageNavigationBarEditor: 'pageNavigationBarEditor',
  previousPage: 'previousPage',
  nextPage: 'nextPage',
  nextPageLocked: 'nextPageLocked',
  profileEditor: 'myAccount',
  select: 'select',
  trueText: 'trueText',
  falseText: 'falseText',
  locked: 'locked',
  invalid: 'invalid',
  invalidInputs: 'invalidInputs',
  passwordMatchFailed: 'passwordMatchFailed',
  passwordErrorMessage: 'passwordErrorMessage',
  changesSavedSuccessfully: 'changesSavedSuccessfully',
  submitAndClose: 'submitAndClose',
  displayName: 'displayName',
  currentPassword: 'currentPassword',
  confirmNewPassword: 'confirmNewPassword',
  basics: 'basics',
  details: 'details',
  usernameDescription: 'usernameDescription',
  displayNameDescription: 'displayNameDescription',
  detailsDescription: 'detailsDescription',
  notStartedUsers: 'notStartedUsers',
  partiallyCompleteUsers: 'partiallyCompleteUsers',
  emptyInteractiveOpen: 'emptyInteractiveOpen',
  emptyInteractiveClose: 'emptyInteractiveClose',
  interactiveEditor: 'interactiveEditor',
  selectInteractive: 'selectInteractive',
  MultipleSelectQuestion: 'MultipleSelectQuestion',
  Question: 'Question',
  interactiveContent: 'interactiveContent',
  interactive: 'interactive',
  change: 'change',
  itemError: 'itemError',
  replacedByKc: 'replacedByKc',
  pageLayoutBuilder: 'pageLayoutBuilder',
  cancelAndCloseShort: 'cancelAndCloseShort',
  helpEditing: 'helpEditing',
  needHelpEditing: 'needHelpEditing',
  invalidInteractivesWarning:
    'Error: One or more interactive items are not properly setup.',
  unsavedInteractivesWarning:
    'Warning: There are unsaved interactive changes. These changes will be lost upon publish unless saved',
  openPageEditor: 'Open Page Editor',
  moveItem: 'Move Item',
  deleteItem: 'Delete Item',
  findItem: 'Find Item',
  selectTrackingId: 'Select Tracking Id',
  selectLanguage: 'Select Language',
  selectInteractiveId: 'Select Interactive Id',
  resetItem: 'Reset Item',
  addItem: 'Add Item',
  statusIndicator: 'Status Indicator',
  addStatusIndicator: 'Add Status Indicator',
  removeStatusIndicator: 'Remove Status Indicator',
  interactiveHelpText:
    'Interactive items work slightly different than regular items. To setup an interactive item you must assign the item an interactive Id. Interactive Ids can be re-used in multiple places including knowledge checks and assist in user tracking.',
  Markdown: 'Markdown',
  Audio: 'Audio',
  audioWithMarkdown: 'audioWithMarkdown',
  Comment: 'Comment',
  Divider: 'Divider',
  DragDropMatchingQuestion: 'DragDropMatchingQuestion',
  Iframe: 'Iframe',
  ImageList: 'Image',
  imageWithImage: 'imageWithImage',
  imageWithImageWithImage: 'imageWithImageWithImage',
  imageWithMarkdownWithImage: 'imageWithMarkdownWithImage',
  imageWithMultipleSelectQuestion: 'imageWithMultipleSelectQuestion',
  imageWithQuestion: 'imageWithQuestion',
  markdownWithAudio: 'markdownWithAudio',
  markdownWithImage: 'markdownWithImage',
  markdownWithStamp: 'markdownWithStamp',
  markdownWithVideo: 'markdownWithVideo',
  markdownWithVimeo: 'markdownWithVimeo',
  markdownWithYouTube: 'markdownWithYouTube',
  markdownWithMarkdown: 'markdownWithMarkdown',
  markdownWithImageWithImage: 'markdownWithImageWithImage',
  markdownWithLottie: 'markdownWithLottie',
  Poll: 'Poll',
  progressTracker: 'progressTracker',
  Rating: 'Rating',
  Stamp: 'Stamp',
  stampWithMarkdown: 'stampWithMarkdown',
  Video: 'Video',
  Vimeo: 'Vimeo',
  videoWithMarkdown: 'videoWithMarkdown',
  Viewed: 'Viewed',
  vimeoWithMarkdown: 'vimeoWithMarkdown',
  YouTube: 'YouTube',
  youTubeWithMarkdown: 'youTubeWithMarkdown',
  youtubeWithImage: 'youtubeWithImage',
  PDFDocument: 'PDFDocument',
  MarkdownMap: 'MarkdownMap',
  MapMarkdown: 'MapMarkdown',
  lottieWithMarkdown: 'lottieWithMarkdown',
  InteractiveIframe: 'InteractiveIframe',
  imageWithMarkdown: 'imageWithMarkdown',
  imageWithImageWithMarkdown: 'imageWithImageWithMarkdown',
  imageWithYoutube: 'imageWithYoutube',
  Filters: 'Filters',
  confirmChanges: 'confirmChanges',
  developFromTemplate: 'developFromTemplate',
  addIndividualItems: 'addIndividualItems',
  pageLayoutPreview: 'pageLayoutPreview',
  copyPageAsTemplate: 'copyPageAsTemplate',
  copyPageAsTemplateDescription: 'copyPageAsTemplateDescription',
  copyPageWithItems: 'copyPageWithItems',
  copyPageWithItemsDescription: 'copyPageWithItemsDescription',
  responseSubmitted: 'responseSubmitted',
  submitAnswer: 'submitAnswer',
  enterAnswerHere: 'enterAnswerHere',
  writtenResponseEditor: 'writtenResponseEditor',
  question: 'question',
  minCharacterLength: 'minCharacterLength',
  maxCharacterLength: 'maxCharacterLength',
  responseTooShort: 'responseTooShort',
  responseTooLong: 'responseTooLong',
  searchTerm: 'searchTerm',
  contains: 'Contains',
  matches: 'Matches',
  doesNotMatch: 'doesNotMatch',
  beginsWith: 'beginsWith',
  endsWith: 'endsWith',
  equalTo: 'equalTo',
  notEqualTo: 'notEqualTo',
  between: 'Between',
  greaterThan: 'greaterThan',
  lessThan: 'lessThan',
  on: 'On',
  before: 'Before',
  after: 'After',
  apply: 'Apply',
  clear: 'Clear',
  selectDates: 'selectDates',
  selectDate: 'selectDate',
  allDates: 'allDates',
  editReportColumns: 'editReportColumns',
  editSortingOptions: 'editSortingOptions',
  editFilters: 'editFilters',
  saveAndClose: 'saveAndClose',
  cancelChangesAndClose: 'cancelChangesAndClose',
  field: 'Field',
  priority: 'Priority',
  order: 'Order',
  noSort: 'noSort',
  ascending: 'Ascending',
  descending: 'Descending',
  loadTemplate: 'loadTemplate',
  saveTemplate: 'saveTemplate',
  selectATemplate: 'selectATemplate',
  reportTitle: 'reportTitle',
  templateName: 'templateName',
  templates: 'Templates',
  loadingTemplates: 'loadingTemplates',
  editReport: 'editReport',
  inReport: 'inReport',
  notInReport: 'notInReport',
  VrLauncher: 'VrLauncher',
  vrLauncherEditor: 'vrLauncherEditor',
  selectAnApp: 'selectAnApp',
  launchApp: 'launchApp',
  notStarted: 'notStarted',
  notRunningInAppMode: 'notRunningInAppMode',
  noAppSelected: 'noAppSelected',
  appLaunchedDialog1: 'appLaunchedDialog1',
  appLaunchedDialog2: 'appLaunchedDialog2',
  resetEarnedDialogHeader: 'resetEarnedDialogHeader',
  resetEarnedDialogBody: 'resetEarnedDialogBody',
  setAsDefaultTemplate: 'setAsDefaultTemplate',
  personalInteractiveReports: 'personalInteractiveReports',
  noInteractiveReportsAtThisTime: 'noInteractiveReportsAtThisTime',
  clickToDownloadAndGenerate: 'clickToDownloadAndGenerate',
  myReports: 'myReports',
  writtenResponse: 'writtenResponse',
  WrittenResponse: 'writtenResponse',
  sectionPagePicker: 'sectionPagePicker',
  useSelected: 'useSelected',
  currentlySelected: 'currentlySelected',
  local: 'local',
  loaded: 'loaded',
  sideMargins: 'sideMargins',
  passKnowledgeCheck: 'passKnowledgeCheck',
  failKnowledgeCheck: 'failKnowledgeCheck',
  createACourse: 'createACourse',
  create: 'Create',
  courseName: 'courseName',
  courseCategory: 'courseCategory',
  chooseACourseImage: 'chooseACourseImage',
  courseSuccessfullyPublished: 'courseSuccessfullyPublished',
  selectVideoSource: 'selectVideoSource',
  uploads: 'Uploads',
  email: 'email',
  emailHelp: 'emailHelp',
  password: 'password',
  partOfAnotherPersonsTeam: 'partOfAnotherPersonsTeam',
  wantToCreateYourOwnCourse: 'wantToCreateYourOwnCourse',
  registerNow: 'registerNow',
  toCreateYourOwnTeamAndCourse: 'toCreateYourOwnTeamAndCourse',
  createYourFreeAccount: 'createYourFreeAccount',
  afterYouClickSubmit: 'afterYouClickSubmit',
  createYourOwnTeamAndCourse: 'createYourOwnTeamAndCourse',
  breazeIsAProductDevelopedBy: 'breazeIsAProductDevelopedBy',
  icomProductions: 'icomProductions',
  fullName: 'fullName',
  userSetupPanelMessage: 'userSetupPanelMessage',
  userAccountSetup: 'userAccountSetup',
  confirmPassword: 'confirmPassword',
  organizationSetupPanelMessage: 'organizationSetupPanelMessage',
  companyName: 'companyName',
  industry: 'industry',
  siteUsername: 'siteUsername',
  organizationSetup: 'organizationSetup',
  passwordAndConfirmPasswordFieldsMustMatch:
    'passwordAndConfirmPasswordFieldsMustMatch',
  passwordMinLength: 'passwordMinLength',
  alreadyRegistered: 'alreadyRegistered',
  optInForNewsletter: 'optInForNewsletter',
  agreeTo: 'agreeTo',
  termsOfService: 'termsOfService',
  requirements: 'requirements',
  multipleChoiceQuestion: 'multipleChoiceQuestion',
  MultipleChoiceQuestion: 'Multiple Choice Question',
  userAccounts: 'userAccounts',
  openSpots: 'openSpots',
  userManagementSection: 'userManagementSection',
  pendingInvites: 'pendingInvites',
  inviteUser: 'inviteUser',
  yourTeam: 'yourTeam',
  resetPassword: 'resetPassword',
  remove: 'Remove',
  removeUser: 'removeUser',
  removeUserDialogBody: 'removeUserDialogBody',
  admin: 'Admin',
  openSpot: 'openSpot',
  userLimitReachedString: 'userLimitReachedString',
  invitationSentString: 'invitationSentString',
  requirement: 'requirement',
  potentialRequirements: 'potentialRequirements',
  addedRequirements: 'addedRequirements',
  emailConfirmationSent: 'emailConfirmationSent',
  breazeVersion: 'breazeVersion',
  forgotPassword: 'forgotPassword',
  delete: 'Delete',
  deleteCourse: 'deleteCourse',
  deleteCourseDialog: 'deleteCourseDialog',
  deleteFile: 'deleteFile',
  deleteFileDialog: 'deleteFileDialog',
  emptyLearnerHomepageBlurb: 'emptyLearnerHomepageBlurb',
  uploadErrorMessage: 'uploadErrorMessage',
  achievementEditorHelp: 'achievementEditorHelp',
  startWithTemplate: 'startWithTemplate',
  configuration: 'configuration',
  changeIcon: 'changeIcon',
  disableThisAchievement: 'disableThisAchievement',
  deleteAchievement: 'deleteAchievement',
  requirementType: 'requirementType',
  course: 'course',
  achievementNameDescriptionWarning: 'achievementNameDescriptionWarning',
  achievementConfigurationWarning: 'achievementConfigurationWarning',
  achievementDisabledWarning: 'achievementDisabledWarning',
  totalAchievements: 'totalAchievements',
  totalActiveAchievements: 'totalActiveAchievements',
  achievementDeleteWarning: 'achievementDeleteWarning',
  achievementsDashboard: 'achievementsDashboard',
  overallStatus: 'overallStatus',
  achievementsInProgress: 'achievementsInProgress',
  achievementsEarned: 'achievementsEarned',
  achievementsExpired: 'achievementsExpired',
  badges: 'badges',
  certificates: 'certificates',
  notEligibleToEarn: 'notEligibleToEarn',
  noEarnedAchievements: 'noEarnedAchievements',
  noExpiredAchievements: 'noExpiredAchievements',
  print: 'print',
  achievementDetails: 'achievementDetails',
  allOfTheFollowing: 'allOfTheFollowing',
  xOfTheFollowing: 'xOfTheFollowing',
  achievement: 'achievement',
  earnableOn: 'earnableOn',
  editBackground: 'editBackground',
  fullSpan: 'fullSpan',
  advancedOptions: 'advancedOptions',
  sideMarginDescription: 'sideMarginDescription',
  textColor: 'Text color',
  headerColor: 'Header Color',
  EditorComment: 'EditorComment',
  showDots: 'showDots',
  makeClickable: 'makeClickable',
  makeClickableDescription: 'makeClickableDescription',
  trackingIdDescription: 'trackingIdDescription',
  interactiveId: 'interactiveId',
  interactiveIdDescription: 'interactiveIdDescription',
  addChoice: 'addChoice',
  correctAnswer: 'correctAnswer',
  randomizeAnswers: 'randomizeAnswers',
  retry: 'retry',
  letter: 'letter',
  addBucket: 'addBucket',
  DoubleItem: 'DoubleItem',
  swapSides: 'swapSides',
  changeItemType: 'changeItemType',
  TripleItem: 'TripleItem',
  sectionDescription: 'sectionDescription',
  addSection: 'addSection',
  addLesson: 'addLesson',
  editing: 'Editing',
  live: 'Live',
  courseLive: 'courseLive',
  courseLiveBlurb: 'courseLiveBlurb',
  publishChanges: 'publishChanges',
  editCourseSettings: 'editCourseSettings',
  category: 'Category',
  courseTitle: 'courseTitle',
  createANewCourse: 'createANewCourse',
  getStartedInMinutes: 'getStartedInMinutes',
  getStartedHomepageBlurb: 'getStartedHomepageBlurb',
  sectionDeleteBlurb: 'sectionDeleteBlurb',
  sectionDeleteAccept: 'sectionDeleteAccept',
  start: 'Start',
  lessons: 'Lessons',
  reportingDashboard: 'reportingDashboard',
  activeUsers: 'activeUsers',
  userSpots: 'userSpots',
  overallCompletion: 'overallCompletion',
  userId: 'userId',
  userProgressReport: 'userProgressReport',
  trackingIdsEligible: 'trackingIdsEligible',
  trackingIdsComplete: 'trackingIdsComplete',
  trackingProgress: 'trackingProgress',
  trackingIdName: 'trackingIdName',
  progressReport: 'progressReport',
  usersEligible: 'usersEligible',
  usersEarned: 'usersEarned',
  percentageComplete: 'percentageComplete',
  achievementCompletions: 'achievementCompletions',
  overallAchievementCompletion: 'overallAchievementCompletion',
  latestActivity: 'latestActivity',
  overallProgress: 'overallProgress',
  averageUserProgress: 'averageUserProgress',
  usersName: 'usersName',
  expiresAt: 'expiresAt',
  resetsAt: 'resetsAt',
  interactiveReporting: 'interactiveReporting',
  progressReporting: 'progressReporting',
  interactiveReportingDescription: 'interactiveReportingDescription',
  progressReportingDescription: 'progressReportingDescription',
  singleSelectQuestionsReport: 'singleSelectQuestionsReport',
  questionsReport: 'questionsReport',
  pageName: 'pageName',
  itemType: 'itemType',
  questionReport: 'questionReport',
  pollReport: 'pollReport',
  mostPopularResponse: 'mostPopularResponse',
  ratingReport: 'ratingReport',
  writtenResponseReport: 'writtenResponseReport',
  totalResponses: 'totalResponses',
  allowsFeedback: 'allowsFeedback',
  percentageTotal: 'percentageTotal',
  usersResponded: 'usersResponded',
  ratingPercent: 'ratingPercent',
  changeOrganizations: 'changeOrganizations',
  pickATemplate: 'pickATemplate',
  blankPage: 'blankPage',
  returnToCourseEditing: 'returnToCourseEditing',
  createCourse: 'createCourse',
  basic: 'Basic',
  generateId: 'generateId',
  layoutEditorHelp: 'layoutEditorHelp',
  itemTags: 'itemTags',
  layoutPreview: 'layoutPreview',
  pageEditingHelp: 'pageEditingHelp',
  changesSuccessfullySaved: 'changesSuccessfullySaved',
  saveChanges: 'saveChanges',
  courseSettingsHelp: 'courseSettingsHelp',
  courseSettingsEditor: 'courseSettingsEditor',
  closeModal: 'closeModal',
  lockSection: 'lockSection',
  lockSectionDescription: 'lockSectionDescription',
  assignCourseImage: 'assignCourseImage',
  putCourseLive: 'putCourseLive',
  takeCourseOffline: 'takeCourseOffline',
  addSectionCopy: 'addSectionCopy',
  addNewSection: 'addNewSection',
  copySectionHelp: 'copySectionHelp',
  copySection: 'copySection',
  copyPage: 'copyPage',
  copyPageHelp: 'copyPageHelp',
  addNewPage: 'addNewPage',
  addPageCopy: 'addPageCopy',
  shallowCopyPage: 'shallowCopyPage',
  deepCopyPage: 'deepCopyPage',
  shallowCopySection: 'shallowCopySection',
  deepCopySection: 'deepCopySection',
  shallowCopyPageDescription: 'shallowCopyPageDescription',
  deepCopyPageDescription: 'deepCopyPageDescription',
  shallowCopySectionDescription: 'shallowCopySectionDescription',
  deepCopySectionDescription: 'deepCopySectionDescription',
  typeDeleteToContinue: 'typeDeleteToContinue',
  inviteInstruction: 'inviteInstruction',
  idPickerHelp: 'idPickerHelp',
  interactiveIdPicker: 'interactiveIdPicker',
  trackingIdPicker: 'trackingIdPicker',
  createNewId: 'createNewId',
  rename: 'rename',
  refineSearch: 'refineSearch',
  horizontal: 'horizontal',
  vertical: 'vertical',
  orientation: 'orientation',
  enterSearchToBegin: 'enterSearchToBegin',
  noResultsFound: 'noResultsFound',
  clickToSelect: 'clickToSelect',
  selected: 'selected',
  addFromStock: 'addFromStock',
  howToUserGuide: 'howToUserGuide',
  overview: 'Overview',
  content: 'Content',
  advanced: 'Advanced',
  difficulty: 'Difficulty',
  medium: 'Medium',
  changeTeam: 'changeTeam',
  copyHelp: 'copyHelp',
  courseHelp: 'courseHelp',
  alignment: 'alignment',
  top: 'top',
  middle: 'middle',
  bottom: 'bottom',
  addedItem: 'addedItem',
  originalImageWidth: 'originalImageWidth',
  originalImageHeight: 'originalImageHeight',
  cropWidth: 'cropWidth',
  cropHeight: 'cropHeight',
  presetDimensions: 'presetDimensions',
  aspectRatio: 'aspectRatio',
  free: 'Free',
  saveAs: 'saveAs',
  fileName: 'fileName',
  itemSelect: 'itemSelect',
  overwriteFileDialog: 'overwriteFileDialog',
  saveCopy: 'saveCopy',
  preview: 'Preview',
  startHere: 'startHere',
  courseDetails: 'courseDetails',
};

export default defaultStrings;
