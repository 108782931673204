import imports from '../../lib/imports';

const getImport = (library, libraryMaps) => {
  const map = libraryMaps.find(
    libraryMap => libraryMap[library] && imports[libraryMap[library]]
  );
  const importWhat = map[library];
  return imports[importWhat];
};

export default getImport;
