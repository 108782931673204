/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import React from 'react';
import BreazeTextInput from '../assets/Inputs/BreazeTextInput';
import BreazeDropDown from '../assets/Inputs/BreazeDropDown';
import industry_list from '../../../lib/authentication/industry_list';

const OrganizationSetupPanel = ({
  company,
  setCompany,
  industry,
  setIndustry,
  siteUsername,
  setSiteUsername,
}) => {
  return (
    <div className={'box'}>
      <h1>{'Organization Setup'}</h1>
      <div className={'message'}>
        {
          'This information will be used to create an organization where your team can gather together for an innovative e-learning experience.'
        }
      </div>
      <BreazeTextInput
        label={'Company Name'}
        pattern={'^.{2,}$'}
        value={company.value}
        onChange={setCompany}
      />
      <BreazeDropDown
        label={'Industry'}
        value={industry.value}
        onChange={setIndustry}
        options={industry_list}
      />
      <BreazeTextInput
        label={'Team Name'}
        pattern={'^.{2,}$'}
        value={siteUsername.value}
        onChange={setSiteUsername}
        helpText={"Enter simple phrase to identify your team"}
      />
    </div>
  );
};

OrganizationSetupPanel.propTypes = {
  company: PropTypes.object.isRequired,
  setCompany: PropTypes.func.isRequired,
  industry: PropTypes.object.isRequired,
  setIndustry: PropTypes.func.isRequired,
  siteUsername: PropTypes.object.isRequired,
  setSiteUsername: PropTypes.func.isRequired,
};

export default OrganizationSetupPanel;
