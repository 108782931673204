import reduxEvents from '../redux-events';

const defaultState = { earnedAchievements: [] };

const setEarnedAchievements = (state, list) =>
  Object.assign({}, state, { earnedAchievements: list });

const earnedAchievement = (state, achievement) =>
  Object.assign({}, state, {
    earnedAchievements: [...state.earnedAchievements, achievement],
  });

const achievementsEvents = reduxEvents()
  .addEvent('ACHIEVEMENTS', 'SET_EARNED_ACHIEVEMENTS', setEarnedAchievements)
  .addEvent('ACHIEVEMENTS', 'EARN_ACHIEVEMENT', earnedAchievement);

const achievements = achievementsEvents.getReducer(defaultState);
const achievementsActionCreator = achievementsEvents.getActionCreators();

export { achievements, achievementsActionCreator };
