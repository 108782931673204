import React from 'react';
import ErrorUnauthorizedHTTPRequest from '../../../components/dialog/errors/ErrorUnauthorizedHTTPRequest';
import { useDispatch } from '../../../providers/Store';

const ErrorUnauthorizedHTTPRequestWrapper = props => {
  const dispatch = useDispatch();
  return <ErrorUnauthorizedHTTPRequest {...props} dispatch={dispatch} />;
};

export default ErrorUnauthorizedHTTPRequestWrapper;
