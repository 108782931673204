/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useStore } from '../providers/Store';
import getAppClassName from '../selectors/view/get-app-classname';
import LoadedComponent from './loaded-component/LoadedComponent';
import Context from './context/Context';
import UtilityPanel from './utility-panel/UtilityPanel';
import Modals from './modals/Modals';
import 'react-toastify/dist/ReactToastify.css';
import ToasterContainer from '../containers/toaster/ToasterContainer';
import { toast } from 'react-toastify';

toast.configure();

const style = t => [
  css({
    fontFamily: t.fontFamily,
  }),
  css`
    background-color: ${t.background};
    outline: 100vh ${t.background} solid;
    [class^='markdownWith'],
    [class$='WithMarkdown'] {
      background: ${t.markdownBackground};
    }
    .imageWithQuestion,
    .imageWithMultipleSelectQuestion {
      background: ${t.interactiveBackground};
    }
    .pop-in-enter {
      transform: scale(0.3);
      opacity: 0.01;
      transition: opacity 0.1s linear, transform 0.1s linear;
    }
    .pop-in-enter.pop-in-enter-active {
      opacity: 1;
      transform: scale(1);
    }
    .pop-in-exit {
      transform: scale(1);
      opacity: 1;
      transition: opacity 0.1s linear, transform 0.1s linear;
    }
    .pop-in-exit.pop-in-exit-active {
      transform: scale(0.3);
      opacity: 0.01;
    }
    &.hide-interface {
      .top-navigation-bar {
        .left-icon-clr.home-icon-button {
          visibility: hidden !important;
        }
        .logo {
          cursor: default !important;
        }
      }

      .utility-panel .panel {
        .panel-section ul li:first-of-type {
          display: none !important;
        }
        .panel-section {
          display: none;
        }
        .panel-section:last-of-type {
          display: block;
        }
        .panel-section:last-of-type {
          .panel-item {
            display: none;
          }
          .panel-item:last-of-type {
            display: block;
            span {
              display: none;
            }
            .panel-item-header:before {
              content: 'Exit';
              color: black;
              font-size: 1.2em;
            }
          }
        }
      }
    }

    p,
    ul {
      font-size: 1.2em;
    }
    h1 {
      font-size: 2.5em;
    }
    h2 {
      font-size: 2em;
    }
    h3 {
      font-size: 1.5em;
    }
    h4 {
      font-size: 1.17em;
    }
    h5 {
      font-size: 1em;
    }
    h6 {
      font-size: 0.87em;
    }
    & * {
      &::-webkit-scrollbar {
        width: 0.3125rem;
        height: 0.3125rem;
      }
      &::-webkit-scrollbar-track {
        background-color: #e0e0e0;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #bdbdbd;
      }
    }
    [tabindex]:not([tabindex='-1']),
    a,
    button,
    input,
    select,
    textarea {
      outline: 0.125rem solid transparent;
      outline-offset: 3px;
      transition: outline-offset 0.15s linear;
      &:focus {
        outline: 0.125rem solid #004abb;
        outline-offset: -0.125rem;
      }
      &:active,
      * {
        outline: 0;
      }
    }
    [tabindex='-1'] {
      outline: 0;
      * {
        outline: 0;
      }
    }

    input[type='text'],
    input[type='password'] {
      width: 100%;
      color: #333;
      background: none;
      border: 0;
      border-bottom: 0.0625rem solid #cfd8dc;
      padding: 0.3125rem 0 0.5625rem 0;
      &::placeholder {
        color: #333;
        opacity: 0.7;
      }
      &:focus {
        outline: 0;
        border-bottom: 0.125rem solid #cfd8dc;
        padding-bottom: 0.5rem;
      }
    }

    .demo-menu {
      margin: 0.625rem 1.875rem 2.5rem 0;
    }
    [class*='-icon'] {
      width: 1.5rem;
      height: 1.5rem;
      display: inline-block;
      vertical-align: middle;
    }
    .grecaptcha-badge {
      visibility: hidden;
    }
  `,
  css`
    ${t.css}
  `,
];

const StyledModal = () => ({
  '.global-modal': {
    '&.open': {
      '&:before': {
        background: 'rgba(0, 0, 0, 0.45)',
        width: '100%',
        height: '100vh',
        content: '""',
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        zIndex: '15',
      },
    },
    "[class*='-modal']": {
      position: 'fixed',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      zIndex: '18',
    },
  },
});

const App = () => {
  const store = useStore();
  const className = getAppClassName(store);
  return (
    <div className={className} css={style}>
      <LoadedComponent library="TopNavigationBar" />
      <Context />
      <UtilityPanel />
      <ToasterContainer />
      <div css={StyledModal}>
        <Modals />
      </div>
    </div>
  );
};

export default App;
