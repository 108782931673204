const Viewed = {
  type: 'Viewed',
  tags: ['tracking'],
  image: 'https://breas3.com/item-images-v0/viewed.jpg',
  rolloverImage:
    'https://breas3.com/item-images-v0/viewed.jpg',
  description:
    'Non-visible tracking element that shows when a page has been viewed',
  tracking_id: null,
  forceTracked: true,
  helpExtension: 'page-visited',
};

export default Viewed;
