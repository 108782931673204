const PageNavigationBar = {
  type: 'PageNavigationBar',
  tags: ['other'],
  image:
    'https://breas3.com/item-images-v0/pagenavigationbar.jpg',
  rolloverImage:
    'https://breas3.com/item-images-v0/pagenavigationbar_rollover.jpg',
  description:
    'Interactive element for navigating to the next page (NEXT) or previous page (BACK)',
  helpExtension: 'page-navigation-bar',
  content: {
    en: {
      prev: 'Prev',
      next: 'Next',
      locked: 'Next',
    },
    fr: {
      prev: 'Précédent',
      next: 'Suivant',
      locked: 'Suivant',
    },
  },
};

export default PageNavigationBar;
