import xr from '../../lib/xr/xr-wrapper';
import urls from '../../config/urls';
import { interactivesActionCreator } from '../../events/interactives';
import baseItems from '../../lib/guidebook/default-items/base-items/base-items';

const { set } = interactivesActionCreator;

const loadInteractiveIds = () => async dispatch => {
  const { data: interactives } = await xr.get(urls.interactive_load_all);
  const interactivesMap = new Map();
  interactives.forEach(interactive => {
    const { content, ...rest } = interactive;
    const { id, type } = interactive;
    if (content === null) {
      const { content: workingContent = {} } = baseItems[type];
      interactivesMap.set(id, {
        ...rest,
        initialContent: undefined,
        workingContent,
      });
    } else {
      interactivesMap.set(id, {
        ...rest,
        initialContent: content,
        workingContent: content,
      });
    }
  });
  dispatch(set(interactivesMap));
  return interactives;
};

export default loadInteractiveIds;
