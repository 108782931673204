import { ACHIEVEMENTS_DASHBOARD } from '../../../config/constants';
import getContext from '../../../selectors/view/get-context';
import loadAchievementsDashboardPage from '../../achievements/load-achievements-dashboard-page';

const setAchievementsDashboard = () => (dispatch, getState) => {
  const state = getState();
  const context = getContext(state);
  if (context !== ACHIEVEMENTS_DASHBOARD) {
    dispatch(loadAchievementsDashboardPage());
  }
};

export default setAchievementsDashboard;
