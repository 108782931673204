import isUUID from '../is-uuid';
import getSections from '../guidebook/sections/get-section-nodes-values';

const getSectionId = (state, parameter) => {
  const sections = getSections(state);
  const matchedSections = sections.filter(({ slug }) => slug === parameter);
  if (parameter && matchedSections.length > 0 && !isUUID(parameter)) {
    return matchedSections[0].id;
  }
  return parameter;
};

export default getSectionId;
