/** @jsxImportSource @emotion/react */

const StyledCompleteMessage = {
  fontSize: '1rem',
  padding: '1.3rem 0.5rem',
  fontWeight: '600',
  color: '#acacac',
};

const CompleteMessage = () => {
  return (
    <div css={StyledCompleteMessage} className={'complete-message'}>
      {
        'Confirmation email has been sent to the provided address. Follow the instructions in the provided email to complete your registration'
      }
    </div>
  );
};

CompleteMessage.propTypes = {};

export default CompleteMessage;
