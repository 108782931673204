import React from 'react';
import ErrorUnexpectedHTTPResponse from '../../../components/dialog/errors/ErrorUnexpectedHTTPResponse';
import { useDispatch } from '../../../providers/Store';

const ErrorUnexpectedHTTPResponseWrapper = props => {
  const dispatch = useDispatch();
  return <ErrorUnexpectedHTTPResponse {...props} dispatch={dispatch} />;
};

export default ErrorUnexpectedHTTPResponseWrapper;
