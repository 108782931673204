/** @jsxImportSource @emotion/react */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from './login/Login';
import Registration from './registration/Registration';
import Create from './create/Create';
import Join from './join/Join';
import addFontSizeEventListener from '../../events/font-size/font-size';
import PasswordReset from './password_reset/PasswordReset';

const StyledAuthentication = {
  zIndex: '11',
  minHeight: '100vh',
  minWidth: '100vw',
  background: 'white',
  position: 'absolute',
  fontFamily: 'Open Sans,sans-serif',
};

const Authentication = ({}) => {
  React.useEffect(() => {
    addFontSizeEventListener();
  });
  return (
    <div css={StyledAuthentication} className={'authentication'}>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route path="/register">
          <Registration />
        </Route>
        <Route path="/create-team">
          <Create />
        </Route>
        <Route path="/join-team">
          <Join />
        </Route>
        <Route path="/change-password">
          <PasswordReset />
        </Route>
        <Redirect to="/" />
      </Switch>
    </div>
  );
};

export default Authentication;
