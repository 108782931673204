import xr from '../../lib/xr/xr-wrapper';
import urls from '../../config/urls';
import { guidebooksActionCreator } from '../../events/guidebooks/guidebooks';

const { set } = guidebooksActionCreator;

const loadCourses = () => dispatch =>
  xr.get(urls.courses_assigned).then(({ data }) => {
    dispatch(set(data));
    return data;
  });

export default loadCourses;
