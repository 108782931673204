import isInterfaceHidden from '../../selectors/view/is-interface-hidden';
import { viewActionCreator } from '../../events/view/view';

const { set } = viewActionCreator;

const setStateFromSearchParameter = history => (dispatch, getState) => {
  const state = getState();
  const { search } = history;
  const hideInterface = isInterfaceHidden(state);
  if (
    (search === '?hideInterface' && !hideInterface) ||
    (search !== '?hideInterface' && hideInterface)
  ) {
    const view = { hideInterface: !hideInterface };
    dispatch(set(view));
  }
};

export default setStateFromSearchParameter;
