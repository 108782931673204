import React from 'react';
import LoadedSpinner from '../components/material/LoadedSpinner';
import { useIsLoading } from '../hooks/store';

const LoadingAppWrapper = props => {
  const isLoading = useIsLoading();
  if (isLoading) {
    return <LoadedSpinner isLoading />;
  }
  return props.children;
};

export default LoadingAppWrapper;
