import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';

const DialogWrapper = props => {
  const { toggleOffModal, acceptCallback, rejectCallback } = props;
  return (
    <Dialog
      {...props}
      acceptCallback={() => {
        toggleOffModal();
        acceptCallback();
      }}
      rejectCallback={() => {
        toggleOffModal();
        rejectCallback();
      }}
    />
  );
};

DialogWrapper.propTypes = {
  toggleOffModal: PropTypes.func.isRequired,
  acceptCallback: PropTypes.func,
  rejectCallback: PropTypes.func,
};

DialogWrapper.defaultProps = {
  acceptCallback: () => {},
  rejectCallback: () => {},
};

export default DialogWrapper;
