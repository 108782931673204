/** @jsxImportSource @emotion/react */
import SecondaryButton from '../assets/buttons/SecondaryButton';
import React from 'react';
import { useHistory } from 'react-router-dom';

const RegisterButton = () => {
  const history = useHistory();
  return (
    <div className={'register-btn'}>
      <SecondaryButton
        text={'Register Now'}
        onClick={() => {
          history.push('/register');
        }}
      />
    </div>
  );
};

export default RegisterButton;
