/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { cx } from '@emotion/css';
import { useDispatch, useStore } from '../../providers/Store';
import utilityPanelIsOpen from '../../selectors/view/utility-panel-is-open';
import toggleUtilityPanel from '../../actions/toggle-utility-panel';
import LoadedComponent from '../loaded-component/LoadedComponent';

const outsideCss = [
  css`
    &.open {
      > .outside-wrapper {
        &:before {
          content: '';
          background: rgba(0, 0, 0, 0.2);
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 15;
        }
      }
      .panel {
        right: 0;
      }
    }
  `,
];

const insideCss = [
  css`
    user-select: none;
    z-index: 15;
    width: 18.75rem;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    right: -18.75rem;
    transition: all 0.3s ease-in-out;
    background: #fff;
    box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.5);
    box-sizing: content-box;
    ::-webkit-scrollbar {
        width: 0.625rem;
      }
  `,
];

const UtilityPanel = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const open = utilityPanelIsOpen(store);
  const onClick = () => dispatch(toggleUtilityPanel());
  const className = cx('utility-panel', { open });
  return (
    <div className={className} css={outsideCss}>
      <div className="outside-wrapper" onClick={onClick}>
        <div
          className="panel"
          css={insideCss}
          onClick={e => e.stopPropagation()}
        >
          {open ? <LoadedComponent library="UtilityPanel" /> : null}
        </div>
      </div>
    </div>
  );
};

export default UtilityPanel;
