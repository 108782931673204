export default {
  Question: 'Multiple Choice Question',
  TxtQuestion: 'Question',
  MultipleSelectQuestion: 'Multiple Select Question',
  DragDropMatchingQuestion: 'Drag and Drop Question',
  StatusIndicator: 'Status Indicator',
  progressTracker: 'Progress Tracker',
  videoWithMarkdown: 'Video with Text',
  youTubeWithMarkdown: 'YouTube with Text',
  vimeoWithMarkdown: 'Vimeo with Text',
  markdownWithImage: 'Text with Image',
  markdownWithMarkdown: 'Text with Text',
  markdownWithStamp: 'Text with Stamp',
  imageWithMarkdown: 'Image with Text',
  stampWithMarkdown: 'Stamp with Text',
  markdownWithVideo: 'Text with Video',
  markdownWithYouTube: 'Text with YouTube',
  markdownWithVimeo: 'Text with Vimeo',
  passwordChangeSuccess: 'Your password was changed successfully',
  passwordChangeError: 'An error occurred - your password was not changed',
  audioWithMarkdown: 'Audio with Text',
  markdownWithAudio: 'Text with Audio',
  imageWithImage: 'Image with Image',
  imageWithImageWithImage: 'Image with Image with Image',
  MarkdownMap: 'Text and Map',
  MapMarkdown: 'Map and Text',
  closeCourseDialog1: 'Your course has been launched in a new window/tab.',
  closeCourseDialog2:
    'Complete the course then close the new window/tab to continue.',
  CourseLauncher: 'Course Launcher',
  Exam: 'Exam [Deprecated by Knowledge Check]',
  Poll: 'Poll',
  imageWithQuestion: 'Image with Multiple Choice',
  imageWithMultipleSelectQuestion: 'Image with Multiple Select',
  imageList: 'Image List',
  ImageList: 'Image',
  imageWithMarkdownWithImage: 'Image with Text with Image',
  markdownWithImageWithImage: 'Text with Image with Image',
  imageWithImageWithMarkdown: 'Image with Image with Text',
  imageWithYoutube: 'Image with YouTube',
  youtubeWithImage: 'YouTube with Image',
  Ratings: 'Ratings',
  Polls: 'Polls',
  Exams: 'Exams',
  MultipleSelectQuestions: 'Multiple Select Questions',
  Questions: 'Multiple Choice Questions',
  questionCorrectText: 'CORRECT!',
  DocumentSignature: 'Document Signature',
  DocumentSignatures: 'Document Signatures',
  PDFDocument: 'PDF Document',
  InteractiveIframe: 'Interactive Iframe',
  lottieWithMarkdown: 'Lottie with Text',
  achievementEditor: 'Achievement Editor',
  userDashboard: 'User Dashboard',
  achievementBasics: 'Achievement Basics',
  expiryReset: 'Expiry and Reset',
  requirementsLabel: 'Requirement(s)',
  trackingRequirement: 'Tracking Id(s) Requirement',
  sectionRequirement: 'Section(s) Requirements',
  pageRequirement: 'Page(s) Requirements',
  previewSave: 'Preview and Save',
  achievementName: 'Achievement Name',
  achievementDescription: 'Achievement Description',
  expiry: 'Expiry',
  reset: 'Reset',
  potentialCriteria: 'Potential Criteria',
  addedCriteria: 'Added Criteria',
  threshold: 'Threshold',
  achievementIcon: 'Achievement Icon',
  achievements: 'Achievements',
  dashboardGreeting: 'Welcome to the User Dashboard',
  earnedOn: 'Earned on ',
  resetOn: 'Progress Resets on ',
  expiresOn: 'Expires on ',
  earnedAchievementsName: 'Earned Achievements',
  expiredAchievements: 'Expired Achievements',
  obtainableAchievements: 'Achievements in Progress',
  cancelAndClose: 'Cancel Changes and Close',
  markdownWithLottie: 'Text with Lottie',
  achievementReport: 'Achievement Report',
  achievementId: 'Achievement Id',
  earnedAt: 'Earned At',
  resetsOn: 'Resets On',
  isReset: 'Is Reset',
  noData:
    'Nobody has earned any achievements yet, try creating one in the ' +
    'Achievement Editor!',
  achievementReportWelcome: 'Welcome to Achievement Reporting',
  createNewAchievement: 'Create New Achievement',
  achievementDisabled: 'Achievement Disabled',
  disabledWarning:
    'Warning: This Achievement is disabled. It cannot be earned at this time but will be still be shown to those who already earned it.',
  editImage: 'Edit Image',
  expiryString: 'from the date the achievement was earned',
  noIcon: 'No Icon',
  noIconSelected: 'No Icon Selected',
  selectedIcon: 'Selected Icon:',
  previewPopup: 'Preview Popup',
  looksGood: 'Looks Good',
  achievementsNameWarning: 'Achievements require a name value',
  achievementsDescriptionWarning: 'Achievements require a description value',
  requirementWarning:
    'Requirement Setup is invalid. There must be at least one requirement per achievement',
  expiryResetWarning:
    'Any modifications to expiry or reset will only affect users who have not ' +
    'earned or re-earn this achievement.',
  requirementsModificationWarning:
    'Any modifications to requirements will only affect users who have not ' +
    'earned or re-earn this achievement.',
  yourProgress: 'Your Progress',
  markAllAsRead: 'Mark All as Read',
  newText: 'New',
  noNotifications: 'You currently have no notifications',
  editIcon: 'Edit Icon',
  selectIcon: 'Select Icon',
  displayType: 'Display Type',
  editColor: 'Edit Color',
  editDisplayType: 'Edit Display Type',
  certificationOfCompletion: 'Certification of Completion',
  hasCompleted: ' has completed ',
  dateCertified: 'Date Certified: ',
  expiryDate: 'Expiry Date',
  displayProperties: 'Display Properties',
  certificateIncomplete: 'Certificate Incomplete',
  thumbnailPickerText:
    'Enter a video timestamp in seconds from the selected video to set a thumbnail.',
  nameLabel: 'Name',
  completed: 'Completed',
  thumbnailPickerPreview: 'Edit video thumbnail',
  leftBoxDefaultText: 'For the Completion of Achievement',
  dateOfCompletion: 'Date of Completion',
  dateOfExpiry: 'Date of Expiry',
  presentedTo: 'PRESENTED TO:',
  editorResetWarning:
    'Are you sure you wish to reset your progress for this item',
  assessmentText: 'Assessment Text',
  awaitingContent: 'Awaiting Content Text',
  awaitingUser: 'Awaiting User Text',
  awaitingManager: 'Awaiting Manager Text',
  completedText: 'Completed Text',
  assessmentEditor: 'Assessment Editor',
  AssessmentExam: 'Assessment Exam',
  managerDashboard: 'Manager Dashboard',
  selectAGroup: 'Select A Group',
  selectAType: 'Select A Type',
  userSectionCompletions: 'User Section Completions',
  usersComplete: 'Users Complete',
  usersAssigned: 'Users Assigned',
  sectionUserTitle: 'Section User Completions',
  achievementUserTitle: 'Achievement User Completions',
  enterSearchTerm: 'Enter Search Term',
  userAchievementTitle: 'User Achievement Completions',
  userSectionTitle: 'User Section Completions',
  completeUsers: 'Complete Users',
  eligibleUsers: 'Eligible Users',
  completedAchievements: 'Completed Achievements',
  eligibleAchievements: 'Eligible Achievements',
  sectionsCompleted: 'Sections Completed',
  sectionsAssigned: 'Sections Assigned',
  completedAt: 'Completed At',
  PDFLink: 'PDF Link',
  buttonText: 'Button Text',
  PDFScheduleLinks: 'PDF Schedule Links',
  firstButtonText: 'First Button Text',
  secondButtonText: 'Second Button Text',
  firstLink: 'First Link',
  secondLink: 'Second Link',
  SectionEnd: 'Section End',
  sectionResults: 'Section Results',
  pageResults: 'Page Results',
  KnowledgeCheck: 'Knowledge Check',
  enableAssessment: 'Enable Assessment',
  enableExam: 'Enable Exam',
  responseDetailsText: 'Response Details For Attempt',
  notificationSettings: 'Notification Settings',
  myMessages: 'My Messages',
  showHiddenMessagesLabel: 'Show Hidden Messages',
  sendMessages: 'Send Messages',
  sentMessages: 'Sent Messages',
  noMessages: 'No Messages to Display',
  hideMessage: 'Hide Message',
  receivedOn: 'Received On',
  viewedOn: 'Viewed On',
  from: 'From:',
  addRemoveRecipients: 'Add/Remove Recipients',
  editMessageContent: 'Edit Message Content',
  to: 'To:',
  sentOn: 'Sent On',
  invalidFileTypes: 'Invalid file types found',
  fileSizeTooBig: 'File size is too big',
  filesUploadedSuccessfully: 'Files uploaded Successfully',
  oneOrMoreFilesFailedToUpload: 'One or more files failed to upload',
  successfullyDeleted: 'Successfully deleted',
  failedToDelete: 'Failed to delete',
  uploadingFiles: 'Uploading Files',
  phoneNumbers: 'Phone Numbers',
  copySectionWithNewPageIds: "Make Copy With New Page Id's",
  copySectionWithDuplicatePageIds: 'Make Copy With Duplicate Pages',
  noAttempts: 'No Attempts',
  lastUpdated: 'Last Updated',
  knowledgeCheckRequests: 'Knowledge Check Requests',
  sortAlphabetically: 'Sort Alphabetically',
  Assessment: 'Assessment',
  noDataToDisplay: 'No Data to Display',
  overallProgressOfUsers: 'Overall Progress of Users',
  getCSV: 'Get CSV',
  subjectLine: 'Subject Line',
  messageBody: 'Message Body',
  sortByGroup: 'Sort By Group',
  people: 'People',
  selectAllInList: 'Select All From This List',
  deselectAllInList: 'Deselect All From Thi List',
  send: 'Send',
  NA: 'N/A',
  knowledgeChecks: 'Knowledge Checks',
  knowledgeChecksTitle: 'Knowledge Checks For All Groups and Users You Manage',
  manager: 'Manager',
  PageNavigationBar: 'Page Navigation Bar',
  previousLesson: 'Previous Lesson',
  nextLesson: 'Next Lesson',
  overallProgression: 'Overall Progression',
  myCertificates: 'My Certificates',
  assignSelected: 'Assign Selected',
  createNew: 'Create New',
  enterName: 'Enter Name',
  TrackedVideo: 'Tracked Video',
  requiredCompletionPercentage: 'Required Completion Percentage',
  lastVisitedText:
    "You've visited this section before, would you like to resume where you left off?",
  ImageStatusIndicator: 'Image Status Indicator',
  incompleteImage: 'Incomplete Image',
  completeImage: 'Complete Image',
  userCompletionPercentTitle: 'User Overall Completion of Assigned Content',
  completionPercentage: 'Completion Percentage',
  pendingAssessments: 'Pending Assessments',
  processedAssessments: 'Processed Assessments',
  backToLearning: 'Back To Learning',
  sectionEmptyError: 'Section has no navigable pages',
  turnOnEditModeGoToSection: 'Turn on edit mode and continue to section?',
  hideFeedbackCheckMark: 'Hide Feedback Check Mark',
  inProgress: 'In Progress',
  noneInProgress: 'You have no achievements that are in progress.',
  noneEarned: "You haven't earned any achievements yet.",
  noneExpired: 'You have no expired achievements.',
  pageNavigationBarEditor: 'Page Navigation Bar Editor',
  previousPage: 'Previous Page',
  nextPage: 'Next Page',
  nextPageLocked: 'Next Page Locked',
  welcome: 'Welcome',
  myAccount: 'My Account',
  select: 'Select',
  trueText: 'True',
  falseText: 'False',
  locked: 'Locked',
  invalid: 'Invalid',
  invalidInputs: 'Unable to process request as some input values are invalid',
  passwordMatchFailed:
    'New Password and Confirm New Password fields must have matching values to proceed',
  passwordErrorMessage:
    'User must be logged in and provide their valid current password',
  changesSavedSuccessfully: 'Changes Saved Successfully',
  submitAndClose: 'Submit and Close',
  displayName: 'Display Name',
  currentPassword: 'Current Password',
  confirmNewPassword: 'Confirm New Password',
  basics: 'Basics',
  details: 'Details',
  usernameDescription: 'Unique system ID for you to log on',
  displayNameDescription: 'How your name shows up in eco',
  detailsDescription:
    'Control settings for connected experiences across your learning platform, effecting your assignments and settings.',
  newPassword: 'New Password',
  passwordHint: 'Password Hint',
  notStartedUsers: 'Not Started Users',
  partiallyCompleteUsers: 'Partially Complete Users',
  emptyInteractiveOpen: 'This',
  emptyInteractiveClose: 'has not been setup',
  interactiveEditor: 'Interactive Editor',
  selectInteractive: 'Select Interactive Id',
  interactiveContent: 'Interactive Content',
  interactive: 'Interactive',
  change: 'Change',
  itemError: 'Something has gone wrong with this item',
  replacedByKc:
    "This item is no longer supported and has had it's functionality replaced by the Knowledge Check item.",
  pageLayoutBuilder: 'Page Layout Editor',
  cancelAndCloseShort: 'Cancel and Close',
  helpEditing: 'Help Editing?',
  needHelpEditing: 'Need Help Editing?',
  Markdown: 'Text',
  filters: 'Filters',
  confirmChanges: 'Confirm Changes',
  developFromTemplate: 'Develop From Template',
  addIndividualItems: 'Add Individual Items',
  pageLayoutPreview: 'Page Layout Preview',
  copyPageAsTemplate: 'Copy Page As Template',
  copyPageAsTemplateDescription:
    'This will allow you to select an existing page and have all the items from that page added to the current page. When using this mode the items will be populated with default data and will not be direct copies of the items found on the copied page. Pro Tip: If you find yourself making many similar pages then create a single page and use it as a template for the following pages.',
  copyPageWithItems: 'Copy Page With Items',
  copyPageWithItemsDescription:
    'This will allow you to select an existing page and have all the items from that page added to the current page. When using this mode the items will have their existing data and will be direct copies of the items found on the copied page. Pro Tip: If you find yourself making many similar pages then create a single page and use it as a template for the following pages.',
  responseSubmitted: 'Response Submitted',
  submitAnswer: 'Submit Answer',
  enterAnswerHere: 'Enter Answer Here',
  writtenResponseEditor: 'Written Response Editor',
  question: 'Question',
  minCharacterLength: 'Minimum Character Length',
  maxCharacterLength: 'Maximum Character Length',
  responseTooShort: 'Response Too Short',
  responseTooLong: 'Response Too Long',
  searchTerm: 'Search Term',
  doesNotMatch: 'Does Not Match',
  beginsWith: 'Begins With',
  endsWith: 'Ends With',
  equalTo: 'Equal To',
  notEqualTo: 'Not Equal To',
  greaterThan: 'Greater Than',
  lessThan: 'Less Than',
  selectDates: 'Select Dates',
  selectDate: 'Select Date',
  allDates: 'All Dates',
  editReportColumns: 'Edit Report Columns',
  editSortingOptions: 'Edit Sorting Options',
  editFilters: 'Edit Filters',
  saveAndClose: 'Save And Close',
  cancelChangesAndClose: 'Cancel Changes And Close',
  noSort: 'No Sort',
  loadTemplate: 'Load Template',
  saveTemplate: 'Save Template',
  selectATemplate: 'Select A Template',
  reportTitle: 'Report Title',
  templateName: 'Template Name',
  loadingTemplates: 'Loading Templates',
  editReport: 'Edit Report',
  inReport: 'In Report',
  notInReport: 'Not In Report',
  VrLauncher: 'VR Launcher',
  vrLauncherEditor: 'VR Launcher Editor',
  selectAnApp: 'Select An App',
  launchApp: 'Launch App',
  notStarted: 'Not Started',
  notRunningInAppMode:
    'Please visit this page in app mode to use this feature.',
  noAppSelected:
    'Either no app is selected, or the selected app is no longer available. Please select a valid app in the editor.',
  appLaunchedDialog1: 'Your app has been launched in a new window.',
  appLaunchedDialog2:
    'Please finish what you are doing in the app then close the new window to continue.',
  resetEarnedDialogHeader: 'Are you sure you want to reset this achievement?',
  resetEarnedDialogBody:
    'This will reset all content associated to the most recent earning of this achievement for this user.',
  setAsDefaultTemplate: 'Set As Default Template',
  personalInteractiveReports: 'Personal Interactive Reports',
  noInteractiveReportsAtThisTime: 'No interactive reports at this time',
  clickToDownloadAndGenerate:
    'Click to generate report and automatically download',
  myReports: 'My Reports',
  writtenResponse: 'Written Response',
  sectionPagePicker: 'Page and Section Picker',
  useSelected: 'Use Selected',
  currentlySelected: 'Currently selected',
  local: 'Local',
  loaded: 'Loaded',
  sideMargins: 'Side Margins',
  passKnowledgeCheck:
    'Please confirm that the user has passed this knowledge check',
  failKnowledgeCheck:
    'Please confirm that the user has failed this knowledge check',
  createACourse: 'Create A Course',
  courseName: 'Course Name',
  courseCategory: 'Course Category',
  selectACategory: 'Select A Category',
  chooseACourseImage: 'Choose A Course Image',
  courseSuccessfullyPublished: 'Course Successfully Published',
  selectVideoSource: 'Select Video Source',
  email: 'Email',
  emailHelp: 'Please enter a valid email address',
  password: 'Password',
  partOfAnotherPersonsTeam: 'Part of another persons team?',
  wantToCreateYourOwnCourse: 'Want to create your own course?',
  registerNow: 'Register now',
  toCreateYourOwnTeamAndCourse: 'To create your own team and course!',
  afterYouClickSubmit:
    'After you click submit you will receive an email with a link and instructions to complete your registration',
  createYourOwnTeamAndCourse: 'Create your own team and course',
  breazeIsAProductDevelopedBy: 'Breaze is a product developed by ',
  icomProductions: 'ICOM Productions',
  fullName: 'Full Name',
  userSetupPanelMessage:
    'This information will be used to create your individual Breaze account that you can access anytime to learn or create content within your e-learning organization.',
  userAccountSetup: 'User Account Setup',
  confirmPassword: 'Confirm Password',
  organizationSetupPanelMessage:
    'This information will be used to create an organization where your team can gather together for an innovative e-learning experience.',
  companyName: 'Company Name',
  industry: 'Industry',
  siteUsername: 'Site Username',
  organizationSetup: 'Organization Setup',
  passwordAndConfirmPasswordFieldsMustMatch:
    'Password and Confirm Password fields must match',
  passwordMinLength: 'Password must have a length of 8',
  alreadyRegistered: 'Already Registered',
  optInForNewsletter: 'Opt in to newsletter',
  agreeTo: 'Agree to ',
  termsOfService: 'Terms of Service',
  requirements: 'Requirements',
  multipleChoiceQuestion: 'Multiple Choice Question',
  userAccounts: 'User Accounts',
  openSpots: 'Open Spots',
  userManagementSection: 'User Management Section',
  pendingInvites: 'Pending Invites',
  inviteUser: 'Invite User',
  yourTeam: 'Your Team',
  resetPassword: 'Reset Password',
  removeUser: 'Remove User',
  removeUserDialogBody: 'Are you sure you would like to remove this user?',
  openSpot: 'Open Spot',
  userLimitReachedString: 'You have reached your user limit',
  invitationSentString:
    'User will receive an email with registration instructions shortly.',
  requirement: 'Requirement',
  potentialRequirements: 'Potential Requirements',
  addedRequirements: 'Added Requirements',
  breazeVersion: 'Breaze Version',
  deleteCourse: 'Delete Course',
  deleteCourseDialog:
    'Are you sure you want to delete this course? This will delete all of its associated sections, pages, and unearned achievements.',
  deleteFile: 'Delete File',
  deleteFileDialog:
    'Are you sure you want to permanently delete this file? It will remove any current usages of this file.',
  emptyLearnerHomepageBlurb:
    'It looks like you have no content right now, please come back later',
  uploadErrorMessage: 'Something went wrong with one or more of your upload',
  achievementEditorHelp: 'Achievement Editor Help',
  startWithTemplate: 'Start with template',
  configuration: 'Configuration',
  changeIcon: 'Change Icon',
  disableThisAchievement: 'Disable this achievement',
  deleteAchievement: 'Delete Achievement',
  requirementType: 'Requirement Type',
  course: 'Course',
  achievementNameDescriptionWarning:
    'Achievement name and description fields are required to save this achievement',
  achievementConfigurationWarning:
    'Achievement must have either a course, section or page assigned to it through the configuration options',
  achievementDisabledWarning:
    'This achievement is currently marked as disabled. This will prevent users from earning this achievement until it is enabled.',
  totalAchievements: 'Total Achievements',
  totalActiveAchievements: 'Total Active Achievements',
  achievementDeleteWarning:
    'Are you sure you would like to delete this achievement? This action cannot be undone and will remove this achievement from all users who are eligible to earn or have already earned it.',
  achievementsDashboard: 'Achievements Dashboard',
  overallStatus: 'Overall Status',
  achievementsInProgress: 'Achievements In Progress',
  achievementsEarned: 'Achievements Earned',
  achievementsExpired: 'Achievements Expired',
  badges: 'Badges',
  certificates: 'Certificates',
  notEligibleToEarn: 'You are currently not eligible to earn any achievements',
  noEarnedAchievements: 'You currently have no earned achievements',
  noExpiredAchievements: 'You currently have no expired achievements',
  print: 'Print',
  achievementDetails: 'Achievement Details',
  allOfTheFollowing:
    'All of the following requirements must be completed in order to earn this achievement',
  xOfTheFollowing:
    ' of the following requirements must be completed to earn this achievement',
  achievement: 'Achievement',
  earnableOn: 'Earnable on',
  editBackground: 'Edit Background',
  fullSpan: 'Full Span',
  advancedOptions: 'Advanced Options',
  sideMarginDescription:
    'Side margins are used to reduce the width that text can span on larger screens.',
  EditorComment: 'Editor Comment',
  showDots: 'Show dots',
  makeClickable: 'Allow click to complete',
  makeClickableDescription:
    'Disabling this option will prevent the stamp from completing with a mouse click. Can be used to visually indicate tracking id completion to learners',
  trackingIdDescription:
    'Tracking ids are used in to manage progress and completions for users across different items',
  interactiveId: 'Interactive Id',
  interactiveIdDescription:
    'Interactive ids are used to attach user responses to item data. They can be re-used as needed.',
  addChoice: 'Add Choice',
  correctAnswer: 'Correct Answer',
  randomizeAnswers: 'Randomize answers ',
  retry: 'Retry',
  letter: 'Letter',
  addBucket: 'Add Bucket',
  DoubleItem: 'Double Item',
  swapSides: 'Swap sides',
  changeItemType: 'Change Item Type',
  TripleItem: 'Triple Item',
  sectionDescription: 'Section Description',
  addSection: 'Add Section',
  addLesson: 'Add Lesson',
  courseLive: 'Make Course Live',
  courseLiveBlurb: 'This will make this course visible to your team',
  publishChanges: 'Publish Changes',
  editCourseSettings: 'Edit Course Settings',
  courseTitle: 'Course Title',
  createANewCourse: 'Create A New Course',
  getStartedInMinutes: 'Get Started and Create Your First Course in Minutes',
  getStartedHomepageBlurb:
    'The create course button will create a basic course with a starter lesson page for you to edit',
  sectionDeleteBlurb:
    "This section has lessons in it, if you delete the section you will also delete it's lessons.",
  sectionDeleteAccept: "Delete section and it's lessons",
  reportingDashboard: 'Reporting Dashboard',
  activeUsers: 'Active Users',
  userSpots: 'User Spots',
  overallCompletion: 'Overall Completion',
  userId: 'User Id',
  userProgressReport: 'User Progress Report',
  trackingIdsEligible: 'Tracking Ids Eligible',
  trackingIdsComplete: 'Tracking Ids Complete',
  trackingProgress: 'Tracking Progress',
  trackingIdName: 'Tracking Id Name',
  progressReport: 'Progress Report',
  usersEligible: 'Users Eligible',
  usersEarned: 'Users Earned',
  percentageComplete: 'Percentage Complete',
  achievementCompletions: 'Achievement Completions',
  overallAchievementCompletion: 'Overall Achievement Completion',
  latestActivity: 'Latest Activity',
  overallProgress: 'Overall Progress',
  averageUserProgress: 'Average User Progress',
  usersName: "User's Name",
  expiresAt: 'Expires At',
  resetsAt: 'Resets At',
  interactiveReporting: 'Interactive Reporting',
  progressReporting: 'Progress Reporting',
  interactiveReportingDescription:
    'Shows the results of polls, ratings, and the answer results from your content',
  progressReportingDescription:
    'Shows various completion stats about users and their progress through the provided learning material',
  singleSelectQuestionsReport: 'Single Select Questions Report',
  questionsReport: 'Questions Report',
  pageName: 'Page Name',
  itemType: 'Item Type',
  questionReport: 'Question Report',
  pollReport: 'Poll Report',
  mostPopularResponse: 'Most popular Response',
  ratingReport: 'Rating Report',
  writtenResponseReport: 'Written Response Report',
  totalResponses: 'Total Responses',
  allowsFeedback: 'Allows Feedback',
  percentageTotal: 'Percentage Total',
  usersResponded: 'Users Responded',
  ratingPercent: 'Rating %',
  changeOrganizations: 'Change Organizations',
  pickATemplate: 'Pick a Template',
  blankPage: 'Blank Page',
  returnToCourseEditing: 'Return to Course Editing',
  createCourse: 'Create Course',
  generateId: 'Generate Id',
  layoutEditorHelp: 'Layout Editor Help',
  itemTags: 'Item Tags',
  layoutPreview: 'Layout Preview',
  pageEditingHelp: 'Page Editing Help',
  changesSuccessfullySaved: 'Changes Successfully Saved',
  saveChanges: 'Save Changes',
  courseSettingsHelp: 'Course Settings Help',
  courseSettingsEditor: 'Course Settings Editor',
  closeModal: 'Close Modal',
  lockSection: 'Lock Section',
  lockSectionDescription:
    'Locking the section will prevent learners from progressing to the next piece of content until all previous pieces of content have been completed',
  assignCourseImage: 'Assign Course Image',
  putCourseLive: 'Put Course Live',
  takeCourseOffline: 'Take Course Offline',
  addSectionCopy: 'Add Section Copy',
  addNewSection: 'Add New Section',
  copySectionHelp: 'Copy Section Help',
  copySection: 'Copy Section',
  copyPage: 'Copy Page',
  copyPageHelp: 'Copy Page Help',
  addNewPage: 'Add New Page',
  addPageCopy: 'Add Page Copy',
  shallowCopyPage: 'Shallow Copy Page',
  deepCopyPage: 'Deep Copy Page',
  shallowCopySection: 'Shallow Copy Section',
  deepCopySection: 'Deep Copy Section',
  shallowCopyPageDescription:
    'Makes a replica of the selected page that can be edited independently of all other pages',
  deepCopyPageDescription:
    'Adds existing page to the intended location. Page edits will be reflected on both the copied page and the original.',
  shallowCopySectionDescription:
    'Replicates section and all subpages of selected section. Edits to pages will act independently of all other pages',
  deepCopySectionDescription:
    'Replicates section and adds existing subpages of selected section. Edits to pages will be reflected in all locations that page exists',
  typeDeleteToContinue:
    'In order to proceed with this action you must type "Delete" in the following input and click yes to delete the course',
  inviteInstruction:
    'Use the following input to invite users to your team. Users will be receive an email invite to this team that they can use to begin their learning journey.',
  idPickerHelp: 'Id Picker Help',
  interactiveIdPicker: 'Interactive Id Picker',
  trackingIdPicker: 'Tracking Id Picker',
  createNewId: 'Create New Id',
  rename: 'Rename',
  refineSearch: 'Refine Search',
  horizontal: 'Horizontal',
  vertical: 'Vertical',
  orientation: 'Orientation',
  category: 'Category',
  enterSearchToBegin: 'Enter Search To Begin',
  noResultsFound: 'No Results Found',
  clickToSelect: 'Click To Select',
  selected: 'Selected',
  addFromStock: 'Add From Stock',
  howToUserGuide: 'How To User Guide',
  changeTeam: 'Change Team',
  copyHelp: 'Copy Help',
  courseHelp: 'Course Help',
  alignment: 'Alignment',
  top: 'Top',
  middle: 'Middle',
  bottom: 'Bottom',
  addedItem: 'Added Item',
  originalImageWidth: 'Original Image Width',
  originalImageHeight: 'Original Image Height',
  cropWidth: 'Crop Width',
  cropHeight: 'Crop Height',
  presetDimensions: 'Preset Dimensions',
  aspectRatio: 'Aspect Ratio',
  saveAs: 'Save As',
  fileName: 'File Name',
  itemSelect: '- Select Item -',
  overwriteFileDialog:
    "Saving this file will replace all usages with the cropped version. You will need to clear your browser's cache to see the changes immediately or you can wait 4 hours.",
  saveCopy: 'Save Copy',
  startHere: 'Start Here',
  courseDetails: 'Course Details',
};
