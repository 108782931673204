/** @jsxImportSource @emotion/react */
import React from 'react';
import BreazeLogo from '../assets/BreazeLogo';
import AlreadyRegisteredButton from './AlreadyRegisteredButton';
import Policy from './Policy';
import RegisterForm from './RegisterForm';
import CompleteMessage from './CompleteMessage';

const StyledRegistrationBody = settled => ({
  opacity: settled ? '1' : '0',
  padding: '1rem',
  maxWidth: '32rem',
  minWidth: '32rem',
  margin: 'auto',
  transition: 'opacity 0.4s ease-in',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  '.create': {
    fontSize: '1.5rem',
    fontWeight: '700',
    margin: '1rem 2.5rem 0 2.5rem',
    boxSizing: 'border-box',
  },
  '.breaze-logo': {
    margin: 'auto',
    width: '12rem',
    height: '4rem',
    maxWidth: '100%',
  },
  '.already': {
    paddingTop: '1.5rem',
  },
  '.after': {
    color: '#acacac',
    fontSize: '0.8rem',
    width: '23rem',
    margin: '2rem auto auto auto',
    '@media only screen and (max-width: 768px)': {
      width: '75%',
    },
  },
  '@media only screen and (max-width: 768px)': {
    boxSizing: 'border-box',
    padding: '0.5rem',
    maxWidth: '100%',
    minWidth: '0',
    '.create': {
      fontSize: '1rem',
    },
  },
  '@media only screen and (min-width: 1440px)': {
    margin: 'auto 0 auto auto',
  },
});

const RegisterBody = props => {
  const [settled, setSettled] = React.useState(false);
  const [showCompleteMessage, setShowCompleteMessage] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setSettled(true);
    }, 3);
  });

  return (
    <div css={StyledRegistrationBody(settled)} className={'registration-form'}>
      <BreazeLogo />
      <div className={'create'}>
        {'Create your free account today and build your course in 2 minutes!'}
      </div>
      {showCompleteMessage ? (
        <CompleteMessage />
      ) : (
        <RegisterForm
          {...props}
          setShowCompleteMessage={setShowCompleteMessage}
        />
      )}
      <div className={'after'}>
        {' '}
        {
          'After you click submit you will receive an email with a link and instructions to complete your registration'
        }
      </div>
      <AlreadyRegisteredButton {...props} />
      <Policy />
    </div>
  );
};

export default RegisterBody;
