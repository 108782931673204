import reduxEvents from './redux-events';
import set from '../lib/immutable-array-helpers/set';

const setTrackingIds = (_, trackingIds) => trackingIds;

const insertTrackingId = (state, trackingId) => [...state, trackingId];

const updateTrackingId = (state, trackingId) => {
  const index = state.findIndex(({ id }) => id === trackingId.id);
  return set(index, state, trackingId);
};

const trackingIdsEvents = reduxEvents()
  .addEvent('TRACKING_IDS', 'SET_TRACKING_IDS', setTrackingIds)
  .addEvent('TRACKING_IDS', 'UPDATE_TRACKING_ID', updateTrackingId)
  .addEvent('TRACKING_IDS', 'INSERT_TRACKING_ID', insertTrackingId);

const trackingIds = trackingIdsEvents.getReducer([]);
const trackingIdsActionCreator = trackingIdsEvents.getActionCreators();

export { trackingIds, trackingIdsActionCreator };
