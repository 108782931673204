import getTopics from '../../../selectors/topics/get-topics';
import pushHistory from '../../history/push-history';

const setTopicHistory = id => (_, getState) => {
  const state = getState();
  const topics = getTopics(state);
  if (topics.map(({ id: topicId }) => topicId).includes(id)) {
    pushHistory(`/topic.${id}/`);
  }
};

export default setTopicHistory;
