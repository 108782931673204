import errorDialog from '../../config/error-dialog';
import { playerViewActionCreator } from '../../events/view/player-view';

const { setLoading } = playerViewActionCreator;

const isLoadingWrapper = promise => dispatch =>
  new Promise(resolve => {
    dispatch(setLoading(true));
    const stopLoading = () => dispatch(setLoading(false));
    promise
      .then(data => {
        stopLoading();
        resolve(data);
      })
      .catch(error => {
        stopLoading();
        dispatch(errorDialog(error.status));
      });
  });

export default isLoadingWrapper;
