import initialize from './initialize';
import getTrackers from '../../selectors/google-analytics/get-trackers';
import setTracker from './set-tracker';
import getGAUserId from '../../selectors/google-analytics/get-ga-user-id';
import initializeTracker from './initialize-tracker';

const cache = { setHistory: null };

const setGoogleAnalyticsHistory = (state, historyParameters) => {
  const trackers = getTrackers(state);
  if (cache.setHistory === null && trackers.length > 0) {
    initialize();
    const gaUserId = getGAUserId(state);
    const intializedTrackers = trackers.map(tracker =>
      initializeTracker(tracker)
    );
    cache.setHistory = historyParams =>
      intializedTrackers.forEach(trackerName => {
        const { path, title } = historyParams;
        const params = { trackerName, path, title, userId: gaUserId };
        setTracker(params);
      });
  }
  if (cache.setHistory !== null) {
    cache.setHistory(historyParameters);
  }
};

export default setGoogleAnalyticsHistory;
