import urls from '../../config/urls';
import axios from 'axios';

const changePassword = (token, newPassword) => {
  return axios.post(urls.registration_changePassword, {
    token,
    password: newPassword
  });
};

export default changePassword;
