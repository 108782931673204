/** @jsxImportSource @emotion/react */
import React from 'react';
import { useHistory } from 'react-router-dom';
import getQueryParameter from '../../../selectors/get-query-parameter';
import validateCreateToken from '../../../api/authentication/validate-create-token';
import TopBar from '../assets/TopBar';
import LeftBean from '../assets/LeftBean';
import RightBean from '../assets/RightBean';
import Footer from '../assets/Footer';
import Form from './Form';
import ReCaptchaMessage from '../assets/ReCaptchaMessage';

const StyledCreateOrganization = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  '.body': {
    display: 'flex',
    margin: 'auto 0',
  },
  '.background': {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    zIndex: '-1',
  },
};

const Create = props => {
  const history = useHistory();
  const token = getQueryParameter('token');
  const [email, setEmail] = React.useState(null);
  const [userExists, setUserExists] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const validateToken = async () => {
      if (token) {
        try {
          setLoading(true);
          const data = await validateCreateToken(token);
          if (data?.user_status === 'existing_user') {
            setUserExists(true);
          } else if (data?.user_status === 'new_user') {
            setUserExists(false);
          } else if (data?.user_status === 'create_limit_reached') {
            history.push('/');
          }
          setEmail(data?.email);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          history.push('/');
        }
      } else {
        history.push('/');
      }
    };
    validateToken();
  }, [token]);

  return (
    <div css={StyledCreateOrganization} className={'create-organization'}>
      <TopBar {...props} />
      <div className={'body'}>
        <LeftBean />
        <Form
          loading={loading}
          userExists={userExists}
          email={email}
          token={token}
        />
        <RightBean />
      </div>
      <Footer />
      <ReCaptchaMessage />
    </div>
  );
};

export default Create;
