const Stamp = {
  type: 'Stamp',
  tags: ['tracking'],
  image: 'https://breas3.com/item-images-v0/stamp.jpg',
  rolloverImage:
    'https://breas3.com/item-images-v0/stamp_rollover.jpg',
  description:
    'Clickable visual interactive element that displays completion of a page/section',
  tracking_id: null,
  language: null,
  forceTracked: true,
  canMultiItem: true,
  helpExtension: 'stamp',
  content: {
    clickToComplete: true,
    completeImage: {
      URI: 'https://breas3.com/stamps/complete_stamp.png',
      FileName: 'complete_stamp.png',
    },
    incompleteImage: {
      URI: 'https://breas3.com/stamps/incomplete_stamp.png',
      FileName: 'incomplete_stamp.png',
    },
  },
};

export default Stamp;
