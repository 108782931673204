/** @jsxImportSource @emotion/react */
import React from 'react';
import BreazeTextInput from '../assets/Inputs/BreazeTextInput';
import PrimaryButton from '../assets/buttons/PrimaryButton';
import sendPasswordResetEmail from '../../../api/authentication/send-password-reset-email';
import { getRecaptchaToken, loadRecaptchaScript } from '../../../actions/re-captcha/recaptcha';

const StyledPasswordResetForm = {
  '.input': {
    margin: '1rem 0 0.5rem',
  },
  '.primary-button': {
    fontSize: '0.9rem',
    padding: '0.5rem 1.75rem',
    margin: 'auto',
  },
  '.auth-message': {
    boxSizing: 'border-box',
    height: '2rem',
    color: '#e64c4c',
    textAlign: 'center',
    fontSize: '0.8rem',
  },
};

const EmailForm = ({loading, setSentEmail, setLoading}) => {
  const [email, setEmail] = React.useState({ value: '', valid: false });
  const [authMessage, setAuthMessage] = React.useState('');
  const disabled = !(email.valid && !loading);

  React.useEffect(() => {
    loadRecaptchaScript();
  }, []);

  const onSubmit = () => {
    getRecaptchaToken('passwordReset').then(captchaToken => {
      doSendEmail(captchaToken);
    });
  };

  const doSendEmail = async (captchaToken) => {
    setLoading(true);
    sendPasswordResetEmail(email.value, captchaToken).then(response => {
      if (response.ok) {
        setLoading(false);
        setEmail({ value: '', valid: false });
        setSentEmail(true);
      } else {
        response.text().then(str => {
          setAuthMessage(str);
          setLoading(false);
        });
      }
    });
  };

  return (
    <form onSubmit={e => e.preventDefault()} css={StyledPasswordResetForm}>
      <BreazeTextInput
        label={'Email'}
        pattern={'^.+@.+\\..+$'}
        onChange={setEmail}
        value={email.value}
        helpText={'Please enter a valid email address'}
      />
      <div className={'auth-message'}>{authMessage}</div>
      <PrimaryButton
        onClick={onSubmit}
        text={'Submit'}
        disabled={disabled}
      />
    </form>
  );
};

export default EmailForm;
