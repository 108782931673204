import xr from '../../lib/xr/xr-wrapper';
import urls from '../../config/urls';
import { guidebookActionCreator } from '../../events/guidebook/guidebook';
import createGuidebook from '../../events/guidebook/create-guidebook';
import loadInteractiveIds from '../interactive/load-interactive-ids';

const { set: setGuidebook } = guidebookActionCreator;

const loadCourse = id => async dispatch => {
  const [{ data }] = await Promise.all([
    xr.get(urls.courses_load, { courseId: id }),
    dispatch(loadInteractiveIds()),
  ]);
  const guidebook = createGuidebook(id, data);
  dispatch(setGuidebook(guidebook));
  return guidebook;
};

export default loadCourse;
