import React from 'react';
import PropTypes from 'prop-types';
import LoadedSpinner from '../material/LoadedSpinner';

const Loading = ({
  isLoading,
  loadingComponent: LoadingComponent,
  component: Component,
  ...props
}) => (isLoading ? <LoadingComponent isLoading /> : <Component {...props} />);

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  loadingComponent: PropTypes.func,
};

Loading.defaultProps = { loadingComponent: LoadedSpinner };

export default Loading;
