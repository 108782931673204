const defaultLibraryMap = {
  Spinner: 'MaterialSpinner',
  TopNavigationBar: 'TopNavigationBar',
  Homepage: 'Homepage',
  Topic: 'Topic',
  Section: 'Section',
  Page: 'Page',
  additionalDefaultItems: 'emptyObject',
  Item: 'Item',
  ItemEditor: 'ItemEditor',
  MarkdownEditor: 'MarkdownEditor',
  ReactColor: 'ReactColor',
  Map: 'Map',
  Lottie: 'Lottie',
  UtilityPanel: 'UtilityPanel',
  UtilityPanelEditorPanel: 'UtilityPanelEditorPanel',
  UtilityPanelReportingPanel: 'UtilityPanelReportingPanel',
  UtilityPanelManagementPanel: 'UtilityPanelManagementPanel',
  Reporting: 'Dashboard',
  UserManagement: 'UserManagement',
  ReportingGroupSelector: 'ReportingGroupSelector',
  loadReportingGroups: 'loadReportingGroups',
  AchievementEditor: 'AchievementEditor',
  UserDashboard: 'UserDashboard',
  AchievementCard: 'AchievementCard',
  AchievementDetails: 'AchievementDetails',
  ManagerDashboard: 'ManagerDashboard',
  UtilityPanelDashboardPanel: 'UtilityPanelDashboardPanel',
  ReportingDashboard: 'ReportingDashboard'
};

export default defaultLibraryMap;
