import getGoogleIcons from '../../../selectors/config/fonts/get-google-icons';

const loadGoogleIcons = fonts => {
  const googleIcons = getGoogleIcons(fonts);
  if (googleIcons) {
    const link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute(
      'href',
      `https://fonts.googleapis.com/icon?family=${googleIcons}`
    );
    document.head.appendChild(link);
  }
};

export default loadGoogleIcons;
