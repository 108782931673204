import PropTypes from 'prop-types';
import React from 'react';

const defaultImage =
  'https://s3.amazonaws.com/s3.icom.productions/assets/joeys/toast.png';
const noOp = () => {};

const AchievementToast = ({ toastMessage, description, image, onClick }) => {
  return (
    <div className="toast-content" onClick={onClick}>
      <div
        className="toast-achievement-image"
        style={{
          background: `url(${
            image == null ? defaultImage : image
          }) no-repeat center / contain`,
        }}
      />
      <div className="badge-notification-content">
        <div className="notification-title">{toastMessage}</div>
        <div className="notification-message">{description}</div>
      </div>
    </div>
  );
};

AchievementToast.propTypes = {
  toastMessage: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  onClick: PropTypes.func,
};

AchievementToast.defaultProps = {
  image: defaultImage,
  description: '',
  onClick: noOp(),
};

export default AchievementToast;
