export default {
  organization_config: '/api/organization/config',
  organization_tracking_ids: '/api/organization/t_ids',
  organization_tracking_id: '/api/organization/t_id',
  organization_settings: '/api/organization/settings',
  organization_pages: '/api/organization/pages',
  organization_sections: '/api/organization/sections',
  organization_course_limit: '/api/organization/course_limit',
  organization_users: '/api/organization/users',
  organization_set_user_permission: '/api/organization/set_user_permission',
  organization_remove_user: '/api/organization/remove_user',
  organization_reset_password: '/api/organization/reset_password',
};
