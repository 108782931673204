/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import clsx from 'clsx';

const StyledTemplate = (theme) => ({
  position: 'fixed',
  top: '0',
  left: '0',
  bottom: '0',
  right: '0',
  zIndex: '18',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',
  '.popup':{
    position: 'relative',
    width: 'fit-content',
    margin: '6rem auto',
    '> .content': {
      width: 'fit-content',
      height: 'fit-content',
      background: 'white',
      boxShadow: '0 0.125rem 1.625rem 0 rgb(0 0 0 / 40%)',
      borderRadius: '0.5rem',
      position: 'relative',
      boxSizing: 'border-box',
      zIndex: '1',
      maxWidth: 'calc(100vw - 1rem)'
    },
    '> .content.primary':{
      border: '3px solid ' + theme.primaryColor,
    },
    '> .content.secondary':{
      border: '3px solid #FFCF46',
    },
    '> .content.ok':{
      border: '3px solid #C4E153',
    },
    '> .content.error':{
      border: '3px solid #FE5107',
    },
    '.modal-flare': {
      height: '1rem',
      width: '90%',
      position: 'absolute',
      bottom: '-1rem',
      left: '0rem',
      margin: '0 5%',
      borderRadius: '0 0 0.5rem 0.5rem',
    },
    '.modal-flare.primary':{
      background: theme.primaryColor,
    },
    '.modal-flare.secondary':{
      background: '#FFCF46',
    },
    '.modal-flare.ok':{
      background: '#C4E153'
    },
    '.modal-flare.error':{
      background: '#FE5107',
    },
  }
});

const BreazeModal = ({type = "primary", children}) => {
  return (
    <div css={StyledTemplate} className={'dark-back'}>
      <div className={'popup'}>
        <div className={clsx('content', type)}>
          {children}
        </div>
        <div className={clsx('modal-flare', type)} />
      </div>
    </div>
  );
};

BreazeModal.propTypes = {
  type: PropTypes.string,
  children: PropTypes.object.isRequired
};

export default BreazeModal;
