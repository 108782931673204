import React from 'react';
import useLoginToken from '../../hooks/useLoginToken';

const LogoutEmpty = ({}) => {
  const [, setToken] = useLoginToken();
  setToken();
  return null;
};

export default LogoutEmpty;
