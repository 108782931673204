import ErrorUnexpectedHTTPResponseWrapper from '../containers/dialog/errors/ErrorUnexpectedHTTPResponseWrapper';
import ErrorUnauthorizedHTTPRequestWrapper from '../containers/dialog/errors/ErrorUnauthorizedHTTPRequestWrapper';
import addModal from '../actions/modal/add-modal';

const errorDialog = status => dispatch => {
  if (!(status === 404)) {
    if (status === 401) {
      dispatch(addModal(ErrorUnauthorizedHTTPRequestWrapper));
    } else {
      dispatch(addModal(ErrorUnexpectedHTTPResponseWrapper));
    }
  }
};

export default errorDialog;
