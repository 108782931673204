import React from 'react';
import DialogWrapper from '../DialogWrapper';
import { useDefaultStrings } from '../../../providers/DefaultStrings';
import useLoginToken from '../../../hooks/useLoginToken';

const ErrorUnauthorizedHTTPRequest = props => {
  const { error, ok, pleaseLoginToContinue } = useDefaultStrings();
  const [, setToken] = useLoginToken();
  const logout = () => setToken();
  return (
    <DialogWrapper
      {...props}
      header={error}
      body={pleaseLoginToContinue}
      accept={ok}
      acceptCallback={logout}
    />
  );
};

export default ErrorUnauthorizedHTTPRequest;
