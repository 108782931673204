/** @jsxImportSource @emotion/react */
import { useHistory } from 'react-router-dom';
import SecondaryButton from '../assets/buttons/SecondaryButton';

const StyledSuccessfulReset = {
  display: 'flex',
  flexDirection: 'column',
  '.message':{
    textAlign: 'center',
    fontSize: '1rem',
    padding: '1rem 0',
    color: 'grey'
  },
  '.secondary-button':{
    margin: 'auto'
  }
};

const SuccessfulReset = ({}) => {
  const history = useHistory();

  const onClick = () => {
    history.push('/login');
  }

  return (
    <div css={StyledSuccessfulReset} className={'successful-reset'}>
      <div className={'message'}>Password successfully reset</div>
      <SecondaryButton onClick={onClick} text={'Return to Login'} />
    </div>
  );
};

SuccessfulReset.propTypes = {};

export default SuccessfulReset;