const utilityPanels = {
  UtilityPanel: () =>
    import(
      /* webpackChunkName: "utility-panel" */
      '../../components/utility-panel/UtilityPanelContent'
    ),
  UtilityPanelEditorPanel: () =>
    import(
      /* webpackChunkName: "utility-panel-editor-panel" */
      '../../components/utility-panel/editor-panel/EditorPanel'
    ),
  UtilityPanelManagementPanel: () =>
    import(
      /* webpackChunkName: "utility-panel-management-panel" */
      '../../components/utility-panel/management-panel/ManagementPanel'
    ),
  UtilityPanelDashboardPanel: () =>
    import(
      /* webpackChunkName: "utility-panel-management-panel" */
      '../../components/utility-panel/dashboard-panel/DashboardPanel'
    ),
};

export default utilityPanels;
