import isUUID from '../is-uuid';
import getChildPages from '../guidebook/section/get-child-pages';
import getPages from '../guidebook/pages/get-page-nodes-values';

const getPageId = (state, sectionId, parameter) => {
  const childPages = getChildPages(state, sectionId);
  const matchedPages = getPages(state).filter(
    ({ id, slug }) => slug === parameter && childPages.includes(id)
  );
  if (parameter && matchedPages.length > 0 && !isUUID(parameter)) {
    return matchedPages[0].id;
  }
  return parameter;
};

export default getPageId;
