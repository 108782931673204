/** @jsxImportSource @emotion/react */
import GetBreazeLinks from './GetBreazeLinks';
import BreazeLogo from './BreazeLogo';

const StyledTopBar = {
  width: '100%',
  display: 'flex',
  '.breaze-logo': {
    marginLeft: '2rem',
    margin: 'auto',
    backgroundPosition: '50% 50%',
    height: '2rem'
  },
  '.register-btn, .already': {
    marginLeft: 'auto',
    marginRight: '2rem',
    display: 'flex',
    '.btn-description': {
      fontWeight: '600',
      margin: 'auto 0.3rem',
      fontSize: '0.8rem',
    },
  },
  '@media only screen and (max-width: 882px)': {
    display: 'none',
  },
};

const TopBar = () => {
  return (
    <div css={StyledTopBar} className={'top-bar'}>
      <BreazeLogo />
      <GetBreazeLinks />
    </div>
  );
};

export default TopBar;
