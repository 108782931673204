/** @jsxImportSource @emotion/react */
import React from 'react';
import BreazeTextInput from '../assets/Inputs/BreazeTextInput';
import PrimaryButton from '../assets/buttons/PrimaryButton';
import attemptLogin from '../../../api/authentication/attempt-login';
import MaterialSpinner from '../../material/MaterialSpinner';
import useLoginToken from '../../../hooks/useLoginToken';
import history from '../../../lib/history';

const StyledLoginForm = {
  '.input': {
    margin: '1rem 0 0.5rem',
  },
  '.primary-button': {
    fontSize: '0.9rem',
    padding: '0.5rem 1.75rem',
    margin: 'auto',
  },
  '.auth-message': {
    boxSizing: 'border-box',
    height: '2rem',
    color: '#e64c4c',
    textAlign: 'center',
    fontSize: '0.8rem',
    marginTop: '-1rem'
  },
  '.material-spinner': {
    left: 'calc(50% - 2.75rem)',
    top: 'calc(50% - 4.75rem)',
    borderTopColor: 'rgba(215,215,215, 0.5)',
    borderBottomColor: 'rgba(215,215,215, 0.5)',
    borderRightColor: 'rgba(215,215,215, 0.5)',
    borderLeftColor: '#4ac9e3',
  },
};

const LoginForm = () => {
  const [, , setToken] = useLoginToken();
  const [email, setEmail] = React.useState({ value: '', valid: false });
  const [password, setPassword] = React.useState({ value: '', valid: false });
  const [authMessage, setAuthMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const disabled = !(email.valid && password.valid && !isLoading);

  const doLogin = async () => {
    setIsLoading(true);
    setAuthMessage('');
    try {
      const response = await attemptLogin(email.value, password.value);
      setToken(response.data);
      history.push('/change-organization');
    } catch (e) {
      setAuthMessage(e.response.data);
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={e => e.preventDefault()} css={StyledLoginForm}>
      <BreazeTextInput
        label={'Email'}
        pattern={'^.+@.+\\..+$'}
        onChange={setEmail}
        value={email.value}
        helpText={'Please enter a valid email address'}
      />
      <BreazeTextInput
        label={'Password'}
        pattern={'^.{1,}$'}
        onChange={setPassword}
        value={password.value}
        type={'password'}
      />
      <div className={'auth-message'}>{authMessage}</div>
      <MaterialSpinner isLoading={isLoading} />
      <PrimaryButton
        onClick={() => doLogin()}
        text={'Submit'}
        disabled={disabled}
      />
    </form>
  );
};

export default LoginForm;
