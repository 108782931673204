/** @jsxImportSource @emotion/react */

const StyledFooter = {
  marginTop: 'auto',
  color: '#515050',
  width: '100%',
  textAlign: 'center',
  padding: '0 0 0.5rem 0',
};

const Footer = ({}) => {
  const addr = 'https://www.icomproductions.ca/';

  return (
    <div css={StyledFooter} className={'footer'}>
      {'Breaze is a product developed by '}
      <a target={"_blank"} href={addr}>{'ICOM Productions'}</a>
    </div>
  );
};

export default Footer;
