/** @jsxImportSource @emotion/react */

const StyledPolicy = {
  color: '#acacac',
  fontSize: '0.8rem',
  width: '23rem',

  margin: '3rem auto auto auto',
  '@media only screen and (max-width: 768px)': {
    width: '75%',
  },
};

const Policy = () => {
  const bySubmitting = 'By submitting this form you are accepting our ';
  const termsAddr = 'https://www.getbreaze.com/legal';
  const terms = 'terms of use';
  const and = ' and our ';
  const privacy = 'privacy policy';
  const policyAddr = 'https://www.getbreaze.com/legal';

  return (
    <div className={'policy'} css={StyledPolicy}>
      {bySubmitting}
      <a target={'_blank'} href={termsAddr}>{terms}</a>
      {and}
      <a target={'_blank'} href={policyAddr}>{privacy}</a>
    </div>
  );
};

export default Policy;
