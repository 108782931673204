import PropTypes from 'prop-types';
import React from 'react';
import { cx } from '@emotion/css';
import StyledMaterialSpinner from './StyledMaterialSpinner';

const getClassName = isLoading => cx('material-spinner', { isLoading });

const MaterialSpinner = ({ isLoading }) => (
  <StyledMaterialSpinner className={getClassName(isLoading)} />
);

MaterialSpinner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default MaterialSpinner;
