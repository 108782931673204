/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import React from 'react';
import BreazeLogo from '../../assets/BreazeLogo';
import PrimaryButton from '../../assets/buttons/PrimaryButton';
import OrganizationSetupPanel from '../OrganizationSetupPanel';
import UserSetupPanel from './UserSetupPanel';
import MaterialSpinner from '../../../material/MaterialSpinner';
import Create from '../../../../api/authentication/create';
import useLoginToken from '../../../../hooks/useLoginToken';

const StyledRegistrationForm = settled => ({
  opacity: settled ? '1' : '0',
  margin: 'auto',
  maxWidth: '30rem',
  transition: 'all 0.4s ease-in',
  '.info': {
    paddingTop: '0.5rem',
    color: '#acacac',
    fontSize: '0.6rem',
    textAlign: 'center',
  },
  '.top': {
    borderBottom: '0.15rem solid #4ac9e3',
  },
  '.box': {
    padding: '0.5rem',
    marginBottom: '1rem',
    h1: {
      fontSize: '1rem',
      padding: '0',
      margin: '0',
      color: '#4ac9e3',
    },
    '.message': {
      color: '#acacac',
      margin: '0.5rem 0',
    },
  },
  form: {
    margin: '1rem 0 0 0',
    '.input': {
      margin: '0.5rem 0',
    },
  },
  '.breaze-logo': {
    margin: 'auto',
    width: '13rem',
    height: '3rem',
  },
  '.primary-button': {
    fontSize: '0.9rem',
    padding: '0.5rem 1.75rem',
    margin: 'auto',
  },
  '.material-spinner': {
    left: 'calc(50% - 2.75rem)',
    top: 'calc(50% - 4.75rem)',
    borderTopColor: 'rgba(215,215,215, 0.5)',
    borderBottomColor: 'rgba(215,215,215, 0.5)',
    borderRightColor: 'rgba(215,215,215, 0.5)',
    borderLeftColor: '#4ac9e3',
  },
  '.auth-message': {
    boxSizing: 'border-box',
    height: '2rem',
    color: '#e64c4c',
    textAlign: 'center',
    fontSize: '0.8rem',
  },
  '@media only screen and (max-width: 768px)': {
    padding: '1rem',
  },
});

const CreateUserAndOrganizationBody = ({ email, token }) => {
  const [, setToken] = useLoginToken();
  const defaultEmptyInput = { value: '', valid: false };
  const [settled, setSettled] = React.useState(false);
  const [industry, setIndustry] = React.useState(defaultEmptyInput);
  const [company, setCompany] = React.useState(defaultEmptyInput);
  const [siteUsername, setSiteUsername] = React.useState(defaultEmptyInput);
  const [fullName, setFullName] = React.useState(defaultEmptyInput);
  const [password, setPassword] = React.useState(defaultEmptyInput);
  const [confirmPassword, setConfirmPassword] = React.useState({
    value: '',
    valid: false,
  });
  const [newsletter, setNewsletter] = React.useState({
    value: false,
    valid: false,
  });
  const [tos, setTos] = React.useState({ value: false, valid: false });
  const [loading, setLoading] = React.useState(false);
  const [authMessage, setAuthMessage] = React.useState('');
  const disabled = !(
    fullName.valid &&
    password.valid &&
    confirmPassword.valid &&
    industry.valid &&
    company.valid &&
    siteUsername.valid &&
    newsletter.valid &&
    tos.valid &&
    !loading
  );

  React.useEffect(() => {
    setTimeout(() => {
      setSettled(true);
    }, 3);
  });

  const onSubmit = async () => {
    if (password.value !== confirmPassword.value) {
      setAuthMessage('Password and Confirm Password fields must match');
    } else {
      setLoading(true);
      setAuthMessage('');
      try {
        const response = await Create(
          token,
          fullName.value,
          password.value,
          tos.value,
          newsletter.value,
          company.value,
          industry.value,
          siteUsername.value
        );
        setToken(response.data);
        window.location.href = '/';
      } catch (e) {
        setAuthMessage(e.response.data);
      }
    }
    setLoading(false);
  };

  return (
    <div
      css={StyledRegistrationForm(settled)}
      className={'organization-setup-body'}
    >
      <BreazeLogo />
      <form onSubmit={e => e.preventDefault()}>
        <UserSetupPanel
          email={email}
          fullName={fullName}
          setFullName={setFullName}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          newsletter={newsletter}
          setNewsletter={setNewsletter}
          tos={tos}
          setTos={setTos}
        />
        <OrganizationSetupPanel
          company={company}
          setCompany={setCompany}
          industry={industry}
          setIndustry={setIndustry}
          siteUsername={siteUsername}
          setSiteUsername={setSiteUsername}
        />
        <div className={'auth-message'}>{authMessage}</div>
        <PrimaryButton
          text={'Submit'}
          onClick={() => onSubmit()}
          disabled={disabled}
        />
        <MaterialSpinner isLoading={loading} />
      </form>
    </div>
  );
};

CreateUserAndOrganizationBody.propTypes = {
  email: PropTypes.string.isRequired,
  jwt: PropTypes.string.isRequired,
};

export default CreateUserAndOrganizationBody;
