/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

const StyledSecondary = {
  display: 'flex',
  button: {
    border: 'none',
    background: '#f7ea48',
    fontWeight: '600',
    borderRadius: '0.5rem',
    padding: '0.3rem 0.75rem',
    height: 'fit-content',
    margin: 'auto 0.3rem',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      background: '#D9D6D4',
    },
  },
};

const SecondaryButton = ({ text, onClick }) => {
  return (
    <div className={'secondary-button'} css={StyledSecondary}>
      <button onClick={onClick}>{text}</button>
    </div>
  );
};

SecondaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SecondaryButton;
