import urls from '../../config/urls';

const sendCreateEmail = (email, captchaToken) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('captcha-token', captchaToken);

  return fetch(urls.registration_sendCreateEmail, {
    method: 'POST',
    body: formData,
  });
};

export default sendCreateEmail;
