import isLoadingWrapper from '../load/is-loading-wrapper';
import loadCourses from '../../api/courses/load';
import getGuidebooks from '../../selectors/guidebooks/get-guidebooks';
import getRootId from '../../selectors/view/player/get-root-id';

const reloadGuidebooksIfNeeded = state => dispatch =>
  new Promise(resolve => {
    const guidebooks = getGuidebooks(state);
    const rootId = getRootId(state);
    if (rootId !== undefined) {
      return dispatch(isLoadingWrapper(dispatch(loadCourses()))).then(data =>
        resolve(data)
      );
    }
    return resolve(guidebooks);
  });

export default reloadGuidebooksIfNeeded;
