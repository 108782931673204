const Divider = {
  type: 'Divider',
  language: null,
  tags: ['other'],
  image: 'https://breas3.com/item-images-v0/divider.jpg',
  rolloverImage:
    'https://breas3.com/item-images-v0/divider_rollover.jpg',
  description: 'Visual divider that spans across the width of the page',
  helpExtension: 'divider',
  content: {
    dividerBarBackgroundColor: null,
    dividerBackgroundColor: null,
    dividerHeight: 10,
    dividerArrowAlignment: 'center',
    dividerArrowAlignmentPercentage: 0,
    dividerArrowHeight: 20
  },
};

export default Divider;
