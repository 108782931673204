import reduxEvents from './redux-events';

const add = (state, { id, ModalComponent, props }) => [
  ...state,
  { id, ModalComponent, props },
];

const remove = state => state.slice(0, -1);

const modalsEvents = reduxEvents()
  .addEvent('MODALS', 'ADD', add)
  .addEvent('MODALS', 'REMOVE', remove);

const modals = modalsEvents.getReducer([]);

const modalsActionCreator = modalsEvents.getActionCreators();

export { modals, modalsActionCreator };
