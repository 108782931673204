import reduxEvents from '../redux-events';
import setGuidebooks from '../../lib/immutable-array-helpers/set';

const set = (_, guidebooks) => guidebooks;

const setGuidebookSlug = (prevGuidebooks, { id, slug }) => {
  const index = prevGuidebooks.findIndex(
    ({ id: guidebookId }) => id === guidebookId
  );
  const prevGuidebook = prevGuidebooks[index];
  const guidebook = { ...prevGuidebook, slug };
  return setGuidebooks(index, prevGuidebooks, guidebook);
};

const guidebooksEvents = reduxEvents()
  .addEvent('GUIDEBOOKS', 'SET', set)
  .addEvent('GUIDEBOOKS', 'SET_GUIDEBOOK_SLUG', setGuidebookSlug);

const guidebooks = guidebooksEvents.getReducer([]);
const guidebooksActionCreator = guidebooksEvents.getActionCreators();

export { guidebooks, guidebooksActionCreator };
