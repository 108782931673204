import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { render } from 'react-dom';
import ProvidedApp from './components/ProvidedApp';
import './legacy-less.css';
import 'details-element-polyfill';

const rootElement = document.getElementById('root');

render(<ProvidedApp />, rootElement);
