import React from 'react';
import NavigationErrorDialogWrapper from './NavigationErrorDialogWrapper';
import getReturnPath from './get-return-path';
import { useDefaultStrings } from '../../../providers/DefaultStrings';

const ErrorAccessEntrySectionDialog = props => {
  const {
    error,
    ok,
    doNotHaveAccessToEntrySectionOrDoesNotExist,
  } = useDefaultStrings();
  return (
    <NavigationErrorDialogWrapper
      {...props}
      header={error}
      body={`${doNotHaveAccessToEntrySectionOrDoesNotExist}.`}
      accept={ok}
      historyCallback={getReturnPath('/')}
    />
  );
};

export default ErrorAccessEntrySectionDialog;
