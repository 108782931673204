/** @jsxImportSource @emotion/react */
import BreazeLogo from '../authentication/assets/BreazeLogo';
import React from 'react';
import OrganizationList from './OrganizationList';
import PrimaryButton from '../authentication/assets/buttons/PrimaryButton';
import useAxios from '../../hooks/useAxios';
import useLoginToken from '../../hooks/useLoginToken';
import urls from '../../config/urls';
import Loading from '../wrappers/Loading';
import history from '../../lib/history';
import Legend from './Legend';

const StyledChangeOrganization = theme => ({
  marginTop: theme.topNavigationBarHeight,
  background: theme.breazeNavy,
  minWidth: '100vw',
  minHeight: 'calc(100vh - ' + theme.topNavigationBarHeight + ')',
  display: 'flex',
  flexDirection: 'column',
  '.change-organization': {
    background: '#fff',
    width: '20rem',
    maxWidth: '100%',
    margin: 'auto',
    border: '0.2rem solid ' + theme.primaryColor,
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem',
    '.breaze-logo': {
      margin: 'auto',
      width: '10rem',
      padding: '0.5rem',
      height: '2.5rem',
    },
    '.message': {
      textAlign: 'center',
      fontSize: '0.7rem',
      padding: '0.3rem 1rem',
      color: 'grey',
    },
    '.primary-button': {
      margin: '0.5rem 1rem',
    },
  },
});

const ChangeOrganization = () => {
  const [, setLoginToken] = useLoginToken();
  const [{ data: organizations, loading, error }] = useAxios(
    urls.user_organizations
  );
  const [, execute] = useAxios(urls.auth_changeOrganization, { manual: true });
  const [selected, setSelected] = React.useState(null);

  React.useEffect(() => {
    if(!loading && organizations.length === 1){
      history.push('/');
    }
  }, [organizations]);

  const onClick = async () => {
    const response = await execute({
      method: 'post',
      params: { organizationId: selected },
    });
    history.push('/');
    setLoginToken(response.data);
  };

  if (loading) return <Loading isLoading={true} component={() => {}} />;
  if (error) return `Something went wrong: ${error.message}`;
  else
    return (
      <div css={StyledChangeOrganization} className={'wrapper'}>
        <div className={'change-organization'}>
          <BreazeLogo />
          <div className={'message'}>
            {
              'It looks like you are part of more than one Breaze team, select the team you would like to be logged into'
            }
          </div>
          <OrganizationList
            organizations={organizations}
            selected={selected}
            setSelected={setSelected}
          />
          <Legend />
          <PrimaryButton
            onClick={onClick}
            text={'Select'}
            disabled={selected === null}
          />
        </div>
      </div>
    );
};

export default ChangeOrganization;
