export default {
  registration_validateJoin: '/api/registration/validate_join',
  registration_validateCreate: '/api/registration/validate_create',
  registration_sendCreateEmail: '/api/registration/send_create_email',
  registration_create: '/api/registration/create',
  registration_sendJoinEmail: '/api/registration/send_join_email',
  registration_join: '/api/registration/join',
  registration_validatePasswordReset: '/api/registration/validate_password_reset',
  registration_sendPasswordResetEmail: '/api/registration/send_password_reset_email',
  registration_changePassword: '/api/registration/change_password'
};
