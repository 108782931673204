import urls from '../../config/urls';
import axios from 'axios';

const Join = (token, fullName, password, tos_agreed) => {
  return axios.post(urls.registration_join, {
    token,
    display_name: fullName,
    password,
    tos_agreed,
  });
};

export default Join;
