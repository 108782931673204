/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import BreazeTextInput from '../../assets/Inputs/BreazeTextInput';
import React from 'react';
import BreazeCheckbox from '../../assets/Inputs/BreazeCheckbox';

const LimitedUserSetupPanel = ({
  password,
  setPassword,
  newsletter,
  setNewsletter,
  email,
}) => {
  return (
    <div className={'box top'}>
      <h1>{'User Account Setup'}</h1>
      <div className={'message'}>
        {
          'This information will be used to link your account to this newly created organization'
        }
      </div>
      <BreazeTextInput
        label={'Email'}
        type={'text'}
        value={email}
        disabled={true}
      />
      <BreazeTextInput
        label={'Password'}
        helpText={'Password must have a length of 8'}
        pattern={'^.{8,}$'}
        value={password.value}
        onChange={setPassword}
        type={'password'}
      />
      <BreazeCheckbox
        text={'Opt in to newsletter'}
        value={newsletter.value}
        onChange={setNewsletter}
        mustBeTrue={true}
      />
    </div>
  );
};

LimitedUserSetupPanel.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.object.isRequired,
  setPassword: PropTypes.func.isRequired,
  newsletter: PropTypes.object.isRequired,
  setNewsletter: PropTypes.func.isRequired,
};

export default LimitedUserSetupPanel;
