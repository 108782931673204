import { REPORTING_DASHBOARD } from '../../../config/constants';
import getContext from '../../../selectors/view/get-context';
import loadReportingDashboard from '../../reporting-dashboard/load-reporting-dashboard';

const setReportingDashboard = () => (dispatch, getState) => {
  const state = getState();
  const context = getContext(state);
  if (context !== REPORTING_DASHBOARD) {
    dispatch(loadReportingDashboard());
  }
};

export default setReportingDashboard;
