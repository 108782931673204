/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import BreazeTextInput from '../assets/Inputs/BreazeTextInput';
import BreazeCheckbox from '../assets/Inputs/BreazeCheckbox';
import PrimaryButton from '../assets/buttons/PrimaryButton';
import MaterialSpinner from '../../material/MaterialSpinner';
import React from 'react';
import Join from '../../../api/authentication/join';
import useLoginToken from '../../../hooks/useLoginToken';

const StyledCreateUserForm = {
  margin: '1rem 0 0 0',
  '.description':{
    color: 'grey',
    textAlign: 'center',
    fontSize: '0.9rem',
    paddingBottom: '1rem'
  },
  '.input': {
    margin: '0.5rem 0',
  },
  '.primary-button':{
    width: '80%',
    margin: '0.5rem auto',
    paddingTop: '0.5rem'
  }
};

const CreateUserForm = ({ email, token }) => {
  const [, setToken] = useLoginToken();
  const [fullName, setFullName] = React.useState({ value: '', valid: false });
  const [password, setPassword] = React.useState({ value: '', valid: false });
  const [confirmPassword, setConfirmPassword] = React.useState({
    value: '',
    valid: false,
  });
  const [tos, setTos] = React.useState({ value: false, valid: false });
  const [loading, setLoading] = React.useState(false);
  const [authMessage, setAuthMessage] = React.useState('');
  const disabled = !(
    fullName.valid &&
    password.valid &&
    confirmPassword.valid &&
    tos.valid &&
    !loading
  );

  const onSubmit = async () => {
    if (password.value !== confirmPassword.value) {
      setAuthMessage('Password and Confirm Password fields must match');
    } else {
      setLoading(true);
      setAuthMessage('');
      try {
        const response = await Join(
          token,
          fullName.value,
          password.value,
          tos.value
        );
        setToken(response.data);
        window.location.href = '/';
      } catch (e) {
        setAuthMessage(e?.response?.data);
      }
      setLoading(false);
    }
  };

  return (
    <form onSubmit={e => e.preventDefault()} css={StyledCreateUserForm}>
      <div className={'description'}>
        Fill out the following details to join the intended organization
      </div>
      <BreazeTextInput
        label={'Email'}
        type={'text'}
        value={email}
        disabled={true}
      />
      <BreazeTextInput
        label={'Full Name'}
        pattern={'^.{2,}$'}
        value={fullName.value}
        onChange={setFullName}
      />
      <BreazeTextInput
        label={'Password'}
        helpText={'Password must have a length of 8'}
        pattern={'^.{8,}$'}
        value={password.value}
        onChange={setPassword}
        type={'password'}
      />
      <BreazeTextInput
        label={'Confirm Password'}
        pattern={'^.{8,}$'}
        value={confirmPassword.value}
        onChange={setConfirmPassword}
        type={'password'}
      />
      <BreazeCheckbox
        text={'Agree to '}
        linkText={'terms of service'}
        link={'https://www.icomproductions.ca/'}
        value={tos.value}
        onChange={setTos}
        mustBeTrue={true}
      />
      <div className={'auth-message'}>{authMessage}</div>
      <PrimaryButton
        text={'Submit'}
        onClick={() => onSubmit()}
        disabled={disabled}
      />
      <MaterialSpinner isLoading={loading} />
    </form>
  );
};

CreateUserForm.propTypes = {
  email: PropTypes.string.isRequired,
  jwt: PropTypes.string.isRequired,
};

export default CreateUserForm;
