import React from 'react';
import DialogWrapper from '../DialogWrapper';
import { useDefaultStrings } from '../../../providers/DefaultStrings';
import useLoginToken from '../../../hooks/useLoginToken';

const ErrorUnexpectedHTTPResponse = props => {
  const { error, ok, unexpectedHTTPError, cancel } = useDefaultStrings();
  const [, setToken] = useLoginToken();
  const logout = () => setToken();
  return (
    <DialogWrapper
      {...props}
      header={error}
      body={`${unexpectedHTTPError}?`}
      reject={cancel}
      accept={ok}
      acceptCallback={logout}
    />
  );
};

export default ErrorUnexpectedHTTPResponse;
