/** @jsxImportSource @emotion/react */
import SecondaryButton from '../assets/buttons/SecondaryButton';
import { useHistory } from 'react-router-dom';

const StyledRegistrationInfo = {
  fontSize: '0.75rem',
  margin: '3rem 0 1rem 0',
  textAlign: 'center',
  width: '100%',
  '.pass':{
    paddingBottom: '1rem',
  },
  '.message': {
    margin: '0 0.5rem',
  },
  '.secondary-button': {
    margin: '1rem auto',
    fontSize: '0.7rem',
    button: {
      margin: 'auto',
      width: '60%',
    },
  },
};

const RegistrationInfo = () => {
  const history = useHistory();
  return (
    <div css={StyledRegistrationInfo} className={'registration-info'}>
      <div>
        <div className={'message'}>{'Want to create your own course?'}</div>
        <SecondaryButton
          text={'Register Now'}
          onClick={() => history.push('/register')}
        />
        <div className={'message'}>{'To create your own team and course!'}</div>
      </div>
    </div>
  );
};

export default RegistrationInfo;
