import getInteractives from './get-interactives';

const getInteractive = (state, item) => {
  const { interactive_id = null } = item;
  const interactives = getInteractives(state);
  if (interactives.has(interactive_id)) {
    return interactives.get(interactive_id);
  }
  return undefined;
};

export default getInteractive;
