export const EDITOR = 'editor';
export const USER_MANAGEMENT = 'user-management';
export const INTERACTIVE = 'Interactive';
export const TRACKING_IDS = 'tracking-ids';
export const TRACKING_ID = 'tracking-id';
export const TRACKING_ID_STATE = 'tracking-id-state';
export const INTERACTIVES = 'interactives';
export const REQUIRED = 'required';
export const LOCATION = 'LOCATION';
export const SESSION_ID = 'SESSION_ID';
export const GRADE = 'GRADE';
export const CREATE_PACKAGE = 'create package';
export const UPDATE_PACKAGE = 'update package';
export const USER = 'user';
export const SECTION = 'section';
export const PAGE = 'page';
export const RATING = 'Rating';
export const POLL = 'Poll';
export const MULTIPLECHOICEQUESTION = 'MultipleChoiceQuestion';
export const MULTIPLESELECTQUESTION = 'MultipleSelectQuestion';
export const USER_COMPLETION = 'user-completion';
export const INTERACTIVE_GROUP = 'interactive-group';
export const INTERACTIVE_IFRAME = 'InteractiveIframe';
export const ACHIEVEMENT_EDITOR = 'achievement-editor';
export const FILTER_NULLABLE = 1;
export const FILTER_EQUAL_TO = 2;
export const FILTER_NOT_EQUAL_TO = 4;
export const FILTER_RANGE = 8;
export const FILTER_GREATER_THAN = 16;
export const FILTER_LESS_THAN = 32;
export const FILTER_CONTAINS = 64;
export const FILTER_BEGINS_WITH = 128;
export const FILTER_ENDS_WITH = 256;
export const FILTER_MATCHES_SET = 512;
export const FILTER_DATE_EQUALS = 1024;
export const FILTER_DATE_RANGE = 2048;
export const FILTER_BEFORE_DATE = 4096;
export const FILTER_AFTER_DATE = 8192;
export const FILTER_TIME_RANGE = 16384;
export const FILTER_CUSTOM = 32768;
export const AUTHENTICATION = 'authentication';
export const CHANGE_ORGANIZATION = 'change_organization';
export const CAN_USE_EDITOR = 'can_use_editor';
export const CAN_UPLOAD_MEDIA = 'can_upload_media';
export const CAN_RUN_REPORTS = 'can_run_reports';
export const WRITTEN_RESPONSE = 'WrittenResponse';
export const RECAPTCHA_SITE_KEY = '6LcXcZgaAAAAAGs8vE3AQPwgB6c3TiMkJxqm27lt';
export const ALGOLIA_APP_ID = 'VTU72XVIB9';
export const ACHIEVEMENTS_DASHBOARD = 'achievements-dashboard';
export const REPORTING_DASHBOARD = 'reporting-dashboard'
