const TripleItem = {
  type: 'TripleItem',
  tags: [],
  image:
    'https://breas3.com/item-images-v0/imagelistwithimagelistwithimagelist.jpg',
  rolloverImage:
    'https://breas3.com/item-images-v0/triplepageitem_rollover.jpg',
  description: 'Three separate items that span the width the width of larger screens and stack on smaller screens',
  enabledByConfig: true,
  subItems: [],
};

export default TripleItem;