import getOtherFonts from '../../../selectors/config/fonts/get-other-fonts';

const loadOtherFonts = fonts => {
  const otherFonts = getOtherFonts(fonts);
  if (Array.isArray(otherFonts) && otherFonts.length > 0) {
    otherFonts.forEach(href => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = href;
      document.head.appendChild(link);
    });
  }
};

export default loadOtherFonts;
