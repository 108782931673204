/** @jsxImportSource @emotion/react */
import MaterialSpinner from '../../material/MaterialSpinner';
import React from 'react';
import PropTypes from 'prop-types';
import CreateUserForm from './CreateUserForm';
import JoinOrganizationForm from './JoinOrganizationForm';

const Form = ({ userExists, loading, ...props }) => {
  return loading ? (
    <MaterialSpinner isLoading={true} />
  ) : userExists ? (
    <JoinOrganizationForm {...props} />
  ) : (
    <CreateUserForm {...props} />
  );
};

Form.propTypes = {
  userExists: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Form;
