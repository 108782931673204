export default {
  courses_assigned: '/api/courses/assigned',
  courses_load: '/api/courses/load',
  courses_publish: '/api/courses/publish',
  create_new: '/api/courses/create_new',
  courses_delete: '/api/courses/rm',
  courses_all: '/api/courses/all',
  courses_everyone_assigned: '/api/courses/everyone_assigned',
  courses_assign_everyone: '/api/courses/assign_everyone',
  courses_unassigned_everyone: '/api/courses/unassign_everyone',
};
