import { RECAPTCHA_SITE_KEY } from '../../config/constants';

export const loadRecaptchaScript = () => {
  const id = 'breaze-recaptcha-key';
  const isScriptExist = document.getElementById(id);
  const url = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;

  if (!isScriptExist) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.id = id;
    document.body.appendChild(script);
  }
};

export const getRecaptchaToken = action =>
  new Promise(resolve => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(RECAPTCHA_SITE_KEY, { action: action })
        .then(token => {
          resolve(token);
        });
    });
  });
