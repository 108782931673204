import React, { useState, useLayoutEffect } from 'react';
import { cx } from '@emotion/css';
import CSSTransition from 'react-transition-group/CSSTransition';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import { useDispatch, useStore } from '../../providers/Store';
import modalIsOpen from '../../selectors/view/modal-is-open';
import getModals from '../../selectors/modals/get-modals';
import toggleOffModal from '../../actions/toggle-off-modal';
import closeCurrentOverlay from '../../actions/modal/close-current-overlay';

const Modals = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const [modalsData, setModalsData] = useState();
  const open = modalIsOpen(store);
  const modals = getModals(store);
  if (modals.length > 32) {
    debugger;
  }
  const toggleOff = () => dispatch(toggleOffModal());
  const classNames = cx('global-modal', { open });
  const close = e => {
    if (e && e.keyCode === 27) {
      dispatch(closeCurrentOverlay());
    }
  };
  useLayoutEffect(() => {
    document.addEventListener('keydown', close);
    return () => document.removeEventListener('keydown', close);
  }, []);
  return (
    <div className={classNames}>
      <TransitionGroup>
        {modals.map((modal, index) => {
          const { ModalComponent } = modal;
          return (
            <CSSTransition
              key={modal.id}
              classNames="pop-in"
              timeout={{ enter: 500, exit: 300 }}
            >
              <ModalComponent
                key={modal.id}
                modalsData={modalsData}
                setModalsData={setModalsData}
                toggleOffModal={toggleOff}
                isTopModal={index + 1 === modals.length}
                {...modal.props}
              />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
};

export default Modals;
