import xr from '../../lib/xr/xr-wrapper';
import urls from '../../config/urls';
import { trackingIdsActionCreator } from '../../events/tracking-ids';

const { setTrackingIds } = trackingIdsActionCreator;

const loadTrackingIds = () => dispatch =>
  xr.get(urls.organization_tracking_ids).then(({ data }) => {
    dispatch(setTrackingIds(data));
    return data;
  });

export default loadTrackingIds;
