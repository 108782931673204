import getGuidebooks from '../../../selectors/history/get-guidebooks';
import getRootId from '../../../selectors/history/get-root-id';
import getStateRootId from '../../../selectors/view/player/get-root-id';
import getTopicsSections from '../../../selectors/history/get-topics-sections';
import addModal from '../../modal/add-modal';
import getGuidebook from '../../../selectors/history/get-guidebook';
import getSectionId from '../../../selectors/history/get-section-id';
import getStateSectionId from '../../../selectors/view/player/get-section-id';
import sectionExists from '../../../selectors/guidebook/section/section-exists';
import sectionLocked from '../../../selectors/guidebook/section/section-locked';
import getStatePageId from '../../../selectors/view/player/get-page-id';
import getPageId from '../../../selectors/history/get-page-id';
import getTopicId from '../../../selectors/view/player/get-topic-id';
import pageExists from '../../../selectors/guidebook/page/page-exists';
import pageLocked from '../../../selectors/guidebook/page/page-locked';
import ErrorAccessEntrySectionDialog from '../../../components/dialog/errors/ErrorAccessEntrySectionDialog';
import ErrorAccessLockedSectionDialog from '../../../components/dialog/errors/ErrorAccessLockedSectionDialog';
import ErrorAccessPageDialog from '../../../components/dialog/errors/ErrorAccessPageDialog';
import ErrorAccessLockedPageDialog from '../../../components/dialog/errors/ErrorAccessLockedPageDialog';
import ErrorAccessSectionDialog from '../../../components/dialog/errors/ErrorAccessSectionDialog';
import { playerViewActionCreator } from '../../../events/view/player-view';
import inGuidebookContext from '../../../selectors/view/in-guidebook-context';
import setContext from '../../view/set-context';

const { set: setPlayerView } = playerViewActionCreator;

const getPlayerViewParameters = (
  state,
  parameters,
  config
) => async dispatch => {
  const rootParameter = parameters[1].split('.')[1];
  const sectionParameter = parameters[2]
    ? parameters[2].split('.')[1]
    : undefined;
  const pageParameter = parameters[3] ? parameters[3].split('.')[1] : undefined;
  const prevRootId = getStateRootId(state);
  const rootId = getRootId(state, rootParameter);
  const guidebooks = await dispatch(getGuidebooks(state, prevRootId, rootId));
  const topicSections = getTopicsSections({ player: { guidebooks } });
  if (!topicSections.map(({ id }) => id).includes(rootId)) {
    dispatch(addModal(ErrorAccessEntrySectionDialog));
    return null;
  }
  const guidebook = await dispatch(getGuidebook(state, prevRootId, rootId));
  const updatedState = { ...state, guidebook };
  const prevSectionId = getStateSectionId(updatedState);
  const sectionId = getSectionId(updatedState, sectionParameter);
  const parentId = sectionId || rootId;
  const prevPageId = getStatePageId(updatedState);
  const pageId = getPageId(updatedState, parentId, pageParameter);
  const topicId = getTopicId(updatedState);
  const playerViewHasChanged =
    prevRootId === rootId &&
    prevRootId &&
    prevSectionId === parentId &&
    prevPageId === pageId &&
    topicId === undefined;
  if (playerViewHasChanged) {
    return null;
  }
  const validSectionChange =
    sectionId !== undefined && sectionId !== prevSectionId;
  if (validSectionChange && !sectionExists(updatedState, sectionId)) {
    dispatch(addModal(ErrorAccessSectionDialog, { rootParameter }));
    return null;
  } else if (
    validSectionChange &&
    sectionLocked(updatedState, config, sectionId)
  ) {
    dispatch(addModal(ErrorAccessLockedSectionDialog, { rootParameter }));
    return null;
  }
  const validPageChange = pageId !== undefined && pageId !== prevPageId;
  if (validPageChange && !pageExists(updatedState, pageId)) {
    dispatch(addModal(ErrorAccessPageDialog, { rootParameter }));
    return null;
  } else if (
    validPageChange &&
    pageLocked(updatedState, config, parentId, pageId)
  ) {
    dispatch(addModal(ErrorAccessLockedPageDialog, { rootParameter }));
    return null;
  }
  return { rootId, sectionId: parentId, pageId, topicId: undefined };
};

const setPlayer = (parameters, config) => (dispatch, getState) => {
  const state = getState();
  dispatch(getPlayerViewParameters(state, parameters, config)).then(
    playerViewUpdates => {
      if (playerViewUpdates) {
        dispatch(setPlayerView(playerViewUpdates));
      }
      if (!inGuidebookContext(state)) {
        dispatch(setContext(undefined));
      }
    }
  );
};

export default setPlayer;
