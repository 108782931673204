import urls from '../../config/urls';
import axios from 'axios';

const attemptLogin = (email, password) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('password', password);

  return axios.post(urls.auth_login, formData);
};

export default attemptLogin;
