/** @jsxImportSource @emotion/react */
import SecondaryButton from '../assets/buttons/SecondaryButton';
import { useHistory } from 'react-router-dom';

const StyledForgotPasswordButton = {
  fontSize: '0.75rem',
  margin: '1rem 0 1rem 0',
  textAlign: 'center',
  width: '100%',
  '.secondary-button': {
    margin: '1rem auto',
    fontSize: '0.7rem',
    button: {
      margin: 'auto',
      width: '60%',
    },
  },
};

const ForgotPasswordButton = ({}) => {
  const history = useHistory();

  return (
    <div className={'pass'} css={StyledForgotPasswordButton}>
      <div className={'message'}>{'Forgot your password?'}</div>
      <SecondaryButton onClick={() => history.push('/change-password')} text={"Reset Password"}/>
    </div>
  );
};

export default ForgotPasswordButton;