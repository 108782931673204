import clearPlayer from './clear-player';
import history from '../../../lib/history';
import reloadGuidebooksIfNeeded from '../reload-guidebooks-if-needed';
import hasEditedGuidebook from '../../../selectors/guidebook/has-edited';
import addModal from '../../modal/add-modal';
import getTopics from '../../../selectors/topics/get-topics';
import { REQUIRED } from '../../../config/constants';
import navigateToTopic from '../../navigation/topic/navigate-to-topic';
import ConfirmDialog from '../../../components/modals/dialogs/ConfirmDialog';
import defaultStrings from '../../../lib/i18n/default-strings';

const setGuidebooksAction = state => async dispatch => {
  const guidebooks = await dispatch(reloadGuidebooksIfNeeded(state));
  const topics = getTopics({ player: { guidebooks } });
  if (topics.length === 1 && topics[0].id === REQUIRED) {
    const { id } = topics[0];
    dispatch(navigateToTopic(id));
  } else {
    dispatch(clearPlayer());
  }
};

const setGuidebooks = () => (dispatch, getState) => {
  const state = getState();
  const { destroyUnsavedChanges } = defaultStrings;

  const acceptCallback = () => dispatch(setGuidebooksAction(state));
  if (hasEditedGuidebook(state)) {
    const rejectCallback = () => history.goBack();
    dispatch(
      addModal(ConfirmDialog, {
        type: 'secondary',
        icon: 'warning',
        message: destroyUnsavedChanges,
        acceptButtonCallback: acceptCallback,
        cancelButtonCallback: rejectCallback,
      })
    );
  } else {
    acceptCallback();
  }
};

export default setGuidebooks;
