import pathToRegexp from 'path-to-regexp';
import setPlayer from './player/set-player';
import setUserManagement from './user-management/set-user-management';
import setGuidebooks from './player/set-guidebooks';
import playerPathRegexp from '../../config/player-path-regexp';
import ErrorSelectedLocationDialog from '../../components/dialog/errors/ErrorSelectedLocationDialog';
import addModal from '../modal/add-modal';
import setGoogleAnalyticsHistory from '../../lib/google-analytics/set-google-analytics-history';
import setAchievementEditor from './achievements/set-achievement-editor';
import setAchievementsDashboard from './achievements/set-achievements-dashboard';
import {
  canManageUsers,
  canRunReports,
  canUseEditor,
} from '../../selectors/view/permissions';
import setChangeOrganization from './change-organization/set-change-organization';
import setContext from '../view/set-context';
import { AUTHENTICATION } from '../../config/constants';
import setReportingDashboard from './reporting-dashboard/set-reporting-dashboard';

const matchesPlayer = path => pathToRegexp(playerPathRegexp, []).exec(path);
const matchesUserManagement = path =>
  pathToRegexp('/user-management/', []).exec(path);
const matchesAchievementEditor = path =>
  pathToRegexp('/achievement-editor', []).exec(path);
const matchesUserDashboard = path =>
  pathToRegexp('/achievements-dashboard/([\\d]*)?', []).exec(path);
const matchesChangeOrganization = path =>
  pathToRegexp('/change-organization', []).exec(path);
const matchesReportingDashboard = path =>
  pathToRegexp('/reporting-dashboard', []).exec(path);
const matchesRegister = path => pathToRegexp('/register', []).exec(path);
const matchesLogin = path => pathToRegexp('/login', []).exec(path);
const matchesJoin = path => pathToRegexp('/join', []).exec(path);

const canEnterUserManagement = (path, state) =>
  matchesUserManagement(path) && canManageUsers(state);
const canEnterAchievementEditor = (path, state) =>
  matchesAchievementEditor(path) && canUseEditor(state);
const canEnterUserDashboard = path => matchesUserDashboard(path);
const canEnterChangeOrganization = path => matchesChangeOrganization(path);
const canEnterReportingDashboard = (path, state) =>
  matchesReportingDashboard(path) && canRunReports(state);

const setStateFromPath = (history, config) => (dispatch, getState) => {
  const state = getState();
  const { pathname: path = '/' } = history;
  const playerParameters = matchesPlayer(path);
  const setGAHistory = title =>
    setGoogleAnalyticsHistory(state, { path, title });

  if (playerParameters) {
    //TODO: make this only scroll to top when navigating to a page.
    // Scrolls to the top when performing a player navigation
    window.scrollTo(0, 0);
    setGAHistory('Player');
    dispatch(setPlayer(playerParameters, config));
  } else if (canEnterUserManagement(path, state)) {
    setGAHistory('User Management');
    dispatch(setUserManagement());
  } else if (canEnterAchievementEditor(path, state)) {
    setGAHistory('Achievement Editor');
    dispatch(setAchievementEditor());
  } else if (canEnterUserDashboard(path)) {
    setGAHistory('Achievements Dashboard');
    dispatch(setAchievementsDashboard());
  } else if (canEnterChangeOrganization(path)) {
    setGAHistory('Change Organization');
    dispatch(setChangeOrganization());
  } else if (canEnterReportingDashboard(path, state)) {
    setGAHistory('Reporting Dashboard');
    dispatch(setReportingDashboard());
  } else if (matchesRegister(path) || matchesJoin(path) || matchesLogin(path)) {
    dispatch(setContext(AUTHENTICATION));
  } else if (path !== '/') {
    dispatch(addModal(ErrorSelectedLocationDialog));
  } else {
    setGAHistory('Home');
    dispatch(setGuidebooks());
  }
};

export default setStateFromPath;
