import PropTypes from 'prop-types';
import { useDefaultStrings } from '../../../providers/DefaultStrings';
import DialogBody from './DialogBody';

const ConfirmDialog = ({
  message,
  icon,
  type,
  acceptButtonText,
  acceptButtonCallback,
  cancelButtonText,
  cancelButtonCallback,
  toggleOffModal,
}) => {
  const { unexpectedHTTPError, ok, cancel } = useDefaultStrings();
  const messageBody = message ?? unexpectedHTTPError;
  const acceptText = acceptButtonText ?? ok;
  const cancelText = cancelButtonText ?? cancel;
  const onCancel = () => {
    if(typeof  cancelButtonCallback === 'function'){
      cancelButtonCallback();
    }
    toggleOffModal();
  };
  const onAccept = () => {
    if(typeof acceptButtonCallback === 'function'){
      acceptButtonCallback();
    }
    toggleOffModal();
  };

  return (
    <DialogBody
      message={messageBody}
      acceptButtonText={acceptText}
      acceptButtonCallback={onAccept}
      cancelButtonText={cancelText}
      cancelButtonCallback={onCancel}
      type={type}
      icon={icon}
    />
  );
};

ConfirmDialog.propTypes = {
  message: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  acceptButtonText: PropTypes.func,
  acceptButtonCallback: PropTypes.func,
};

export default ConfirmDialog;
