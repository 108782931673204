/** @jsxImportSource @emotion/react */
import TopBar from '../assets/TopBar';
import Footer from '../assets/Footer';
import LoginBody from './LoginBody';
import LeftBean from '../assets/LeftBean';
import RightBean from '../assets/RightBean';
import ReCaptchaMessage from '../assets/ReCaptchaMessage';

const StyledLogin = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  '.content': {
    display: 'flex',
    margin: 'auto 0',
  },
};

const Login = props => {
  return (
    <div css={StyledLogin} className={'login'}>
      <TopBar {...props} />
      <div className={'content'}>
        <LeftBean />
        <LoginBody {...props} />
        <RightBean />
      </div>
      <Footer />
      <ReCaptchaMessage />
    </div>
  );
};

export default Login;
