import urls from '../../config/urls';
import axios from 'axios';

const Create = (
  token,
  fullName,
  password,
  tos_agreed,
  newsletter,
  company,
  industry,
  siteUsername
) => {
  return axios.post(urls.registration_create, {
    token,
    display_name: fullName,
    password,
    tos_agreed,
    newsletter,
    company,
    industry,
    code: siteUsername,
  });
};

export default Create;
