import styled from '@emotion/styled';

const StyledDialogButtons = styled('div')`
  text-align: right;
  .dialog-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0 0.625rem;
    display: inline-block;
    font-size: 1.1em;
    font-weight: bold;
    cursor: pointer;
  }
  .dialog-clr {
    color: ${props => props.theme.primaryColor};
  }
`;

export default StyledDialogButtons;
