import React, { createContext, useState, useEffect, useContext } from 'react';
import loadConfig from '../actions/config/load-config';
import loadFonts from '../actions/config/fonts/load-fonts';

const Context = createContext(undefined);

export const useConfig = () => useContext(Context);

const setDocumentTitleFromConfig = (config = {}) => {
  const { CustomData = {} } = config;
  const { documentTitle = 'breaze' } = CustomData;
  document.title = documentTitle;
};

export const Config = props => {
  const [config, setConfig] = useState();
  useEffect(() => {
    loadConfig().then(setConfig);
  }, []);
  useEffect(() => {
    if (config) {
      loadFonts(config);
      setDocumentTitleFromConfig(config);
    }
  }, [config]);

  if (undefined === config) {
    return null;
  }
  return <Context.Provider {...props} value={config} />;
};
