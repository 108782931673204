import React from 'react';
import NavigationErrorDialogWrapper from './NavigationErrorDialogWrapper';
import getReturnPath from './get-return-path';
import { useDefaultStrings } from '../../../providers/DefaultStrings';

const ErrorSelectedLocationDialog = props => {
  const { error, ok, selectedLocationDoesNotExist } = useDefaultStrings();
  return (
    <NavigationErrorDialogWrapper
      {...props}
      header={error}
      body={`${selectedLocationDoesNotExist}.`}
      accept={ok}
      historyCallback={getReturnPath('/')}
    />
  );
};

export default ErrorSelectedLocationDialog;
