/** @jsxImportSource @emotion/react */

const StyledLandingBackground = {
  position: 'absolute',
  width: '100vw',
  height: '100vh',
  zIndex: '-1',
  overflow: 'hidden',
  svg: {
    width: '100%',
    path: {
      fill: '#f7ea48',
    },
  },
  '.left-bean': {
    position: 'relative',
    top: '30%',
    left: '-78%',
    transform: 'rotate(-35deg)',
  },
  '.right-bean': {
    position: 'absolute',
    top: '24%',
    right: '-14%',
    width: '48%',
    transform: 'rotate(-18deg)',
  },
};

const RegisterBackground = () => {
  return (
    <div css={StyledLandingBackground} className={'landing-background'}>
      <div className={'left-bean'}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_1"
          data-name="Layer 1"
          viewBox="0 0 365.49 282.98"
        >
          <path
            d="M292.64,79.71c21.1,8.15,162.65,86.07,83.42,205.06C301.7,396.45,216.69,329,178.73,317.26S57.17,329.58,37.53,255.78C11.26,157,157.45,20.08,292.64,79.71Z"
            transform="translate(-34.45 -65.19)"
          />
        </svg>
      </div>
      <div className={'right-bean'}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_1"
          data-name="Layer 1"
          viewBox="0 0 365.49 282.98"
        >
          <path
            d="M292.64,79.71c21.1,8.15,162.65,86.07,83.42,205.06C301.7,396.45,216.69,329,178.73,317.26S57.17,329.58,37.53,255.78C11.26,157,157.45,20.08,292.64,79.71Z"
            transform="translate(-34.45 -65.19)"
          />
        </svg>
      </div>
    </div>
  );
};

export default RegisterBackground;
