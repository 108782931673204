const getResizedFontSize = width => {
  const { max } = Math;
  const baseFontSize = 16;
  const baseWidth = 960;
  const scalingFactor = 0.3;
  const fontSizeScaled =
    ((width - baseWidth) / baseWidth) * baseFontSize * scalingFactor;
  return baseFontSize + max(fontSizeScaled, 0);
};

const setHTMLFontSize = ({ innerWidth: width }) =>
  (document.body.parentNode.style.fontSize = `${getResizedFontSize(width)}px`);

const addFontSizeEventListner = () => {
  setHTMLFontSize(window);
  window.addEventListener('resize', ({ target }) => setHTMLFontSize(target));
};

export default addFontSizeEventListner;
