import React from 'react';
import Providers from '../providers';
import LoadingAppWrapper from '../containers/LoadingAppWrapper';
import App from './App';

const ProvidedApp = () => (
  <Providers>
    <LoadingAppWrapper>
      <App />
    </LoadingAppWrapper>
  </Providers>
);

export default ProvidedApp;
