import React from 'react';
import PropTypes from 'prop-types';
import NavigationErrorDialogWrapper from './NavigationErrorDialogWrapper';
import getReturnPath from './get-return-path';
import { useDefaultStrings } from '../../../providers/DefaultStrings';

const ErrorAccessLockedPageDialog = props => {
  const { error, ok, thisPageIsLockedPleaseComplete } = useDefaultStrings();
  return (
    <NavigationErrorDialogWrapper
      {...props}
      header={error}
      body={`${thisPageIsLockedPleaseComplete}.`}
      accept={ok}
      historyCallback={getReturnPath(`/entry-section.${props.rootParameter}/`)}
    />
  );
};

ErrorAccessLockedPageDialog.propTypes = {
  rootParameter: PropTypes.string.isRequired,
};

export default ErrorAccessLockedPageDialog;
