/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import BreazeModal from '../BreazeModal';
import Icon from '../../icon/Icon';
import clsx from 'clsx';
import Button from '../../ui/Button';

const StyledDialogModal = {
  '.popup':{
    margin: '7rem auto'
  }
}

const StyledDialogBody = theme => ({
  padding: '0.5rem',
  width: '20rem',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  boxSizing: 'border-box',
  '.icon-container': {
    background: theme.primaryColor,
    borderRadius: '100%',
    margin: 'auto',
    padding: '0.3rem',
    svg: {
      height: '3.5rem',
      width: '3.5rem',
    },
  },
  '.secondary': {
    background: '#FFCF46',
  },
  '.ok': {
    background: '#C4E153',
  },
  '.error': {
    background: '#FE5107',
  },
  '.message': {
    margin: '0.5rem 0',
    textAlign: 'center',
    fontSize: '0.9rem',
  },
  '.buttons': {
    display: 'flex',
    padding: '0 3rem',
    '.breaze-button': {
      margin: 'auto',
    },
  },
});

const DialogBody = ({
  message,
  icon = '',
  type,
  acceptButtonText,
  acceptButtonCallback,
  cancelButtonText,
  cancelButtonCallback,
}) => {
  const acceptButtonType =
    typeof cancelButtonCallback === 'function' ? 'ok' : type;

  return (
    <div css={StyledDialogModal}>
      <BreazeModal type={type}>
        <div className={'dialog-body'} css={StyledDialogBody}>
          {icon !== '' ? (
            <div className={clsx('icon-container', type)}>
              <Icon name={icon} />
            </div>
          ) : null}
          <div className={'message'}>{message}</div>
          <div className={'buttons'}>
            <Button
              onClick={acceptButtonCallback}
              text={acceptButtonText}
              type={acceptButtonType}
            />
            {typeof cancelButtonCallback === 'function' ? (
              <Button
                onClick={cancelButtonCallback}
                text={cancelButtonText}
                type={'error'}
              />
            ) : null}
          </div>
        </div>
      </BreazeModal>
    </div>
  );
};

DialogBody.propTypes = {
  message: PropTypes.string.isRequired,
  icon: PropTypes.string,
  type: PropTypes.string,
  acceptButtonText: PropTypes.string.isRequired,
  acceptButtonCallback: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string,
  cancelButtonCallback: PropTypes.func,
};

export default DialogBody;
