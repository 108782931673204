import isUUID from '../is-uuid';
import getGuidebooks from '../guidebooks/get-guidebooks';

const getRootId = (state, rootParameter) => {
  const guidebooksFilteredBySlug = getGuidebooks(state).filter(
    ({ slug }) => slug === rootParameter
  );
  if (
    rootParameter &&
    guidebooksFilteredBySlug.length > 0 &&
    !isUUID(rootParameter)
  ) {
    return guidebooksFilteredBySlug[0].id;
  }
  return rootParameter;
};

export default getRootId;
