/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Icon from '../icon/Icon';
import darken from '../../selectors/style/darken';

const StyledPrimary = theme => ({
  display: 'flex',
  flexDirection: 'column',
  button: {
    lineHeight: '1.1rem',
    border: 'none',
    background: theme.primaryColor,
    color: 'white',
    fontWeight: '600',
    borderRadius: '0.5rem',
    padding: '0.3rem 0.75rem',
    height: 'fit-content',
    margin: 'auto 0.3rem',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      background: darken(theme.primaryColor, 30),
    },
    '&:disabled': {
      background: '#acacac',
    },
    span: {
      verticalAlign: 'middle',
    },
    '[class*="-icon"]':{
      marginLeft: '0.3rem',
    }
  },
  '.primary': {
    background: theme.primaryColor,
    color: 'white',
  },
  '.secondary': {
    background: theme.secondaryColor,
    color: 'black',
    svg:{
      path:{
        fill: 'black'
      }
    },
    '&:hover': {
      background: darken(theme.secondaryColor, 30),
    },
    '&:disabled': {
      background: '#acacac',
    },
  },
  '.ok': {
    background: '#C4E153',
    color: 'black',
    svg:{
      path:{
        fill: 'black'
      }
    },
    '&:hover': {
      background:  darken('#C4E153', 30),
    },
    '&:disabled': {
      background: '#acacac',
    },
  },
  '.error': {
    background: '#FE5107',
    color: 'white',
    '&:hover': {
      background:  darken('#FE5107', 30),
    },
    '&:disabled': {
      background: '#acacac',
    },
  },
  '.outline':{
    border: '2px solid white',
    background: 'transparent',
    color: 'white'
  }
});

const Button = ({
  text,
  onClick,
  disabled = false,
  type = 'Primary',
  icon = false,
}) => {
  return (
    <div className={'breaze-button'} css={StyledPrimary}>
      <button className={type} disabled={disabled} onClick={onClick}>
        <span>{text}</span>
        {icon ? <Icon name={icon} /> : null}
      </button>
    </div>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  icon: PropTypes.string
};

export default Button;
