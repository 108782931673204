import React, { createContext, useContext } from 'react';
import { connect } from 'react-redux';

const StoreContext = createContext(undefined);
const DispatchContext = createContext(undefined);

export const useStore = () => useContext(StoreContext);
export const useDispatch = () => useContext(DispatchContext);

const Store = props => {
  return (
    <StoreContext.Provider value={props.state}>
      <DispatchContext.Provider value={props.dispatch}>
        {props.children}
      </DispatchContext.Provider>
    </StoreContext.Provider>
  );
};

const ConnectedStore = connect(state => ({ state }))(Store);

export { ConnectedStore as Store };
