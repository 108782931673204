const industry_list = [
  { value: 'Accounting', id: 'Accounting' },
  { value: 'Agriculture', id: 'Agriculture', },
  { value: 'Administration', id: 'Administration' },
  { value: 'Advertising', id: 'Advertising' },
  { value: 'Financial Services', id: 'Financial Services' },
  { value: 'Customer Service', id: 'Customer Service' },
  { value: 'Community Services', id: 'Community Services' },
  { value: 'Communication', id: 'Communication' },
  { value: 'Construction', id: 'Construction' },
  { value: 'Consulting & Strategy', id: 'Consulting & Strategy' },
  { value: 'Architecture', id: 'Architecture' },
  { value: 'Education', id: 'Education' },
  { value: 'Engineering', id: 'Engineering' },
  { value: 'Government', id: 'Government' },
  { value: 'Healthcare', id: 'Healthcare' },
  { value: 'Hospitality', id: 'Hospitality' },
  { value: 'Human Resources', id: 'Human Resources' },
  { value: 'Information Technology', id: 'Information Technology'},
  { value: 'Insurance', id: 'Insurance' },
  { value: 'Legal', id: 'Legal' },
  { value: 'Logistics', id: 'Logistics' },
  { value: 'Manufacturing', id: 'Manufacturing' },
  { value: 'Marketing', id: 'Marketing' },
  { value: 'Mining, Resources & Energy', id: 'Mining, Resources & Energy' },
  { value: 'Real Estate', id: 'Real Estate' },
  { value: 'Retail', id: 'Retail' },
  { value: 'Sales', id: 'Sales' },
  { value: 'Science & Technology', id: 'Science & Technology' },
  { value: 'Sport & Recreation', id: 'Sport & Recreation' },
  { value: 'Tourism', id: 'Tourism' },
  { value: 'Trades & Services', id: 'Trades & Services' },
  { value: 'Other', id: 'Other' },
];

export default industry_list;
