const Video = {
  type: 'Video',
  tags: ['media'],
  image: 'https://breas3.com/item-images-v0/video.jpg',
  rolloverImage:
    'https://breas3.com/item-images-v0/video_rollover.jpg',
  description: 'Video that is uploaded and hosted within eco',
  language: null,
  canMultiItem: true,
  helpExtension: 'video',
  content: {
    videoId: 'aa69d8e0b44e1aa454e8388ba97d17f4',
    FileName: 'Default.mp4',
    loop: 0,
    autoplay: 0,
    muted: 0,
    hideControls: 0,
  },
};

export default Video;
