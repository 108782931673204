const Poll = {
  type: 'Poll',
  tags: ['interactives'],
  image: 'https://breas3.com/item-images-v0/poll.jpg',
  rolloverImage:
    'https://breas3.com/item-images-v0/poll_rollover.jpg',
  description:
    'Interactive element to ask and display results of a poll question',
  tracking_id: null,
  interactive_id: null,
  language: null,
  canMultiItem: true,
  helpExtension: 'poll',
  content: {
    question: 'How old are you?',
    feedback: 'Completed',
    submitText: 'Vote',
    hideResults: 0,
    choices: [
      {
        id: '694ab51f-a4a5-44d6-900b-af46c0629817',
        value: 'Under 18',
      },
      {
        id: '3dcd1bac-de3f-4941-9f17-1d2734b93dcb',
        value: '18-24',
      },
      {
        id: '93510772-8f45-47ee-8c08-738508a8587b',
        value: '25-30',
      },
      {
        id: '4fedbdb4-5e7d-46df-817a-068f495e6779',
        value: '31-50',
      },
      {
        id: '50731863-fe3a-4bc2-9c3b-68f233892073',
        value: '50+',
      },
    ],
  },
};

export default Poll;
