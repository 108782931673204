import EditorComment from './EditorComment';
import Divider from './Divider';
import ImageList from './ImageList';
import InteractiveIframe from './InteractiveIframe';
import Markdown from './Markdown';
import MultipleSelectQuestion from './MultipleSelectQuestion';
import PageNavigationBar from './PageNavigationBar';
import Poll from './Poll';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import Rating from './Rating';
import Stamp from './Stamp';
import Video from './Video';
import Viewed from './Viewed';
import WrittenResponse from './WrittenResponse';
import DoubleItem from './DoubleItem';
import TripleItem from './TripleItem';

const baseItems = [
  EditorComment,
  Divider,
  ImageList,
  InteractiveIframe,
  Markdown,
  MultipleChoiceQuestion,
  MultipleSelectQuestion,
  PageNavigationBar,
  Poll,
  Rating,
  Stamp,
  Video,
  Viewed,
  WrittenResponse,
  DoubleItem,
  TripleItem,
];

export default baseItems;
