/** @jsxImportSource @emotion/react */
import BreazeLogo from '../assets/BreazeLogo';
import RegistrationInfo from './RegistrationInfo';
import React from 'react';
import LoginForm from './LoginForm';
import ForgotPasswordButton from './ForgotPasswordButton';

const StyledLoginBody = settled => ({
  opacity: settled ? '1' : '0',
  padding: '1rem',
  margin: 'auto',
  width: '18rem',
  transition: 'opacity 0.4s ease-in',
  '.form-container':{
    border: '3px solid #4AC9E3',
    borderRadius: '0.75rem',
    padding:'1.3rem',
    '.breaze-logo': {
      margin: 'auto',
      width: '9rem',
      height: '3rem',
    },
    '.help-text':{
      color: 'grey',
      textAlign: 'center',
      fontSize: '0.9rem',
    },
    '.input': {
      margin: '1rem 0 0.5rem',
    },
    '.primary-button': {
      fontSize: '0.9rem',
      padding: '0.5rem 1.75rem',
      margin: 'auto',
    },
  },

});

const LoginBody = props => {
  const [settled, setSettled] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      setSettled(true);
    }, 3);
  });

  return (
    <div css={StyledLoginBody(settled)} className={'login-body'}>
      <div className={'form-container'}>
        <BreazeLogo />
        <div className={'help-text'}>Enter your credentials to login</div>
        <LoginForm />
        <ForgotPasswordButton />
      </div>
      <RegistrationInfo {...props} />
    </div>
  );
};

export default LoginBody;
