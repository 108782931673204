import reduxEvents from '../redux-events';
import immutableSet from '../../lib/immutable-set-helpers/immutable-set';
import immutableDelete from '../../lib/immutable-set-helpers/immutable-delete';

const set = (_, completedTrackingIds) => new Set(completedTrackingIds);

const add = (state, trackingId) => immutableSet(state, trackingId);

const remove = (state, trackingId) => immutableDelete(state, trackingId);

const completedTrackingIdsEvents = reduxEvents()
  .addEvent('COMPLETED_TRACKING_IDS', 'SET', set)
  .addEvent('COMPLETED_TRACKING_IDS', 'ADD', add)
  .addEvent('COMPLETED_TRACKING_IDS', 'REMOVE', remove);

const completedTrackingIds = completedTrackingIdsEvents.getReducer(new Set());
const completedTrackingIdsActionCreator = completedTrackingIdsEvents.getActionCreators();

export { completedTrackingIds, completedTrackingIdsActionCreator };
