// darkens hex colours by a percentage, accepts AAA, #AAA, AAAAAA, #AAAAAA formats.
function darken(hexColor, percent) {
  let r;
  let g;
  let b;
  if (hexColor.length === 3) {
    r = hexColor.slice(0, 1);
    r = r.repeat(2);
    g = hexColor.slice(1, 2);
    g = g.repeat(2);
    b = hexColor.slice(2, 3);
    b = b.repeat(2);
  } else if (hexColor.length === 4) {
    r = hexColor.slice(1, 2);
    r = r.repeat(2);
    g = hexColor.slice(2, 3);
    g = g.repeat(2);
    b = hexColor.slice(3, 4);
    b = b.repeat(2);
  } else if (hexColor.length === 6) {
    r = hexColor.slice(0, 2);
    g = hexColor.slice(2, 4);
    b = hexColor.slice(4, 6);
  } else {
    // for length 7
    r = hexColor.slice(1, 3);
    g = hexColor.slice(3, 5);
    b = hexColor.slice(5, 7);
  }
  r = parseInt(r, 16);
  g = parseInt(g, 16);
  b = parseInt(b, 16);
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = (max + min) / 2;
  let s = (max + min) / 2;
  let l = (max + min) / 2;

  if (max === min) {
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
    }

    h /= 6;
  }
  h *= 360;
  s *= 100;
  l *= 100;
  l -= percent;
  if (l < 0) {
    l = 0;
  }
  return `hsl(${h.toFixed(0)},${s.toFixed(0)}%,${l.toFixed(0)}%)`;
}

export default darken;
