import loadGoogleFonts from './load-google-fonts';
import loadGoogleIcons from './load-google-icons';
import loadOtherFonts from './load-other-fonts';
import getFonts from '../../../selectors/config/fonts/get-fonts';

const loadFonts = config => {
  const fonts = getFonts(config);
  loadGoogleFonts(fonts);
  loadGoogleIcons(fonts);
  loadOtherFonts(fonts);
};

export default loadFonts;
