import store from './store';
import load from './actions/load/load';

const cache = { config: {} };

const createStore = config => {
  if (config !== null && cache.config !== config) {
    cache.config = config;
    store.dispatch(load(config));
  }
  return store;
};

export default createStore;
