import xr from '../../lib/xr/xr-wrapper';
import urls from '../../config/urls';
import { completedTrackingIdsActionCreator } from '../../events/completed-tracking-ids/completed-tracking-ids';

const { set: setCompletedTrackingIds } = completedTrackingIdsActionCreator;

const loadTrackingIdCompletions = () => dispatch =>
  xr
    .get(urls.user_trackingIdStates)
    .then(({ data }) =>
      dispatch(
        setCompletedTrackingIds(
          data.filter(({ state }) => state).map(({ id }) => id)
        )
      )
    );

export default loadTrackingIdCompletions;
