import getLanguage from './get-language';
import getTheme from './get-theme';
import getHideInterfaceClassName from './get-hide-interface-classname';

const getAppClassName = state =>
  `${getLanguage(state)} ${getTheme(state)} ${getHideInterfaceClassName(
    state
  )}`;

export default getAppClassName;
