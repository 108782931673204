const itemEditors = {
  BlankEditor: () =>
    import(
      /* webpackChunkName: "blank-editor" */
      '../../components/item-editor/blank-editor/BlankEditor'
    ),
  MarkdownEditor: () =>
    import(
      /* webpackChunkName: "markdown-editor" */
      '../../containers/item-editor/markdown/MarkdownContainer'
    ),
};

export default itemEditors;
