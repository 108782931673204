import React from 'react';
import { useConfig } from './Config';
import createStore from '../create-store';
import { Provider } from 'react-redux';

export const Redux = props => {
  const config = useConfig();
  const store = createStore(config);
  return <Provider {...props} store={store} />;
};
