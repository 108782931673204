import getPageItems from './page/get-page-items';
import getPageNodes from './pages/get-page-nodes';
import getInteractive from '../interactives/get-interactive';

const getFirstUnsavedInteractive = state => {
  let value = null;
  const pages = Array.from(getPageNodes(state).values());
  pages.forEach(page => {
    if (value === null) {
      const items = getPageItems(state, page.id);
      items.forEach(item => {
        const interactive = getInteractive(state, item);
        if (
          interactive &&
          interactive.workingContent !== interactive.initialContent
        ) {
          value = item;
        }
      });
    }
  });
  return value;
};

export default getFirstUnsavedInteractive;
