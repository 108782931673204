/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const StyledInput = {
  margin: '1.5rem 0 0.5rem',
  'input, input[type = "password"], input[type = "text"]': {
    border: '0.15rem solid #4ac9e3',
    borderRadius: '0.5rem',
    boxSizing: 'border-box',
    height: '2.2rem',
    padding: '0.4rem',
    width: '100%',
    outline: 'none',
    background: '#fff',
    transition: 'all 0.2s ease-out',
    '&:focus': {
      outline: 'none',
      border: '0.2rem solid #4ac9e3',
      color: '#000',
    },
    '&:disabled': {
      border: '0.15rem solid #acacac',
    },
  },
  'input[class="invalid"]': {
    border: '0.15rem solid #fed141',
    color: '#acacac',
    '&:focus': {
      outline: 'none',
      border: '0.2rem solid #fed141',
    },
  },
  '.help-text': {
    width: '100%',
    height: '1rem',
    opacity: '1',
    transition: 'all 0.2s ease-out',
  },
  '.hide': {
    opacity: '0',
  },
};

const BreazeTextInput = ({
  pattern = '',
  helpText = '',
  label,
  value = '',
  disabled = false,
  onChange = () => {},
  type = 'text',
  spellcheck = false
}) => {
  const [inputFocused, setInputFocused] = React.useState(false);
  const regex = new RegExp(pattern);
  const valid = regex.test(value);

  const change = e => {
    onChange({ value: e.target.value, valid: regex.test(e.target.value) });
  };

  return (
    <div css={StyledInput} className={'input'}>
      <input
        className={clsx({ invalid: !valid && (inputFocused || value !== '') })}
        type={type}
        placeholder={label}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
        onChange={e => change(e)}
        value={value}
        disabled={disabled}
        spellCheck={spellcheck}
      />
      <div
        className={clsx({
          'help-text': true,
          hide: !(!valid && (inputFocused || value !== '')),
        })}
      >
        {helpText}
      </div>
    </div>
  );
};

BreazeTextInput.propTypes = {
  pattern: PropTypes.string,
  helpText: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  spellcheck: PropTypes.bool
};

export default BreazeTextInput;
