import React from 'react';
import DialogWrapper from '../DialogWrapper';

const NavigationErrorDialogWrapper = props => {
  const { historyCallback } = props;
  const acceptCallback = historyCallback();
  return <DialogWrapper {...props} acceptCallback={acceptCallback} />;
};

export default NavigationErrorDialogWrapper;
