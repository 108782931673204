const ImageList = {
  type: 'ImageList',
  tags: ['images'],
  image: 'https://breas3.com/item-images-v0/imagelist.jpg',
  rolloverImage: 'https://breas3.com/item-images-v0/imagelist_rollover.jpg',
  description:
    'Visual element (or carousel of visual flip cards) across the width of the page',
  language: null,
  canMultiItem: true,
  helpExtension: 'image',
  content: {
    displayMode: 'flip',
    images: [
      {
        URI: 'https://breas3.com/images/placeholder_02.jpg',
        FileName: 'Placeholder_02.jpg',
        optimized: true,
      },
      {
        URI: 'https://breas3.com/images/placeholder_01.jpg',
        FileName: 'Placeholder_01.jpg',
        optimized: true,
      },
    ],
    displayModeOptions: { dots: false },
  },
};

export default ImageList;
