import { ACHIEVEMENT_EDITOR } from '../../../config/constants';
import getContext from '../../../selectors/view/get-context';
import loadAchievementEditorPage from '../../achievements/load-achievements-editor-page';

const setAchievementEditor = () => (dispatch, getState) => {
  const state = getState();
  const context = getContext(state);
  if (context !== ACHIEVEMENT_EDITOR) {
    dispatch(loadAchievementEditorPage());
  }
};

export default setAchievementEditor;
