import history from '../../lib/history';

const pushHistory = newPath => {
  const {
    location: { pathname: path, search },
  } = history;
  if (path !== newPath) {
    history.push(`${newPath}${search}`);
  }
};

export default pushHistory;
