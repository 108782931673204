import xr from '../../lib/xr/xr-wrapper';
import { titleTranslationsActionCreator } from '../../events/title-translations';

const { set } = titleTranslationsActionCreator;

const loadTitleTranslations = (view, config) => dispatch => {
  const lang = view.lang;
  const translationFiles = config.TranslationFiles;

  if (translationFiles && translationFiles[lang]) {
    xr.get(translationFiles[lang]).then(translations => {
      dispatch(set(translations.data));
    });
  } else dispatch(set([]));
};

export default loadTitleTranslations;
