import auth from './auth';
import courses from './courses';
import media from './media';
import organization from './organization';
import user from './user';
import util from './util';
import urlTodoList from './urlTodoList';
import achievements from './achievements';
import interactive from './interactive';
import registration from './registration';
import reporting from './reporting';

const urls = {
  ...achievements,
  ...auth,
  ...courses,
  ...media,
  ...interactive,
  ...organization,
  ...registration,
  ...reporting,
  ...user,
  ...util,
  ...urlTodoList,
};

export default urls;
