import reduxEvents from '../redux-events';

const setLoading = (state, loading) => ({ ...state, loading });

const toggleUtilityPanel = ({ utilityPanel, ...state }) => ({
  utilityPanel: !utilityPanel,
  ...state,
});

const set = (state, updates) => ({ ...state, ...updates });

const playerViewEvents = reduxEvents()
  .addEvent('PLAYER_VIEW', 'SET_LOADING', setLoading)
  .addEvent('PLAYER_VIEW', 'TOGGLE_UTILITY_PANEL', toggleUtilityPanel)
  .addEvent('PLAYER_VIEW', 'SET', set);

const playerView = playerViewEvents.getReducer({});
const playerViewActionCreator = playerViewEvents.getActionCreators();

export { playerView, playerViewActionCreator };
