import styled from '@emotion/styled';

const StyledMaterialSpinner = styled('div')`
  border-top: 1.1em solid rgba(255, 255, 255, 0.5);
  border-right: 1.1em solid rgba(255, 255, 255, 0.5);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.5);
  border-left: 1.1em solid rgba(0, 0, 0, 0.8);
  animation: spin 1.1s infinite linear;
  position: absolute;
  left: calc(50% - 2.5rem);
  top: calc(50% - 2.5rem);
  display: none;
  border-radius: 50%;
  width: 5em;
  height: 5em;
  &.isLoading {
    display: block;
  }
  @keyframes spin {
    0% {
      backface-visibility: hidden;
      transform: rotate(0deg) scale(1, 1);
    }
    100% {
      backface-visibility: hidden;
      transform: rotate(360deg) scale(1, 1);
    }
  }
  &:after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
  }
`;

export default StyledMaterialSpinner;
