/** @jsxImportSource @emotion/react */
import Icon from '../icon/Icon';

const StyledLegend = {
  fontSize: '0.8rem',
  display: 'flex',
  color: 'grey',
  marginTop: '-1rem',
  marginBottom: '0.5rem',
  '.text':{
    fontWeight: 600,
    margin: 'auto'
  },
  '.item':{
    margin: 'auto',
    "[class*='-icon']": {
      height: '1rem',
      width: '1rem',
      path:{
        fill: '#afafaf'
      }
    },
  }
};

const Legend = ({}) => {

  return (
    <div css={StyledLegend} className={'legend'}>
      <div className={'text'}>
        Legend:
      </div>
      <div className={'item'}>
        <Icon name={'mode-edit'}/>
        <span>Editor</span>
      </div>
      <div className={'item'}>
        <Icon name={'table-view'}/>
        <span>Reporter</span>
      </div>
    </div>
  );
};

Legend.propTypes = {};

export default Legend;