/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Icon from '../icon/Icon';

const StyledOrganizationList = theme => ({
  margin: '0 1rem 1rem 1rem',
  border: '0.15rem solid ' + theme.primaryColor,
  height: '15rem',
  borderRadius: '0.3rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  '.organization': {
    width: '100%',
    color: 'grey',
    padding: '0.3rem',
    fontSize: '0.9rem',
    cursor: 'pointer',
    display: 'flex',
    boxSizing: 'border-box',
    span:{
      flex: '1'
    },
    "[class*='-icon']": {
      path:{
        fill: '#afafaf'
      }
    },
    '&:hover': {
      background: '#e0e0e0',
    },
  },
  '.selected': {
    background: '#e0e0e0',
  },
});

const OrganizationList = ({ organizations = [], selected, setSelected }) => {
  const onClick = id => {
    setSelected(id);
  };

  return (
    <div css={StyledOrganizationList} className={'organization-list'}>
      {organizations.map(({ id, name, can_use_editor, can_run_reports}) => (
        <div
          key={id}
          onClick={() => {
            onClick(id);
          }}
          className={clsx({ organization: true, selected: id === selected })}
        >
          <span>{name}</span>
          {can_use_editor? <Icon name={'mode-edit'}/> : null}
          {can_run_reports? <Icon name={'table-view'}/> : null}
        </div>
      ))}
    </div>
  );
};

OrganizationList.propTypes = {
  organizations: PropTypes.array.isRequired,
  selected: PropTypes.number,
  setSelected: PropTypes.func.isRequired,
};

export default OrganizationList;
