const Markdown = {
  type: 'Markdown',
  tags: ['text'],
  image: 'https://breas3.com/item-images-v0/markdown.jpg',
  rolloverImage:
    'https://breas3.com/item-images-v0/markdown_rollover.jpg',
  description: 'Text information spread across the width of the page',
  language: null,
  canMultiItem: true,
  helpExtension: 'text',
  content: {
    data: `A First Level Header
====================

A Second Level Header
---------------------

This is just a
regular paragraph.

The quick brown fox jumped over the lazy
dog's back.

### Header 3

> This is a blockquote.
>
> This is the second paragraph in the blockquote.
>
> ## This is an H2 in a blockquote

1. Helium
2. Iron
3. 
4. Magnesium

Name | Symbol | Atomic Number | Type
--- | --- | --- | ---
Lithium | Li | 3 | Metal
Carbon | C | 6 | Nonmetal
Silver | Ag | 47 | Transition
Neon | Ne | 10 | Noble Gas
`,
  },
};

export default Markdown;
