import inGuidebookContext from '../../../selectors/view/in-guidebook-context';
import { playerViewActionCreator } from '../../../events/view/player-view';
import clearGuidebook from '../../guidebook/clear-guidebook';
import guidebookLoaded from '../../../selectors/guidebook/guidebook-loaded';
import getRootId from '../../../selectors/view/player/get-root-id';
import getSectionId from '../../../selectors/view/player/get-section-id';
import getPageId from '../../../selectors/view/player/get-page-id';
import setContext from '../../view/set-context';
import getTopicId from '../../../selectors/view/player/get-topic-id';

const { set: setPlayerView } = playerViewActionCreator;

const clearPlayer = () => (dispatch, getStore) => {
  const state = getStore();
  const rootId = getRootId(state);
  const sectionId = getSectionId(state);
  const pageId = getPageId(state);
  const topicId = getTopicId(state);
  if (
    rootId !== undefined ||
    sectionId !== undefined ||
    pageId !== undefined ||
    topicId !== undefined
  ) {
    const playerViewUpdates = {
      rootId: undefined,
      sectionId: undefined,
      pageId: undefined,
      topicId: undefined,
    };
    dispatch(setPlayerView(playerViewUpdates));
  }
  if (!inGuidebookContext(state)) {
    dispatch(setContext(undefined));
  }
  if (guidebookLoaded(state)) {
    dispatch(clearGuidebook());
  }
};

export default clearPlayer;
