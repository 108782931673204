const immutableSet = (set, key) => {
  if (set.has(key)) {
    return set;
  }
  const newSet = new Set(set);
  newSet.add(key);
  return newSet;
};

export default immutableSet;
