import { CHANGE_ORGANIZATION } from '../../../config/constants';
import getContext from '../../../selectors/view/get-context';
import loadChangeOrganization from '../../change-organization/load-change-organization';

const setChangeOrganization = () => (dispatch, getState) => {
  const state = getState();
  const context = getContext(state);
  if (context !== CHANGE_ORGANIZATION) {
    dispatch(loadChangeOrganization());
  }
};

export default setChangeOrganization;
