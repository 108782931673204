const createGuidebook = (root, data) => {
  const {
    sectionNodes,
    pageNodes,
    pageItems,
    childSections,
    childPages,
  } = data;
  const sectionNodesMap = new Map();
  const pageNodesMap = new Map();
  const pageItemsMap = new Map();
  const childSectionsMap = new Map();
  const childPagesMap = new Map();
  const setMapOfArray = (target, id, value) =>
    target.has(id) ? target.get(id).push(value) : target.set(id, [value]);

  sectionNodes.forEach(sectionNode =>
    sectionNodesMap.set(sectionNode.id, sectionNode)
  );
  pageNodes.forEach(pageNode => pageNodesMap.set(pageNode.id, pageNode));
  pageItems.forEach(pageItem =>
    setMapOfArray(pageItemsMap, pageItem.page_id, pageItem)
  );
  childPages.forEach(({ parentId, childId }) =>
    setMapOfArray(childPagesMap, parentId, childId)
  );
  childSections.forEach(({ parentId, childId }) =>
    setMapOfArray(childSectionsMap, parentId, childId)
  );
  const guidebook = {
    sectionNodes: sectionNodesMap,
    pageNodes: pageNodesMap,
    pageItems: pageItemsMap,
    childPages: childPagesMap,
    childSections: childSectionsMap,
  };
  return {
    root,
    initial: guidebook,
    working: guidebook,
    dirtyPages: new Set(),
    dirtySections: new Set(),
  };
};

export default createGuidebook;
