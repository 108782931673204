import { USER_MANAGEMENT } from '../../../config/constants';
import loadUserManagement from '../../user-management/load-user-management';
import getContext from '../../../selectors/view/get-context';

const setUserManagement = () => (dispatch, getState) => {
  const state = getState();
  const context = getContext(state);
  if (context !== USER_MANAGEMENT) {
    dispatch(loadUserManagement());
  }
};

export default setUserManagement;
