export default {
  reporting_user_progress: '/api/reporting/user_progress',
  reporting_user_tracking_completion: '/api/reporting/user_tracking_completion',
  reporting_achievement_earns: '/api/reporting/achievement_earns',
  reporting_average_user_progress: '/api/reporting/average_user_progress',
  reporting_header_information: '/api/reporting/header_information',
  reporting_questions: '/api/reporting/questions',
  reporting_question: '/api/reporting/question',
  reporting_polls: '/api/reporting/polls',
  reporting_poll: '/api/reporting/poll',
  reporting_ratings: '/api/reporting/ratings',
  reporting_rating: '/api/reporting/rating',
  reporting_written_responses: '/api/reporting/written_responses',
  reporting_written_response: '/api/reporting/written_response',
};
