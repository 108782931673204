/** @jsxImportSource @emotion/react */
import SecondaryButton from '../assets/buttons/SecondaryButton';
import React from 'react';
import { useHistory } from 'react-router-dom';

const StyledAlreadyRegisteredButton = {
  display: 'flex',
  margin: 'auto',
  fontSize: '0.9rem',
  fontWeight: '700',
  span: {
    verticalAlign: 'middle',
    lineHeight: '2rem',
    marginRight: '0.5rem',
  },
  '.secondary-button': {
    button: {
      padding: '0.3rem 2.3rem',
    },
  },
};

const AlreadyRegisteredButton = () => {
  const history = useHistory();
  return (
    <div className={'already'} css={StyledAlreadyRegisteredButton}>
      <span>{'Already Registered?'}</span>
      <SecondaryButton text={'login'} onClick={() => history.push('/')} />
    </div>
  );
};

export default AlreadyRegisteredButton;
