import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import urls from '../config/urls';
import { getToken } from './useLoginToken';

const refreshAuthLogic = async () => {
  const tokenRefreshResponse = await axios({
    method: 'post',
    url: urls.auth_reauthorize,
    headers: { Authorization: `Bearer ${getToken()}` },
    skipAuthRefresh: true,
  });

  const token = tokenRefreshResponse.data;
  localStorage.setItem('breaze_login_token', JSON.stringify(token));
  return Promise.resolve();
};

const useAxios = (config, options) => {
  const axiosInstance = axios.create();
  createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);
  axiosInstance.interceptors.request.use(request => {
    request.headers['Authorization'] = `Bearer ${getToken()}`;
    return request;
  });
  return makeUseAxios({ axios: axiosInstance })(config, options);
};

export default useAxios;
