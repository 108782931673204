import urls from '../../config/urls';
import axios from 'axios';

const validatePasswordResetToken = async token => {
  try {
    const { data } = await axios.post(urls.registration_validatePasswordReset, token);
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

export default validatePasswordResetToken;
