/** @jsxImportSource @emotion/react */
import { Route, Switch } from 'react-router-dom';
import RegisterButton from './RegisterButton';
import AlreadyRegisteredButton from './AlreadyRegisteredButton';

const StyledGetBreazeLinks = {
  display: 'flex',
  marginLeft: 'auto',
    '.basics':{
      ul:{
        borderRight: '1px solid grey',
        listStyle: 'none',
        textAlign: 'center',
        li:{
          display: 'inline-block',
          a:{
            display: 'block',
            padding: '1rem 0.1rem',
            margin: '0 0.8rem',
            textDecoration: 'none',
            color: 'grey',
            fontSize: '0.9rem',
            fontFamily: "'Rubik', sans-serif;",
            position: 'relative',
            '&:after':{
              transition: 'all 0.35s',
              position: 'absolute',
              bottom: '0',
              left: '0',
              right: '0',
              margin: 'auto',
              width: '0%',
              content: '"."',
              color: 'transparent',
              background: '#4AC9E3',
              height: '0.16rem',
            },
            '&:before':{
              transition: 'all 0.5s',
            },
            '&:hover':{
              color: '#4AC9E3',
              '&:after':{
                width: '100%'
              }
            }
          }
        }
      }
    },
  '.register-btn, .already':{
    marginLeft: '0.3rem'
  }
};

const GetBreazeLinks = ({}) => {

  return (
    <div css={StyledGetBreazeLinks} className={'get-breaze-links'}>
      <nav className={'basics'}>
        <ul>
          <li><a href={'https://www.getbreaze.com/'}>{'Home'}</a></li>
          <li><a href={'https://www.getbreaze.com/why-breaze'}>{'Why Breaze'}</a></li>
          <li><a href={'https://www.getbreaze.com/breaze-features'}>{'Features'}</a></li>
          <li><a href={'https://www.getbreaze.com/pricing'}>{'Pricing'}</a></li>
          <li><a href={'https://resources.getbreaze.com/blog'}>{'Blog'}</a></li>
          <li><a href={'https://www.getbreaze.com/contact-us'}>{'Contact'}</a></li>
        </ul>
      </nav>
      <Switch>
        <Route exact path="/">
          <RegisterButton />
        </Route>
        <AlreadyRegisteredButton />
      </Switch>
    </div>
  );
};

export default GetBreazeLinks;