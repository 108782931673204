import styled from '@emotion/styled';

const StyledDialog = styled('div')`
  @keyframes move {
    from {
      z-index: -1;
      transform: translate(0, 0);
    }
    to {
      z-index: 18;
      transform: translate(-50%, -50%);
    }
  }
  display: inline-block;
  user-select: none;
  height: auto;
  position: fixed;
  margin: auto;
  width: 30%;
  min-width: 18.75rem;
  top: 50%;
  left: 50%;
  z-index: -1;
  animation: move 0.0001s forwards;
  background: #fff;
  box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.5);
  padding: 1.25rem;
  box-sizing: border-box;
`;

export default StyledDialog;
