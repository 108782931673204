import React from 'react';
import LoadedComponent from '../loaded-component/LoadedComponent';
import LogoutEmpty from '../authentication/LogoutEmpty';
import ChangeOrganization from '../change-organization/ChangeOrganization';
import { Redirect, Route, Switch } from 'react-router-dom';

const UserManagement = () => <LoadedComponent library="UserManagement" />;
const AchievementEditor = () => <LoadedComponent library="AchievementEditor" />;
const UserDashboard = () => <LoadedComponent library="UserDashboard" />;
const ReportingDashboard = () => <LoadedComponent library={"ReportingDashboard"} />;
const SectionComponent = () => <LoadedComponent library="Section" />;
const PageComponent = () => <LoadedComponent key="Page" library="Page" />;
const HomepageComponent = () => <LoadedComponent library="Homepage" />;

const slugRgx = '[.][A-z0-9-\\s]+';
const pagePathRegexp = `/:rootId(entry-section${slugRgx})/:sectionId(section${slugRgx})?/:pageId(page${slugRgx})`;
const sectionPathRegexp = `/:rootId(entry-section${slugRgx})/:sectionId(section${slugRgx})?`;

const Context = () => {
  return (
    <Switch>
      <Route path="/user-management">
        <UserManagement />
      </Route>
      <Route path="/achievement-editor">
        <AchievementEditor />
      </Route>
      <Route path="/achievements-dashboard">
        <UserDashboard />
      </Route>
      <Route path="/reporting-dashboard">
        <ReportingDashboard />
      </Route>
      <Route path="/change-organization">
        <ChangeOrganization />
      </Route>
      <Route path="/logout">
        <LogoutEmpty />
      </Route>
      <Route path="/join-team">
        <LogoutEmpty />
      </Route>
      <Route path="/create-team">
        <LogoutEmpty />
      </Route>
      <Route path={pagePathRegexp}>
        <PageComponent />
      </Route>
      <Route path={sectionPathRegexp}>
        <SectionComponent />
      </Route>
      <Route exact path="/">
        <HomepageComponent />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

export default Context;
