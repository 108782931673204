/** @jsxImportSource @emotion/react */
import { toast, ToastContainer } from 'react-toastify';
import { useStore, useDispatch } from '../../providers/Store';
import AchievementToast from '../../components/toaster/AchievementToast';
import { achievementsActionCreator } from '../../events/achievements/achievements';
import pushHistory from '../../actions/history/push-history';

const { setEarnedAchievements } = achievementsActionCreator;

const style = theme => ({
  '.Toastify': {
    width: '100%',
    overflow: 'hidden',
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    zIndex:'1000',
    '.Toastify__toast-container--bottom-center': {
      width: 'auto',
    },
  },

  '.custom-toast': {
    fontFamily: "'Brandon Grotesque Regular', Arial, sans-serif",
    background: 'white',
    fontSize: '16px',
    width: 'auto',
  },

  '.custom-toast-body': {
    width: 'auto',
    margin: 0,
    flex: 'initial',
  },

  '.toast-content': {
    display: 'flex',
    width: 'auto',
    height: '80px',
    '.toast-achievement-image': {
      boxSizing: 'border-box',
      minWidth: '100px',

      minHeight: '80px',
      maxHeight: '80px',
      filter: 'grayscale(100%)',
      animation: 'fillIn 0.2s linear 1.25s forwards',
    },
    '.badge-notification-content': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      fontWeight: 'bold',
      boxSizing: 'border-box',
      maxWidth: 0,
      animation: 'grow 1s ease 1.85s forwards',
      '.notification-message': {
        fontSize: '14px',
        fontWeight: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '.notification-message, .notification-title': {
        opacity: 0,
        animation: 'fadeDown 0.5s linear 2.85s forwards',
        letterSpacing: '0.5px',
      },
      '.notification-title': {
        textTransform: 'uppercase',
        fontSize: '13px',
        color: 'rgba(0,0,0,0.6)',
      },
    },
  },

  '@keyframes fillIn': {
    from: {
      filter: 'grayscale(100%)',
    },

    to: {
      filter: 'grayscale(0)',
    },
  },

  '@keyframes grow': {
    from: {
      paddingLeft: 0,
      maxWidth: 0,
      minHeight: 0,
    },

    to: {
      paddingLeft: '8px',
      maxWidth: '9999px',
      minHeight: 'initial',
      whiteSpace: 'initial', // experimental
    },
  },

  '@keyframes fadeDown': {
    '0%': {
      opacity: 0,
      transform: 'translate3d(0, -100%, 0)',
    },

    '100%': {
      opacity: 1,
      transform: 'none',
    },
  },

  '@keyframes growContent': {
    '0%': {
      maxWidth: '100px',
    },

    '100%': {
      maxWidth: '9999px',
    },
  },
});

const toastProps = {
  className: 'custom-toast',
  bodyClassName: 'custom-toast-body',
  position: toast.POSITION.BOTTOM_CENTER,
  autoClose: false,
};

const ToasterContainer = props => {
  const store = useStore();
  const dispatch = useDispatch();
  const earnedAchievements = store.player.achievements.earnedAchievements;
  earnedAchievements.forEach(earn => {
    const toastMessage = `${earn.name} Earned!`;
    const onClick = () => {
      pushHistory('/achievements-dashboard?earn_id=' + earn.id);
      window.scroll(0, 0);
    };
    toast(
      <AchievementToast
        onClick={onClick}
        toastMessage={toastMessage}
        description={earn.details.description}
        image={earn.details.image}
      />,
      toastProps
    );
    dispatch(setEarnedAchievements([]));
  });

  return (
    <div css={style} className="toaster-container">
      <ToastContainer newestOnTop={true} position="bottom-center" />
    </div>
  );
};

export default ToasterContainer;
