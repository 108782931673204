/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import BreazeTextInput from '../../assets/Inputs/BreazeTextInput';
import React from 'react';
import BreazeCheckbox from '../../assets/Inputs/BreazeCheckbox';

const UserSetupPanel = ({
  email,
  fullName,
  setFullName,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  newsletter,
  setNewsletter,
  tos,
  setTos,
}) => {
  return (
    <div className={'box top'}>
      <h1>{'User Account Setup'}</h1>
      <div className={'message'}>
        {
          'This information will be used to create your individual Breaze account that you can access anytime to learn or create content within your e-learning organization.'
        }
      </div>
      <BreazeTextInput
        label={'Email'}
        type={'text'}
        value={email}
        disabled={true}
      />
      <BreazeTextInput
        label={'Full Name'}
        pattern={'^.{2,}$'}
        value={fullName.value}
        onChange={setFullName}
      />
      <BreazeTextInput
        label={'Password'}
        helpText={'Password must have a length of 8'}
        pattern={'^.{8,}$'}
        value={password.value}
        onChange={setPassword}
        type={'password'}
      />
      <BreazeTextInput
        label={'Confirm Password'}
        pattern={'^.{8,}$'}
        value={confirmPassword.value}
        onChange={setConfirmPassword}
        type={'password'}
      />
      <BreazeCheckbox
        text={'Opt in to newsletter'}
        value={newsletter.value}
        onChange={setNewsletter}
        mustBeTrue={true}
      />
      <BreazeCheckbox
        text={'Agree to '}
        linkText={'terms of service'}
        link={'https://www.getbreaze.com/legal'}
        value={tos.value}
        onChange={setTos}
        mustBeTrue={true}
      />
    </div>
  );
};

UserSetupPanel.propTypes = {
  email: PropTypes.string.isRequired,
  fullName: PropTypes.object.isRequired,
  password: PropTypes.object.isRequired,
  confirmPassword: PropTypes.object.isRequired,
  setFullName: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  setConfirmPassword: PropTypes.func.isRequired,
  newsletter: PropTypes.object.isRequired,
  setNewsletter: PropTypes.func.isRequired,
  tos: PropTypes.object.isRequired,
  setTos: PropTypes.object.isRequired,
};

export default UserSetupPanel;
