import React, { Suspense, lazy } from 'react';
import getLoader from '../../selectors/config/custom-components/library/get-custom-component-library-loader';
import { useConfig } from '../../providers/Config';

const LoadedSpinner = props => {
  const config = useConfig();
  const spinnerLoader = getLoader(config, 'Spinner');
  const Spinner = lazy(spinnerLoader);
  return (
    <Suspense fallback={<div />}>
      <Spinner {...props} />
    </Suspense>
  );
};

export default LoadedSpinner;
