import loadCourses from '../../../api/courses/load';
import setTopicHistory from './set-topic-history';
import isLoadingWrapper from '../../load/is-loading-wrapper';
import { playerViewActionCreator } from '../../../events/view/player-view';

const { set } = playerViewActionCreator;

const navigateToTopic = topicId => dispatch =>
  dispatch(isLoadingWrapper(dispatch(loadCourses()))).then(() => {
    dispatch(set(undefined, undefined, undefined, topicId));
    dispatch(setTopicHistory(topicId));
  });

export default navigateToTopic;
