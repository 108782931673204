import xr from './xr';
import { getToken } from '../../hooks/useLoginToken';

const addAuthHeader = args => {
  const { headers = {}, ...rest } = args;
  const jwt = getToken();
  return jwt
    ? { ...rest, headers: { ...headers, Authorization: `Bearer ${jwt}` } }
    : args;
};

xr.get = (url, params, args) =>
  xr(addAuthHeader(xr.assign({ url, method: xr.Methods.GET, params }, args)));
xr.put = (url, data, args) =>
  xr(addAuthHeader(xr.assign({ url, method: xr.Methods.PUT, data }, args)));
xr.post = (url, data, args) =>
  xr(addAuthHeader(xr.assign({ url, method: xr.Methods.POST, data }, args)));
xr.patch = (url, data, args) =>
  xr(addAuthHeader(xr.assign({ url, method: xr.Methods.PATCH, data }, args)));
xr.del = (url, args) =>
  xr(addAuthHeader(xr.assign({ url, method: xr.Methods.DELETE }, args)));
xr.options = (url, args) =>
  xr(addAuthHeader(xr.assign({ url, method: xr.Methods.OPTIONS }, args)));

export default xr;
