const EditorComment = {
  type: 'EditorComment',
  tags: ['other'],
  image: 'https://breas3.com/item-images-v0/comment_rollover.jpg',
  enabledByConfig: true,
  rolloverImage:
    'https://breas3.com/item-images-v0/comment_rollover.jpg',
  description:
    'Interactive element for editor comments (Only visible to those with editor credentials)',
  content: {
    data: '',
  },
};

export default EditorComment;
