import React from 'react';
import { Config } from './Config';
import { AppTheme } from './AppTheme';
import { Redux } from './Redux';
import { Store } from './Store';
import { Translation } from './Translation';
import { DefaultStrings } from './DefaultStrings';
import { LoginCheck } from './LoginCheck';
import { Router } from 'react-router-dom';
import history from '../lib/history';

const Providers = props => {
  return (
    <Router history={history}>
      <LoginCheck>
        <Config>
          <AppTheme>
            <Redux>
              <Store>
                <Translation>
                  <DefaultStrings {...props} />
                </Translation>
              </Store>
            </Redux>
          </AppTheme>
        </Config>
      </LoginCheck>
    </Router>
  );
};

export default Providers;
