/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

const StyledBreazeDropDown = {
  margin: '0.5rem 0 1.5rem 0',
  select: {
    border: '0.15rem solid #4ac9e3',
    borderRadius: '0.5rem',
    boxSizing: 'border-box',
    height: '2.2rem',
    padding: '0.4rem',
    width: '100%',
    outline: 'none',
    transition: 'all 0.2s ease-out',
    '&:focus': {
      outline: 'none',
      border: '0.2rem solid #4ac9e3',
    },
    '&:disabled': {
      border: '0.15rem solid #acacac',
    },
    'option[class="invalid"]': {
      color: '#acacac',
    },
  },
  'select[class="invalid"]': {
    color: '#acacac',
  },
  'select[class="focused-invalid"]': {
    border: '0.15rem solid #fed141',
    color: '#acacac',
    '&:focus': {
      outline: 'none',
      border: '0.2rem solid #fed141',
    },
  },
  '.help-text': {
    color: '#acacac',
    width: '100%',
    height: '1rem',
    opacity: '1',
    transition: 'all 0.2s ease-out',
  },
  '.hide': {
    opacity: '0',
  },
};

const BreazeDropDown = ({
  label,
  options,
  value = '',
  disabled = false,
  onChange,
  required = true,
}) => {
  const [inputFocused, setInputFocused] = React.useState(false);
  const valid = (required && value !== '') || !required;

  const change = e => {
    onChange({
      value: e.target.value,
      valid: (required && e.target.value !== '') || !required,
    });
  };

  return (
    <div css={StyledBreazeDropDown} className={'breaze-drop-down'}>
      <select
        className={clsx({
          invalid: !valid,
          'focused-invalid': inputFocused && !valid,
        })}
        placeholder={label}
        onChange={e => change(e)}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
      >
        <option
          value={''}
          className={'invalid'}
          disabled={true}
          selected={true}
          hidden={true}
        >
          {label}
        </option>
        {options.map(option => (
          <option id={option.value} value={option.value}>
            {option.value}
          </option>
        ))}
      </select>
    </div>
  );
};

BreazeDropDown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default BreazeDropDown;
