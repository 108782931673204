import React from 'react';
import PropTypes from 'prop-types';
import NavigationErrorDialogWrapper from './NavigationErrorDialogWrapper';
import getReturnPath from './get-return-path';
import { useDefaultStrings } from '../../../providers/DefaultStrings';

const ErrorAccessLockedSectionDialog = props => {
  const { error, ok, thisSectionIsLockedPleaseComplete } = useDefaultStrings();
  return (
    <NavigationErrorDialogWrapper
      {...props}
      header={error}
      body={`${thisSectionIsLockedPleaseComplete}.`}
      accept={ok}
      historyCallback={getReturnPath(`/entry-section.${props.rootParameter}/`)}
    />
  );
};

ErrorAccessLockedSectionDialog.propTypes = {
  rootParameter: PropTypes.string.isRequired,
};

export default ErrorAccessLockedSectionDialog;
