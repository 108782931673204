/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import icons from '../../lib/icons';

const Style = {
  path: {
    fill: '#fff',
  },
};

const Icon = ({ name, className, onClick, viewBox, tabIndex }) => (
  <svg
    tabIndex={tabIndex}
    className={`${name}-icon ${className}`}
    onClick={onClick}
    viewBox={viewBox}
    css={Style}
  >
    <path d={icons[name]} />
  </svg>
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  viewBox: PropTypes.string,
  tabIndex: PropTypes.string,
};

Icon.defaultProps = {
  className: '',
  onClick: () => {},
  viewBox: '0 0 24 24',
  tabIndex: null,
};

export default Icon;
