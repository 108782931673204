import React, { createContext, useContext } from 'react';

import defaultStrings from '../lib/i18n/default-strings';
import { useI18n } from './Translation';

const DefaultStringsContext = createContext(defaultStrings);

export const useDefaultStrings = () => useContext(DefaultStringsContext);

export const DefaultStrings = props => {
  const i18n = useI18n();
  const translatedDefaultStrings = {};
  for (const key in defaultStrings) {
    translatedDefaultStrings[key] = i18n(defaultStrings[key]);
  }
  return (
    <DefaultStringsContext.Provider
      {...props}
      value={translatedDefaultStrings}
    />
  );
};
