const emptyGuidebook = {
  root: undefined,
  sectionNodes: new Map(),
  pageNodes: new Map(),
  pageItems: new Map(),
  childSections: new Map(),
  childPages: new Map(),
};

export default emptyGuidebook;
