import getGoogleFonts from '../../../selectors/config/fonts/get-google-fonts';

const loadGoogleFonts = fonts => {
  const googleFonts = getGoogleFonts(fonts);
  if (googleFonts) {
    const link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute(
      'href',
      `https://fonts.googleapis.com/css?family=${googleFonts}`
    );
    document.head.appendChild(link);
  }
};

export default loadGoogleFonts;
