const reportingAndUtilityPages = {
  UserManagement: () =>
    import(
      /* webpackChunkName: "user-management" */
      '../../components/user-management/UserManagement'
    ),
  AchievementEditor: () =>
    import(
      /* webpackChunkName: "achievement-editor" */
      '../../components/achievement-editor/AchievementEditorDashboard'
    )
};

export default reportingAndUtilityPages;
