import React, { createContext, useContext, useState, useEffect } from 'react';
import translationMap from '../lib/i18n/translation-map';
import { useConfig } from './Config';
import { useStore } from './Store';
import getLanguage from '../selectors/view/get-language';
import kebabCase from 'lodash/kebabCase';

const I18nContext = createContext(undefined);

export const useI18n = () => useContext(I18nContext);

const useHook = () => {
  const config = useConfig();
  const store = useStore();
  const language = getLanguage(store);
  const [i18n, setI18n] = useState(() => getTranslate(language, config));
  useEffect(() => {
    setI18n(() => getTranslate(language, config));
  }, [config, language]);
  return i18n;
};

export const Translation = props => {
  const i18n = useHook();
  return <I18nContext.Provider {...props} value={i18n} />;
};

const getTranslate = (language, config) => {
  const sources = getTranslationSources(language, config);
  return input => translate(input, sources);
};

const translate = (input, sources) => {
  const kebabedInput = kebabCase(input);
  const matchedSource = sources.find(
    source =>
      Object.prototype.hasOwnProperty.call(source, input) ||
      Object.prototype.hasOwnProperty.call(source, kebabedInput)
  );
  if (matchedSource !== undefined) {
    return Object.prototype.hasOwnProperty.call(matchedSource, kebabedInput)
      ? matchedSource[kebabedInput]
      : matchedSource[input];
  }
  return input;
};

const getTranslationSources = (language, config) => {
  const translationSources = [];
  const configLanguageMap = config.TranslationMap ? config.TranslationMap : {};
  if (Object.prototype.hasOwnProperty.call(configLanguageMap, language)) {
    translationSources.push(configLanguageMap[language]);
  }
  if (Object.prototype.hasOwnProperty.call(translationMap, language)) {
    translationSources.push(translationMap[language]);
  }
  return translationSources;
};
