/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

const StyledBreazeCheckbox = {
  margin: '0 0 0.5rem',
  input: {
    height: '1rem',
    padding: '0.4rem',
    width: '1.3rem',
    outline: 'none',
    background: '#fff',
    transition: 'all 0.2s ease-out',
    '&:focus': {
      outline: 'none',
      border: '0.2rem solid #4ac9e3',
    },
  },
  '.box:checked:before': {
    backgroundColor: 'orange',
    color: 'orange',
  },
  label: {
    color: '#707070',
    fontSize: '0.8rem',
    position: 'relative',
    top: '-0.2rem',
    marginLeft: '0.3rem',
  },
};

const BreazeCheckbox = ({
  text,
  linkText,
  link,
  value,
  onChange,
  mustBeTrue = false,
}) => {
  const setValue = value => {
    onChange({ value: value, valid: mustBeTrue ? value === true : false });
  };
  return (
    <div css={StyledBreazeCheckbox} className={'breaze-checkbox'}>
      <input
        type={'checkbox'}
        id={text}
        className={'box'}
        value={value}
        onChange={e => setValue(e.target.checked)}
      />
      {linkText && link ? (
        <label htmlFor={text}>
          {text}{' '}
          <a href={link} target="_blank">
            {linkText}
          </a>
        </label>
      ) : (
        <label htmlFor={text}>{text}</label>
      )}
    </div>
  );
};

BreazeCheckbox.propTypes = {
  text: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  link: PropTypes.string,
  mustBeTrue: PropTypes.bool,
};

export default BreazeCheckbox;
