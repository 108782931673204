/** @jsxImportSource @emotion/react */

const StyledReCaptchaMessage = {
  position: 'absolute',
  background: '#f1f3f4',
  color: '#546269',
  width: '13rem',
  bottom: '1.5rem',
  right: '0',
  padding: '0.5rem',
  textAlign: 'center',
  borderRadius: '0.5rem 0 0 0.5rem',
  a:{
    padding: '0 0.1rem'
  },
  '@media only screen and (max-width: 900px)': {
    position: 'relative',
    marginTop: '2rem',
  },
};

const ReCaptchaMessage = ({}) => {

  return (
    <div css={StyledReCaptchaMessage} className={'re-captcha-message'}>
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy" target={'_blank'}>Privacy Policy</a> and
      <a href="https://policies.google.com/terms" target={'_blank'}>Terms of Service</a> apply.
    </div>
  );
};


export default ReCaptchaMessage;