/** @jsxImportSource @emotion/react */
import TopBar from '../assets/TopBar';
import Footer from '../assets/Footer';
import LeftBean from '../assets/LeftBean';
import RightBean from '../assets/RightBean';
import PasswordResetBody from './PasswordResetBody';
import React from 'react';
import getQueryParameter from '../../../selectors/get-query-parameter';
import validatePasswordResetToken from '../../../api/authentication/validate-password-reset-token';
import ReCaptchaMessage from '../assets/ReCaptchaMessage';

const StyledLogin = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  '.content': {
    display: 'flex',
    margin: 'auto 0',
  },
};

const PasswordReset = props => {
  const token = getQueryParameter('token');
  const [email, setEmail] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const validateToken = async () => {
      if (token) {
        try {
          setLoading(true);
          const data = await validatePasswordResetToken(token);
          setEmail(data);
          setLoading(false);
        } catch (e) {
          setLoading(false);
        }
      }
    };
    validateToken();
  }, [token]);

  return (
    <div css={StyledLogin} className={'login'}>
      <TopBar {...props} />
      <div className={'content'}>
        <LeftBean />
        <PasswordResetBody {...props} email={email} loading={loading} setLoading={setLoading} token={token} />
        <RightBean />
      </div>
      <Footer />
      <ReCaptchaMessage />
    </div>
  );
};

export default PasswordReset;