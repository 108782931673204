const getParseOrder = config => {
  if (config && config.Navigation && config.Navigation.parseOrder) {
    if (config.Navigation.parseOrder === 'sectionsBeforePages') {
      return 'sectionsBeforePages';
    }
  }
  return 'pagesBeforeSections';
};

export default getParseOrder;
