const MultipleChoiceQuestion = {
  type: 'MultipleChoiceQuestion',
  tags: ['tracking', 'questions'],
  image:
    'https://breas3.com/item-images-v0/multiplechoicequestion.jpg',
  rolloverImage:
    'https://breas3.com/item-images-v0/multiplechoicequestion_rollover.jpg',
  description: 'Knowledge Check question in a multiple choice format',
  tracking_id: null,
  interactive_id: null,
  language: null,
  forceTracked: true,
  canMultiItem: true,
  helpExtension: 'multiple-choice-question',
  content: {
    question: 'How many fingers are on the human hand?',
    random: true,
    hideCheckMark: false,
    choices: [
      {
        value: '5',
        correct: false,
        id: 'a4f04802-1ddf-4ce6-bfcd-c4558a06e7cb',
        feedback: 'A common mistake.',
      },
      {
        value: '4',
        correct: true,
        id: 'f6593805-7c44-451e-ac78-07b25d1c8109',
        feedback: 'Correct you are.',
      },
      {
        value: '10',
        correct: false,
        id: '1fcf6bd8-7605-4bec-9842-f880b4c3ed09',
        feedback: 'Please read the question carefully.',
      },
      {
        value: 'All of the above',
        correct: false,
        id: '7ae5d4d7-5b49-40ad-80e7-b723cbcacf05',
        feedback: 'Um. No.',
      },
    ],
  },
};

export default MultipleChoiceQuestion;
