/** @jsxImportSource @emotion/react */
import React from 'react';
import { useHistory } from 'react-router-dom';
import getQueryParameter from '../../../selectors/get-query-parameter';
import validateJoinToken from '../../../api/authentication/validate-join-token';
import TopBar from '../assets/TopBar';
import LeftBean from '../assets/LeftBean';
import RightBean from '../assets/RightBean';
import Footer from '../assets/Footer';
import Form from './Form';
import BreazeLogo from '../assets/BreazeLogo';
import ReCaptchaMessage from '../assets/ReCaptchaMessage';

const StyledJoinOrganization = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  '.body': {
    display: 'flex',
    margin: 'auto 0',
    '.content':{
      display: 'flex',
      flexDirection: 'column',
      width: '15rem',
      minHeight: '13rem',
      margin: 'auto',
      border: '3px solid #4AC9E3',
      padding: '1rem',
      borderRadius: '0.75rem',
      '.breaze-logo':{
        margin: '0.3rem auto',
        width: '9rem',
        height: '2.5rem',
      }
    }
  },
  '.background': {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    zIndex: '-1',
  },
};

const Join = () => {
  const history = useHistory();
  const token = getQueryParameter('token');
  const [email, setEmail] = React.useState(null);
  const [userExists, setUserExists] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const validateToken = async () => {
      if (token) {
        try {
          setLoading(true);
          const data = await validateJoinToken(token);
          if (data?.user_status === 'exists_not_in') {
            setUserExists(true);
          } else if (data?.user_status === 'does_not_exist') {
            setUserExists(false);
          }
          setEmail(data?.email);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          history.push('/');
        }
      } else {
        history.push('/');
      }
    };
    validateToken();
  }, [token]);

  return (
    <div css={StyledJoinOrganization} className={'registration-details'}>
      <TopBar />
      <div className={'body'}>
        <LeftBean />
        <div className={'content'}>
          <BreazeLogo />
          <Form
            loading={loading}
            userExists={userExists}
            token={token}
            email={email}
          />
        </div>
        <RightBean />
      </div>
      <Footer />
      <ReCaptchaMessage />
    </div>
  );
};

export default Join;
