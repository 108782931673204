/** @jsxImportSource @emotion/react */

const StyledEmailSentMessage = {
  fontSize: '1rem',
  color: 'gray',
  textAlign: 'center'
};

const EmailSentMessage = ({}) => {

  return (
    <div css={StyledEmailSentMessage} className={'email-sent-message'}>
      A Password reset email has been sent to the provided address
    </div>
  );
};

export default EmailSentMessage;