/** @jsxImportSource @emotion/react */

const StyledBreazeLogo = {
  background: 'url("https://breas3.com/logo.png")',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  height: '5rem',
  width: '12rem',
};

const BreazeLogo = () => (
  <div css={StyledBreazeLogo} className={'breaze-logo'} />
);

export default BreazeLogo;
