import React from 'react';
import PropTypes from 'prop-types';

const DialogButton = ({ onClick, text }) =>
  text !== '' ? (
    <button className="dialog-button dialog-clr" onClick={onClick}>
      <div tabIndex="-1">{text}</div>
    </button>
  ) : null;

DialogButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default DialogButton;
