import reduxEvents from '../redux-events';
import emptyGuidebook from './empty-guidebook';

const defaultState = {
  initial: emptyGuidebook,
  working: emptyGuidebook,
  dirtySections: new Set(),
  dirtyPages: new Set(),
};

const resetTo = (_, guidebook) => ({
  initial: guidebook,
  working: guidebook,
  dirtySections: new Set(),
  dirtyPages: new Set(),
});

const set = (state, updates) => ({ ...state, ...updates });

const guidebookEvents = reduxEvents()
  .addEvent('GUIDEBOOK', 'RESET_TO', resetTo)
  .addEvent('GUIDEBOOK', 'SET', set);

const guidebook = guidebookEvents.getReducer(defaultState);
const guidebookActionCreator = guidebookEvents.getActionCreators();

export { guidebook, guidebookActionCreator };
