/** @jsxImportSource @emotion/react */
import React from 'react';
import BreazeTextInput from '../assets/Inputs/BreazeTextInput';
import PrimaryButton from '../assets/buttons/PrimaryButton';
import changePassword from '../../../api/authentication/change-password';
import SuccessfulReset from './SuccessfulReset';

const StyledPasswordResetForm = {
  '.input': {
    margin: '1rem 0 0.5rem',
  },
  '.primary-button': {
    fontSize: '0.9rem',
    padding: '0.5rem 1.75rem',
    margin: 'auto',
  },
  '.auth-message': {
    boxSizing: 'border-box',
    height: '2rem',
    color: '#e64c4c',
    textAlign: 'center',
    fontSize: '0.8rem',
  },
};

const PasswordResetForm = ({ email, token, loading, setLoading, successful, setSuccessful }) => {
  const [password, setPassword] = React.useState({ value: '', valid: false });
  const [confirmPassword, setConfirmPassword] = React.useState({
    value: '',
    valid: false,
  });
  const [authMessage, setAuthMessage] = React.useState('');
  const disabled = !(password.valid && !loading);

  const doChangePassword = async () => {
    if (password.value !== confirmPassword.value) {
      setAuthMessage('Password and Confirm Password fields must match');
    } else {
      setLoading(true);
      setAuthMessage('');
      try {
        const response = await changePassword(token, password.value);
        setSuccessful(true);
      } catch (e) {
        setAuthMessage(e?.response?.data);
      }
      setLoading(false);
    }
  };

  return successful ? (
    <SuccessfulReset />
  ) : (
    <form onSubmit={e => e.preventDefault()} css={StyledPasswordResetForm}>
      <BreazeTextInput
        label={'Email'}
        pattern={'^.+@.+\\..+$'}
        value={email}
        disabled={true}
        helpText={'Please enter a valid email address'}
      />
      <BreazeTextInput
        label={'Password'}
        helpText={'Password must have a length of 8'}
        pattern={'^.{8,}$'}
        value={password.value}
        onChange={setPassword}
        type={'password'}
      />
      <BreazeTextInput
        label={'Confirm Password'}
        pattern={'^.{8,}$'}
        value={confirmPassword.value}
        onChange={setConfirmPassword}
        type={'password'}
      />
      <div className={'auth-message'}>{authMessage}</div>
      <PrimaryButton
        onClick={doChangePassword}
        text={'Submit'}
        disabled={disabled}
      />
    </form>
  );
};

export default PasswordResetForm;
