/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import BreazeTextInput from '../assets/Inputs/BreazeTextInput';
import PrimaryButton from '../assets/buttons/PrimaryButton';
import MaterialSpinner from '../../material/MaterialSpinner';
import React from 'react';
import Join from '../../../api/authentication/join';
import useLoginToken from '../../../hooks/useLoginToken';

const StyledCreateUserForm = {
  margin: '1rem 0 0 0',
  '.description':{
    color: 'grey',
    textAlign: 'center',
    fontSize: '0.9rem',
    paddingBottom: '1rem'
  },
  '.input': {
    margin: '0.5rem 0',
  },
  '.primary-button':{
    width: '80%',
    margin: 'auto'
  }
};

const JoinOrganizationForm = ({ email, token }) => {
  const [, setToken] = useLoginToken();
  const [password, setPassword] = React.useState({ value: '', valid: false });
  const [loading, setLoading] = React.useState(false);
  const [authMessage, setAuthMessage] = React.useState('');
  const disabled = !(password.valid && !loading);

  const onSubmit = async () => {
    setLoading(true);
    setAuthMessage('');
    try {
      const response = await Join(token, null, password.value, null);
      setToken(response.data);
      window.location.href = '/';
    } catch (e) {
      setAuthMessage(e?.response?.data);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={e => e.preventDefault()} css={StyledCreateUserForm}>
      <div className={'description'}>
        Enter your credentials to join the requested organization
      </div>
      <BreazeTextInput
        label={'Email'}
        type={'text'}
        value={email}
        disabled={true}
      />
      <BreazeTextInput
        label={'Password'}
        helpText={'Password must have a length of 8'}
        pattern={'^.{8,}$'}
        value={password.value}
        onChange={setPassword}
        type={'password'}
      />
      <div className={'auth-message'}>{authMessage}</div>
      <PrimaryButton
        text={'Submit'}
        onClick={() => onSubmit()}
        disabled={disabled}
      />
      <MaterialSpinner isLoading={loading} />
    </form>
  );
};

JoinOrganizationForm.propTypes = {
  email: PropTypes.string.isRequired,
  jwt: PropTypes.string.isRequired,
};

export default JoinOrganizationForm;
