import kebabCase from 'lodash/kebabCase';
import getGuidebooks from './get-guidebooks';

const getkebabedGuidebooks = state =>
  getGuidebooks(state).map(({ topic, ..._ }) => ({
    ..._,
    topic: kebabCase(topic),
    topicName: topic,
  }));

export default getkebabedGuidebooks;
