export default {
  // Lost
  lastVisited: '/includes/modules/FM_Guidebook/api/v7/guidebook/last_visited',
  // System
  languages: '/includes/modules/FM_Guidebook/api/v7/sys/languages',
  // Vimeo
  vimeoGetTicket:
    '/includes/modules/FM_Guidebook/api/v7/vimeo/get_upload_ticket',
  vimeoGetVideos: '/includes/modules/FM_Guidebook/api/v7/vimeo/get_videos',
  vimeoCompleteUpload:
    '/includes/modules/FM_Guidebook/api/v7/vimeo/complete_upload',
  vimeoHideVideo: '/includes/modules/FM_Guidebook/api/v7/vimeo/hide_video',
  vimeoSelectThumbnail:
    '/includes/modules/FM_Guidebook/api/v7/vimeo/select_thumbnail',
  vimeoGetVideoDetails:
    '/includes/modules/FM_Guidebook/api/v7/vimeo/get_video_details',
};
